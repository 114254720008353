import { cleanUp as cleanUpSchedule } from '../store/features/scheduleLectureSlice';
import { cleanUp as cleanUpDashboard } from '../store/features/batchDashboardSlice';
import { cleanUp as cleanUpStudent } from '../store/features/batchStudentSlice';
import { cleanUp as cleanUpWeeklyCategory } from '../store/features/weeklyCategorySlice';
import { cleanUp as cleanUpAssignment } from '../store/features/batchAssignmentSlice';
import { cleanUp as cleanUpAbsence } from '../store/features/absenceSlice';

function cleanUp(dispatch) {
  dispatch(cleanUpSchedule());
  dispatch(cleanUpDashboard());
  dispatch(cleanUpStudent());
  dispatch(cleanUpWeeklyCategory());
  dispatch(cleanUpAssignment());
  dispatch(cleanUpAbsence());
}

export default cleanUp;
