import React, { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from '../../components/Container';
import DataTable from '../../components/DataTable';
import HeadingTab from '../../components/HeadingTab';
import Modal from '../../components/Modal';
import Pagination from '../../components/Pagination';
import AssignmentForm from './components/AssignmentForm';
import ActionCell from './components/ActionCell';
import AssignmentFilter from './components/AssignmentFilter';
import AssignmentRubricForm from './components/AssignmentRubricForm';
import AssignmentRubricDetail from './components/AssigmentRubricDetail';
import { findAssignment } from '../../store/features/asyncThunk/assignment';
import CustomLoading from '../../components/CustomLoading';
import {
  setAssignmentPage,
  setAssignmentPerPage,
  setRequestDone,
} from '../../store/features/assignmentSlice';

import sendRequest from '../../helpers/sendRequest';

export default function Assignment() {
  const dispatch = useDispatch();
  const assignmentColumnHeader = useMemo(
    () => [
      {
        Header: 'name',
        accessor: 'name',
      },
      {
        Header: 'type',
        accessor: 'type',
      },
      {
        Header: 'phase',
        accessor: 'phase',
      },
      {
        Header: 'weight',
        accessor: 'weight',
      },
      {
        Header: 'week',
        accessor: 'week',
      },
      {
        Header: 'program',
        accessor: 'program',
      },
      {
        Header: 'created at',
        accessor: 'createdAt',
      },
      {
        Header: 'action',
        accessor: 'id',
        Cell: ActionCell,
      },
    ],
    [],
  );
  const { assignmentRows, paginationDetail, requestDone } = useSelector(
    (state) => state.assignment,
  );
  const [showAddAssignment, setShowAddAssignment] = useState(false);
  const [showAddAssignmentRubric, setShowAddAssignmentRubric] = useState(false);
  const [showAssigmnentDetail, setShowAssignmentDetail] = useState(false);
  const [assignmentDetail, setAssignmentDetail] = useState({});
  const [filterInput, setFilterInput] = useState({
    name: '',
    type: 'challenge',
    phase: 0,
    program: 'FSJS',
  });

  const showModal = (payload) => {
    setAssignmentDetail(payload.assignmentDetail);
    if (payload.addAssignmentRubric) {
      setShowAddAssignmentRubric(true);
    } else {
      setShowAssignmentDetail(true);
    }
  };

  const fetchAssignment = (page) => {
    dispatch(
      setRequestDone({
        requestDone: false,
      }),
    );
    sendRequest({
      dispatch,
      actionName: 'find assignments',
      message: {
        waitMsg: 'Find assignments, please wait ...',
      },
      mainRequest: {
        fn: findAssignment,
        payload: {
          ...filterInput,
          page: page || 1,
          limit: paginationDetail.perPage,
        },
      },
      setRequestDone,
    });
  };

  const changePage = (page) => {
    dispatch(
      setAssignmentPage({
        currentPage: page,
      }),
    );
  };

  const changePerPage = (perPage) => {
    dispatch(setAssignmentPerPage({ perPage }));
  };

  useEffect(() => {
    fetchAssignment(paginationDetail.currentPage);
  }, [paginationDetail.currentPage, paginationDetail.perPage]);

  return (
    <Container>
      <Modal open={showAddAssignment} setOpen={setShowAddAssignment}>
        <AssignmentForm setOpen={setShowAddAssignment} />
      </Modal>
      <Modal
        open={showAddAssignmentRubric}
        setOpen={setShowAddAssignmentRubric}
      >
        <AssignmentRubricForm
          setOpen={setShowAddAssignmentRubric}
          assignmentDetail={assignmentDetail}
        />
      </Modal>
      <Modal open={showAssigmnentDetail} setOpen={setShowAssignmentDetail}>
        <AssignmentRubricDetail
          setOpen={setShowAssignmentDetail}
          assignmentDetail={assignmentDetail}
        />
      </Modal>
      <HeadingTab headingTitle="Assignment List">
        <div className="mt-3 sm:mt-0 sm:ml-4">
          <button
            onClick={() => setShowAddAssignment(true)}
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
          >
            Create Assignment
          </button>
        </div>
      </HeadingTab>
      <div className="md:flex">
        <div className="w-full lg:w-1/5 pr-2 mb-4 md:mb-0">
          <AssignmentFilter
            filterInput={filterInput}
            setFilterInput={setFilterInput}
            fetchFn={fetchAssignment}
          />
        </div>
        <div className="w-full lg:w-4/5 pl-2">
          {!requestDone ? (
            <CustomLoading width="150" />
          ) : (
            <>
              {' '}
              <DataTable
                rows={assignmentRows}
                headers={assignmentColumnHeader}
                needGlobalFilter={false}
                rowFn={showModal}
              />
              <Pagination
                paginationDetail={paginationDetail}
                changePage={changePage}
                changePerPage={changePerPage}
              />
            </>
          )}
        </div>
      </div>
    </Container>
  );
}
