import React, { useEffect } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DataTable from '../../components/DataTable';
import EditableAbsenceStatus from './components/EditableAbsenceStatus';
import ActionCell from './components/ActionCell';
import {
  setStudentAbsence,
  setAbsenceRequestDone,
} from '../../store/features/absenceSlice';
import { setRequestDone } from '../../store/features/scheduleLectureSlice';

import {
  findAbsences,
  upsertManyAbsences,
} from '../../store/features/asyncThunk/absence';
import { findScheduleLecture } from '../../store/features/asyncThunk/scheduleLecture';
import sendRequest from '../../helpers/sendRequest';

const selectAbsenceHeader = (isBatchActive) => createSelector(
  (state) => state.scheduleLecture.scheduleRows,
  (schedules) => {
    const studentHeaders = [
      {
        Header: 'name',
        accessor: 'name',
      },
      {
        Header: 'buddy',
        accessor: 'buddy',
      },
      {
        Header: 'Total',
        columns: [
          {
            Header: 'Late',
            accessor: 'totalLate',
          },
          {
            Header: 'Absent',
            accessor: 'totalAbsent',
          },
        ],
      },
    ];

    // group schedule by dates
    const lectureDates = schedules.reduce((acc, cur) => {
      const currentDate = cur.lectureDate.slice(0, 10);
      if (!acc[currentDate]) {
        acc[currentDate] = [];
      }
      acc[currentDate].push(cur);
      return acc;
    }, {});

    const scheduleHeaders = [];
    const dateHeaders = Object.keys(lectureDates);

    dateHeaders.forEach((date) => {
      const currentHeader = {
        Header: date,
        columns: lectureDates[date].map((schedule) => ({
          Header: `${schedule.topic.topic} - ${schedule.timeFrame}`,
          accessor: `absences.${schedule.id}`,
          Cell: EditableAbsenceStatus,
        })),
      };
      scheduleHeaders.push(currentHeader);
    });

    if (!isBatchActive) {
      return [...studentHeaders, ...scheduleHeaders];
    }
    return [
      ...studentHeaders,
      ...scheduleHeaders,
      {
        Header: 'ACTION',
        accessor: 'id',
        Cell: ActionCell,
      },
    ];
  },
);

const selectStudentAbsence = createSelector(
  (state) => state.absence.absences,
  (state) => state.scheduleLecture.scheduleRows,
  (state) => state.batchStudent.students,
  (absences, schedules, students) => {
    // format student absences
    const formatedAbsences = absences.map((student) => {
      const studentIndex = students.findIndex((row) => row.id === student.id);
      const formatedStudent = {
        id: student.id,
      };
      if (studentIndex !== -1) {
        formatedStudent.name = students[studentIndex].name;
        formatedStudent.buddy = students[studentIndex].buddy?.name;
      }

      const formatedStudentAbsences = schedules.reduce((acc, cur) => {
        const isAbsenceExist = student.studentAbsences.filter(
          (item) => item.scheduleLectureId === cur.id,
        );
        if (isAbsenceExist.length > 0) {
          acc[cur.id] = isAbsenceExist[0].status;
        } else {
          acc[cur.id] = '';
        }
        return acc;
      }, {});
      formatedStudent.absences = formatedStudentAbsences;
      return formatedStudent;
    });

    // format student total absence and late
    return formatedAbsences.map((student) => {
      const formatedStudent = { ...student };
      const scheduleIds = Object.keys(formatedStudent.absences);
      const totalAbsentLate = scheduleIds.reduce(
        (acc, scheduleId) => {
          if (formatedStudent.absences[scheduleId] === 'late') {
            acc.late += 1;
          } else if (formatedStudent.absences[scheduleId] === 'absent') {
            acc.absent += 1;
          }
          return acc;
        },
        { late: 0, absent: 0 },
      );
      formatedStudent.totalLate = totalAbsentLate.late;
      formatedStudent.totalAbsent = totalAbsentLate.absent;
      return formatedStudent;
    });
  },
);

export default function BatchDashboardAbsence({ isBatchActive }) {
  const dispatch = useDispatch();
  const params = useParams();
  const headers = useSelector(selectAbsenceHeader(isBatchActive));
  const rows = useSelector(selectStudentAbsence);
  const { requestDone: requestScheduleDone } = useSelector(
    (state) => state.scheduleLecture,
  );
  const { requestDone: requestAbsenceDone } = useSelector(
    (state) => state.absence,
  );
  const { requestDone: requestBatchDetailDone, currentBatchDetail } = useSelector((state) => state.batchDashboard);

  const fetchScheduleLectures = () => {
    dispatch(
      setRequestDone({
        requestDone: false,
      }),
    );
    sendRequest({
      dispatch,
      actionName: 'find schedules',
      message: {
        waitMsg: 'Find schedules, please wait ...',
      },
      mainRequest: {
        fn: findScheduleLecture,
        payload: {
          batchId: currentBatchDetail.id,
          phase: params.phase,
        },
      },
      setRequestDone,
    });
  };

  const fetchAbsences = () => {
    dispatch(
      setAbsenceRequestDone({
        requestDone: false,
      }),
    );
    sendRequest({
      dispatch,
      actionName: 'find absences',
      message: {
        waitMsg: 'Find absences, please wait ...',
      },
      mainRequest: {
        fn: findAbsences,
        payload: {
          batchId: currentBatchDetail.id,
          phase: params.phase,
        },
      },
      setRequestDone: setAbsenceRequestDone,
    });
  };

  useEffect(() => {
    /** fetch schedule if schedule is not fetched yet */
    if (requestBatchDetailDone && !requestScheduleDone) {
      fetchScheduleLectures();
    }
  }, [requestBatchDetailDone, requestScheduleDone]);

  useEffect(() => {
    /** fetch absences if absences is not fetched yet */
    if (requestBatchDetailDone && !requestAbsenceDone) {
      fetchAbsences();
    }
  }, [requestAbsenceDone, requestBatchDetailDone]);

  const saveStudentAbsence = (rowId) => {
    sendRequest({
      dispatch,
      actionName: 'Update student absences',
      message: {
        waitMsg: 'Updating student absences, please wait ...',
      },
      mainRequest: {
        fn: upsertManyAbsences,
        payload: rowId,
      },
    });
  };

  const updateCell = ({ scheduleLectureId, rowIndex, value }) => {
    dispatch(
      setStudentAbsence({
        scheduleLectureId,
        rowIndex,
        value,
      }),
    );
  };
  return (
    <>
      <DataTable
        rows={rows}
        headers={headers}
        needGlobalFilter
        updateCell={updateCell}
        rowFn={saveStudentAbsence}
        additionalStyle="pb-64 no-scrollbar"
      />
    </>
  );
}
