import { createAsyncThunk } from '@reduxjs/toolkit';
import admissionAPI from '../../../config/api/admissionAPI';
import { thunkHandler } from '../helper/thunkHandler';

export const findStudentStatus = createAsyncThunk(
  'studentStatus/findStudentStatus',
  async (_, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');

    return thunkHandler(
      admissionAPI.get('/student-statuses', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);
