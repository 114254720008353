import academicAPI from '../../../config/api/academicAPI';

export const generateCertificate = (data) => {
  const accessToken = localStorage.getItem('access_token');

  return academicAPI.post('/graduates/import', data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
