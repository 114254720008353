import React from 'react';

export default function ShowProfileCell({ value: showProfileModal, row }) {
  return (
    <button
      className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-500 border-yellow-500 hover:bg-yellow-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
      type="button"
      onClick={() => {
        showProfileModal({
          ...row.original,
        });
      }}
    >
      Profile
    </button>
  );
}
