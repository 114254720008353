import { useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import Container from '../../components/Container';
import HeadingTab from '../../components/HeadingTab';
import { setNotification } from '../../store/features/notificationSlice';
import { generateCertificate } from '../../store/features/asyncThunk/certificate';
import './components/tableStyles.css';

export default function Certificate() {
  const dispatch = useDispatch();
  const [filePayload, setFilePayload] = useState([]);
  const [filePreview, setFilePreview] = useState([]);

  const handleHonor = (score) => {
    if (score >= 100) return 'TEACHER"S AWARD';
    if (score >= 90 && score < 100) return 'HONOR';
    if (score >= 80 && score < 90) return 'GRADUATE';
    return 'PARTICIPANT';
  };

  const handleModifyGraduationDate = (d) => {
    const day = d.split('-')[0];
    const month = d.split('-')[1];
    let year = d.split('-')[2];
    year = `20${year}`;
    return `${year}-${month}-${day}`;
  };

  const handleFileChange = (e) => {
    if (!e.target.files.length) {
      return;
    }
    const file = e.target.files[0];
    setFilePayload(file);
    let response = null;

    const promise = new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve();
        response = reader.result;
      };
      reader.readAsText(file);
    });
    promise
      .then(() => {
        const lines = response.split('\r\n');
        const result = [];
        const headers = lines[0].split(',');
        for (let i = 1; i < lines.length; i += 1) {
          const obj = {};
          if (lines[i].length > 0 && lines[i][0]) {
            const currentLine = lines[i].split(',');
            for (let j = 0; j < headers.length; j += 1) {
              obj[headers[j].toLowerCase().trim()] = currentLine[j]?.replaceAll(';', ',');
            }
            result.push(obj);
          }
        }
        const modifiedArr = result.map((d) => ({
          name: d.name,
          email: d.email,
          program: d.batch?.split('-')[0],
          graduation: dayjs(handleModifyGraduationDate(d.graduation_date)).format('DD-MMM-YYYY'),
          studentStatus: d.student_status,
          score: d.final_score,
          honor: handleHonor(Math.floor(+d.final_score)),
        }));
        setFilePreview(modifiedArr);
      })
      .catch(() => {
        dispatch(
          setNotification({
            status: 'error',
            message: 'error upload csv',
            show: true,
            autoClose: true,
          }),
        );
      });
  };

  const handleSubmitFile = async () => {
    const formData = new FormData();
    formData.append('graduate_csv_file', filePayload);
    try {
      const { data } = await generateCertificate(formData);
      if (data.status) {
        dispatch(
          setNotification({
            status: 'success',
            message: 'Generate certificate success',
            show: true,
            autoClose: true,
          }),
        );
      }
    } catch (error) {
      dispatch(
        setNotification({
          status: 'error',
          message: error.response.data.errors[0],
          show: true,
          autoClose: true,
        }),
      );
    }
  };

  return (
    <>
      <Container>
        <HeadingTab headingTitle="Generate certificate" />
        <div className="md:flex w-full items-center justify-center">
          <div className="w-full lg:w-4/5">
            <div className="flex flex-col w-full mb-8">
              <span className="text-lg font-medium text-gray-600 capitalize">select CSV file</span>
              <input
                type="file"
                accept=".csv"
                onChange={handleFileChange}
              />
            </div>
            <div className="flex w-full align-center justify-center">
              <button
                className="border-2 border-black rounded-md p-4 capitalize hover:cursor-pointer hover:bg-blue-700 hover:text-white font-medium text-lg text-black-700"
                type="button"
                onClick={handleSubmitFile}
              >
                generate certificate
              </button>
            </div>
            {
              filePreview.length > 0 && (
                <table className="w-full my-8">
                  <thead>
                    <tr>
                      <th>name</th>
                      <th>email</th>
                      <th>program</th>
                      <th>graduation date</th>
                      <th>score</th>
                      <th>predicate</th>
                      <th>student status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      filePreview.map((d) => (
                        <tr>
                          <td>{d.name}</td>
                          <td>{d.email}</td>
                          <td>{d.program}</td>
                          <td>{d.graduation}</td>
                          <td>{d.score}</td>
                          <td>{d.honor}</td>
                          <td>{d.studentStatus}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              )
            }
          </div>
        </div>
      </Container>
    </>
  );
}
