import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import Select from 'react-select';

const instructorSelector = createSelector(
  (state) => state.batchDashboard,
  (batchDashboard) => batchDashboard.instructors.map((instructor) => ({
    value: instructor.id,
    label: instructor.fullName,
  })),
);

export default function DropDownBuddyCell({
  row,
  updateMyData,
  value: initialValue,
}) {
  const instructors = useSelector(instructorSelector);
  const [selectedInstructor, setSelectedInstructor] = useState({
    value: initialValue.id,
    label: initialValue.name,
  });
  useEffect(() => {
    setSelectedInstructor({
      value: initialValue.id,
      label: initialValue.name,
    });
  }, [initialValue]);

  return (
    <div className="w-48 mx-auto">
      <Select
        options={instructors}
        onChange={(val) => {
          const {
            batchId,
            id,
            lifePoints,
            name,
            programType,
            status,
            userId,
            warningLetter,
          } = row.original;
          const newRow = {
            batchId,
            id,
            lifePoints,
            name,
            programType,
            status,
            userId,
            warningLetter,
            buddy: {
              id: val.value,
              name: val.label,
            },
          };
          updateMyData({
            rowIndex: row.id,
            newRow,
          });
        }}
        value={selectedInstructor}
      />
    </div>
  );
}
