/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { XIcon } from '@heroicons/react/solid';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import ConfirmationModal from '../../../components/ConfirmationModal';
import Modal from '../../../components/Modal';
import { updateStudentAdmissionStatus } from '../../../store/features/asyncThunk/batchStudent';
import sendRequest from '../../../helpers/sendRequest';

const selectStudentStatus = createSelector(
  (state) => state.studentStatus.studentStatusList,
  (studentStatus) => studentStatus.filter(
    (status) => status.name === 'DROP_OUT'
        || status.name === 'LEAVE'
        || status.name === 'WITHDRAW'
        || status.name === 'WAIVED',
  ),
);

export default function UpdateStatusModal({ setOpen, student }) {
  const dispatch = useDispatch();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [statusId, setStatusId] = useState('');
  const [reason, setReason] = useState('');
  const studentStatus = useSelector(selectStudentStatus);
  const updateStatus = () => {
    const currentStatus = studentStatus.find((item) => item.id === statusId);
    if (currentStatus) {
      const payload = {
        student_id: student.id,
        reason,
        status_id: statusId,
        status_name: currentStatus.name,
      };
      sendRequest({
        dispatch,
        actionName: 'Update student admission status',
        message: {
          waitMsg: 'Updating student admission status, please wait...',
        },
        mainRequest: {
          fn: updateStudentAdmissionStatus,
          payload,
        },
        closeModal: () => {
          setOpen(false);
        },
      });
    }
  };
  useEffect(() => {
    if (studentStatus.length > 0) {
      setStatusId(studentStatus[0].id);
    }
  }, [studentStatus]);

  return (
    <>
      <Modal open={openConfirmation} setOpen={setOpenConfirmation}>
        <ConfirmationModal
          message="Your change status request is irreversible, proceed ?"
          onConfirmed={() => {
            updateStatus();
            setOpenConfirmation(false);
          }}
          setOpen={setOpenConfirmation}
        />
      </Modal>
      <div className="flex flex-col justify-center my-2 mx-4 md:mx-0 text-left w-full">
        <form
          className="mx-auto bg-white p-6 rounded-md w-3/4"
          onSubmit={(e) => {
            e.preventDefault();
            setOpenConfirmation(true);
          }}
        >
          <div className="flex justify-between mb-6">
            <h2 className="w-1/2 text-lg text-black ">
              Change
              {' '}
              {student.name}
              {' '}
              status
            </h2>
            <button type="button" onClick={() => setOpen(false)}>
              <XIcon className="h-6 w-6 bg-red-400 rounded text-gray-100" />
            </button>
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-full px-3 mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="status"
              >
                Status
              </label>
              <div className="border rounded-md border-gray-400 pr-3">
                <select
                  name="status"
                  className="block w-full text-gray-900 font-medium py-3 px-3 leading-tight focus:outline-none rounded-md"
                  value={statusId}
                  onChange={(e) => {
                    setStatusId(+e.target.value);
                  }}
                  required
                >
                  {studentStatus.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="w-full md:w-full px-3 mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="reason"
              >
                Reason
              </label>
              <textarea
                id="reason"
                name="reason"
                rows={6}
                className="px-2 pt-2 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-400 rounded-md mb-2"
                placeholder="Write the reason here..."
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                required
              />
            </div>
            <div className="w-full md:w-full px-3 mb-6">
              <button
                type="submit"
                className="appearance-none block w-full bg-indigo-600 text-gray-100 font-bold border border-gray-200 rounded-lg py-3 px-3 leading-tight hover:bg-blue-500 focus:outline-none  focus:border-gray-500"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
