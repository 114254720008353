import React, { useMemo, useState, useEffect } from 'react';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import Container from '../../components/Container';
import HeadingTab from '../../components/HeadingTab';
import DataTable from '../../components/DataTable';
import Modal from '../../components/Modal';
import TopicForm from './components/TopicForm';
import TopicFilter from './components/TopicFilter';
import Pagination from '../../components/Pagination';
import CustomLoading from '../../components/CustomLoading';
import {
  setRequestDone,
  setPage,
  setPerPage,
} from '../../store/features/topicSlice';
import { findTopic } from '../../store/features/asyncThunk/topic';
import sendRequest from '../../helpers/sendRequest';

const selectTopicRows = createSelector(
  (state) => state.topic.topicRows,
  (rows) => rows,
);

export default function Topic() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const topicHeaderColumn = useMemo(
    () => [
      {
        Header: 'topic',
        accessor: 'topic',
      },
      {
        Header: 'phase',
        accessor: 'phase',
      },
      {
        Header: 'program',
        accessor: 'program',
      },
      {
        Header: 'created at',
        accessor: 'createdAt',
      },
    ],
    [],
  );

  const topicRows = useSelector(selectTopicRows);
  const { requestDone, paginationDetail } = useSelector((state) => state.topic);
  const [filterInput, setFilterInput] = useState({
    topic: '',
    phase: 0,
    program: 'FSJS',
  });
  const fetchTopic = (page) => {
    dispatch(
      setRequestDone({
        requestDone: false,
      }),
    );
    sendRequest({
      dispatch,
      actionName: 'find topics',
      message: {
        waitMsg: 'Find topics, please wait ...',
      },
      mainRequest: {
        fn: findTopic,
        payload: {
          ...filterInput,
          page: page || 1,
          limit: paginationDetail.perPage,
        },
      },
      setRequestDone,
    });
  };
  const changePage = (page) => {
    dispatch(setPage({ currentPage: page }));
  };

  const changePerPage = (perPage) => {
    dispatch(setPerPage({ perPage }));
  };
  useEffect(() => {
    fetchTopic(paginationDetail.currentPage);
  }, [paginationDetail.currentPage, paginationDetail.perPage]);

  return (
    <>
      <Container>
        <HeadingTab headingTitle="Topic Dashboard">
          <div className="mt-3 sm:mt-0 sm:ml-4">
            <button
              onClick={() => setOpen(true)}
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
            >
              Add Topic
            </button>
          </div>
        </HeadingTab>
        <Modal title="Create New Topic" open={open} setOpen={setOpen}>
          <TopicForm setOpen={setOpen} filterInput={filterInput} />
        </Modal>
        <div className="md:flex">
          <div className="w-full lg:w-1/5 pr-2 mb-4 md:mb-0">
            <TopicFilter
              filterInput={filterInput}
              setFilterInput={setFilterInput}
              fetchTopic={fetchTopic}
            />
          </div>
          <div className="w-full lg:w-4/5 pl-2">
            {!requestDone ? (
              <CustomLoading width="150" />
            ) : (
              <>
                <DataTable
                  rows={topicRows}
                  headers={topicHeaderColumn}
                  needGlobalFilter={false}
                />
                <Pagination
                  paginationDetail={paginationDetail}
                  changePage={changePage}
                  changePerPage={changePerPage}
                />
              </>
            )}
          </div>
        </div>
      </Container>
    </>
  );
}
