import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Transition } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/outline';

export default function AssignmentRubricNotes() {
  const { show, notes } = useSelector(
    (state) => state.batchAssignment.focusRubricNotes,
  );

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-10"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon
                      className="h-6 w-6 text-indigo-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Rubric Notes
                    </p>
                    {notes.map((note, i) => (
                      <p
                        className="mt-1 text-xs mb-2 text-gray-500"
                        key={note.id}
                      >
                        {i + 1}
                        .
                        {note.criteria}
                        {' '}
                        <span className="font-bold">
                          (
                          {note.points}
                          )
                        </span>
                      </p>
                    ))}
                  </div>
                  <div className="ml-4 flex-shrink-0 flex" />
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
