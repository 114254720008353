/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { findBatch, findBatchPhases } from './asyncThunk/batch';

const initialState = {
  program: '',
  batchRows: [],
  paginationDetail: {
    currentPage: 1,
    lastPage: 1,
    perPage: 15,
    total: 0,
    from: 1,
    to: 1,
  },
  phases: [],
  requestDone: false,
};

export const batchSlice = createSlice({
  name: 'batch',
  initialState,
  reducers: {
    setProgram: (state, action) => {
      state.program = action.payload.program;
    },
    setBatchRequestDone: (state, action) => {
      state.requestDone = action.payload.requestDone;
    },
    setBatchPage: (state, action) => {
      state.paginationDetail.currentPage = action.payload.currentPage;
    },
    setBatchPerPage: (state, action) => {
      state.paginationDetail.perPage = action.payload.perPage;
    },
    clearPhases: (state) => {
      state.phases = [];
      state.requestDone = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findBatch.fulfilled, (state, action) => {
      state.batchRows = action.payload?.data?.rows?.map((item) => {
        const {
          id,
          code,
          current_phase: currentPhase,
          created_at: createdAt,
        } = item;
        return {
          id,
          code,
          currentPhase,
          createdAt,
        };
      });
      const {
        total,
        current_page: currentPage,
        per_page: perPage,
        last_page: lastPage,
        from,
        to,
      } = action.payload.data.pagination;
      state.paginationDetail = {
        currentPage,
        lastPage,
        perPage,
        total,
        from,
        to,
      };
    });
    builder.addCase(findBatchPhases.fulfilled, (state, action) => {
      if (action.payload?.data) {
        action.payload.data.sort((a, b) => a.phase - b.phase);
      }
      state.phases = action.payload?.data;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setProgram,
  setBatchRequestDone,
  setBatchPage,
  setBatchPerPage,
  clearPhases,
} = batchSlice.actions;

export default batchSlice.reducer;
