import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setProgram } from '../../../store/features/batchSlice';
import hacktivBG from '../../../assets/img/hacktiv-bg.png';

export default function PickProgram() {
  const dispatch = useDispatch();
  const programs = useMemo(() => [
    { code: 'FSJF', desc: 'Fullstack Javascript Flex' },
    { code: 'FSJS', desc: 'Fullstack Javascript Regular' },
    { code: 'FTDS', desc: 'Fulltime Data Science' },
    { code: 'FTDM', desc: 'Fulltime Digital Marketing' },
  ]);
  return (
    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
      {programs.map((item) => (
        <li
          className="group col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200 cursor-pointer border hover:bg-indigo-400 hover:text-white"
          key={item.code}
          onClick={() => dispatch(
            setProgram({
              program: item.code,
            }),
          )}
        >
          <img
            src={hacktivBG}
            alt="program-img"
            className="object-cover pointer-events-none rounded-lg group-hover:opacity-75"
          />
          <div className="flex-1 flex flex-col p-8">
            <h3 className="text-gray-900 group-hover:text-white text-3xl font-medium">
              {item.code}
            </h3>
            <dl className="mt-1 flex-grow flex flex-col justify-between">
              <dt className="sr-only">Title</dt>
              <dd className="text-gray-500 text-lg group-hover:text-white">
                {item.desc}
              </dd>
            </dl>
          </div>
        </li>
      ))}
    </ul>
  );
}
