import React from 'react';
import PropTypes from 'prop-types';

function Container({ children }) {
  return (
    <div className="max-w-full mx-auto sm:px-6 lg:px-12 pb-4 py-8">
      {children}
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
};

export default Container;
