import React, { useMemo, useState, useEffect } from 'react';
import Select from 'react-select';

export default function EditableCategoryCell({
  row,
  updateMyData,
  value,
  column,
}) {
  const options = useMemo(() => [
    {
      label: 'slow',
      value: 'slow',
    },
    {
      label: 'lazy',
      value: 'lazy',
    },
    {
      label: 'unreachable',
      value: 'unreachable',
    },
    {
      label: 'safe',
      value: 'safe',
    },
  ]);
  const [selectedOption, setSelectedOption] = useState({
    value,
    label: value,
  });

  useEffect(() => {
    setSelectedOption({
      value,
      label: value,
    });
  }, [value]);

  return (
    <div className="w-36">
      <Select
        options={options}
        value={selectedOption}
        onChange={(val) => {
          const rowId = row.id;

          const currentWeek = Number(column.parent.Header.split(' ')[1]);
          updateMyData({
            rowId,
            currentWeek,
            value: val.value,
            field: 'category',
          });
        }}
      />
    </div>
  );
}
