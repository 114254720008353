import { setNotification } from '../store/features/notificationSlice';
import { setIsTokenInvalid } from '../store/features/userTokenSlice';

export default function sendRequest({
  dispatch,
  actionName,
  message: { waitMsg },
  mainRequest: { fn, payload },
  closeModal,
  additionalFetch,
  setRequestDone,
}) {
  return new Promise((resolve) => {
    dispatch(
      setNotification({
        status: 'info',
        message: waitMsg,
        show: true,
        autoClose: false,
      }),
    );
    dispatch(fn(payload))
      .then((res) => {
        if (res.error) {
          throw res;
        }
        resolve(res);
        dispatch(
          setNotification({
            status: 'success',
            message: `${actionName} success`,
            show: true,
            autoClose: true,
          }),
        );
        if (additionalFetch) {
          additionalFetch();
        }
      })
      .catch((err) => {
        let errMessage = 'Oops, something went wrong';
        if (err?.payload?.status === 400) {
          errMessage = `${actionName} failed, please check your input`;
        } else if (err?.payload?.errors) {
          errMessage = err.payload.errors.join(',');
        }
        dispatch(
          setNotification({
            status: 'error',
            message: errMessage,
            show: true,
          }),
        );
        if (errMessage === 'token is either expired or invalid') {
          dispatch(
            setIsTokenInvalid({
              isTokenInvalid: true,
            }),
          );
        }
      })
      .finally(() => {
        if (closeModal) {
          closeModal();
        }
        if (setRequestDone) {
          dispatch(setRequestDone({ requestDone: true }));
        }
      });
  });
}
