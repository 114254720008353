import React, { useEffect, useState } from 'react';

export default function CheckBoxCell({
  value: initialValue,
  row,
  updateMyData,
}) {
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <input
      value={value}
      checked={value}
      name="warning-letter"
      type="checkbox"
      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
      onChange={() => {
        const {
          batchId,
          id,
          lifePoints,
          name,
          programType,
          status,
          userId,
          buddy,
        } = row.original;
        const newRow = {
          batchId,
          id,
          lifePoints,
          name,
          programType,
          status,
          userId,
          buddy,
          warningLetter: !value,
        };
        updateMyData({
          rowIndex: row.id,
          newRow,
        });
      }}
    />
  );
}
