import React, { useEffect } from 'react';
import { XIcon } from '@heroicons/react/solid';
import { useSelector, useDispatch } from 'react-redux';
import CustomLoading from '../../../components/CustomLoading';

import { resetStudentJourneyDetail } from '../../../store/features/studentJourneySlice';

export default function StudentJourneyModal({
  setOpen,
  fetchJourney,
  studentDetail,
}) {
  const dispatch = useDispatch();
  const { requestDone, studentJourneyDetail } = useSelector(
    (state) => state.studentJourney,
  );
  useEffect(() => {
    fetchJourney(studentDetail.id);
    return function clearDetail() {
      dispatch(resetStudentJourneyDetail());
    };
  }, []);

  return (
    <div className="flex flex-col justify-center my-2 mx-4 md:mx-0 ">
      <div className="mx-auto bg-white p-6 rounded-md w-full">
        <div className="flex justify-between mb-4">
          <h2 className="w-1/2 text-xl text-left text-black ">
            {studentDetail.name}
            {' '}
            Journeys
            {' '}
          </h2>

          {studentJourneyDetail.immersiveScore
          || studentJourneyDetail.immersiveScore !== 0 ? (
            <p className="text-gray-700">
              Average Immersive Score:
              {' '}
              {studentJourneyDetail.immersiveScore}
            </p>
            ) : (
              ''
            )}

          <button type="button" onClick={() => setOpen(false)}>
            <XIcon className="h-6 w-6 bg-red-400 rounded text-gray-100" />
          </button>
        </div>
        <div className="flex text-sm flex-wrap mb-6">
          <div className="flex flex-col w-full border-t boder-gray-50 mb-2">
            <div className="overflow-x-auto">
              <div className="py-2 align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  {requestDone ? (
                    <table className="min-w-full divide-y divide-gray-200 text-center">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Phase
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Batch
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Buddy
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3   font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Final Score
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Life Points
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3   font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Total Absent
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Total Late
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {studentJourneyDetail.studentJourney?.map((journey) => (
                          <tr key={journey.id} className="text-gray-500">
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                              {journey.createdAt}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                              {journey.phase}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                              {journey.batchDetail?.code}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                              {journey.buddyDetail?.fullName}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                              {journey.status}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                              {journey.finalScore}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                              {journey.lifePoints}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                              {journey.totalAbsent}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                              {journey.totalLate}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <CustomLoading width="150" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
