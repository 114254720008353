import { createAsyncThunk } from '@reduxjs/toolkit';
import academicAPI from '../../../config/api/academicAPI';
import { thunkHandler } from '../helper/thunkHandler';

export const findStudentAssignmentRubric = createAsyncThunk(
  'studentAssignmentRubric/findStudentAssignmentRubrics',
  async (payload, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');
    const params = {
      'batch-assignment': payload.batchAssignmentId,
    };

    return thunkHandler(
      academicAPI.get('/students/assignment-rubrics', {
        params,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);

export const createManyStudentAssignmentRubrics = createAsyncThunk(
  'studentAssignmentRubric/createManyStudentAssignmentRubrics',
  async (payload, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');

    return thunkHandler(
      academicAPI.post('/student-assignment-rubrics/bulk', payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);
