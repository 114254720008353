/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: '',
  status: '', // succes / failed / info
  show: false,
  autoClose: false,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification: (state, action) => {
      state.message = action.payload.message;
      state.status = action.payload.status;
      state.show = action.payload.show;
      state.autoClose = action.payload.autoClose;
    },
    closeNotification: (state) => {
      state.message = '';
      state.status = '';
      state.show = false;
      state.autoClose = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setNotification, closeNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
