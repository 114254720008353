import React, { useMemo, useState, useEffect } from 'react';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import { findInstructor } from '../../store/features/asyncThunk/instructor';
import Container from '../../components/Container';
import HeadingTab from '../../components/HeadingTab';
import DataTable from '../../components/DataTable';
import InstructorFilter from './components/InstructorFilter';
import Pagination from '../../components/Pagination';
import AssignPhaseModal from './components/AssignPhaseModal';
import Modal from '../../components/Modal';
import {
  setInstructorPage,
  setInstructorPerPage,
  setRequestDone,
} from '../../store/features/instructorSlice';
import CustomLoading from '../../components/CustomLoading';
import sendRequest from '../../helpers/sendRequest';

const selectTopicRows = createSelector(
  (state) => state.instructor.instructorRows,
  (rows) => rows,
);

export default function Instructor() {
  const dispatch = useDispatch();
  const [filterInput, setFilterInput] = useState({
    phase: 0,
    program: 'FSJS',
  });
  const [openAssignPhase, setOpenAssignPhase] = useState(false);
  const topicRows = useSelector(selectTopicRows);
  const { paginationDetail, requestDone } = useSelector(
    (state) => state.instructor,
  );
  const changePage = (page) => {
    dispatch(setInstructorPage({ currentPage: page }));
  };
  const changePerPage = (perPage) => {
    dispatch(setInstructorPerPage({ perPage }));
  };
  const fetchInstructor = (page) => {
    dispatch(
      setRequestDone({
        requestDone: false,
      }),
    );
    sendRequest({
      dispatch,
      actionName: 'find instructors',
      message: {
        waitMsg: 'Find instructors, please wait ...',
      },
      mainRequest: {
        fn: findInstructor,
        payload: {
          ...filterInput,
          page: page || 1,
          limit: paginationDetail.perPage,
        },
      },
      setRequestDone,
    });
  };
  const topicHeaderColumn = useMemo(
    () => [
      {
        Header: 'name',
        accessor: 'fullName',
      },
      {
        Header: 'phase',
        accessor: 'phase',
      },
      {
        Header: 'program',
        accessor: 'program',
      },
    ],
    [],
  );
  useEffect(() => {
    fetchInstructor(paginationDetail.currentPage);
  }, [paginationDetail.currentPage, paginationDetail.perPage]);
  return (
    <>
      <Container>
        <Modal open={openAssignPhase} setOpen={setOpenAssignPhase}>
          <AssignPhaseModal
            setOpen={setOpenAssignPhase}
            fetchInstructor={fetchInstructor}
          />
        </Modal>
        <HeadingTab headingTitle="Instructor Dashboard">
          <div className="mt-3 sm:mt-0 sm:ml-4">
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
              onClick={() => {
                setOpenAssignPhase(true);
              }}
            >
              Assign My Phase
            </button>
          </div>
        </HeadingTab>
        <div className="md:flex">
          <div className="w-full lg:w-1/5 pr-2 mb-4 md:mb-0">
            <InstructorFilter
              filterInput={filterInput}
              setFilterInput={setFilterInput}
              fetchFn={fetchInstructor}
            />
          </div>
          <div className="w-full lg:w-4/5 pl-2">
            {!requestDone ? (
              <CustomLoading width="150" />
            ) : (
              <>
                <DataTable
                  rows={topicRows}
                  headers={topicHeaderColumn}
                  needGlobalFilter={false}
                />
                <Pagination
                  paginationDetail={paginationDetail}
                  changePage={changePage}
                  changePerPage={changePerPage}
                />
              </>
            )}
          </div>
        </div>
      </Container>
    </>
  );
}
