import React, { useMemo, useState, useEffect } from 'react';
import Select from 'react-select';

export default function EditableRemarkCell({
  row,
  updateMyData,
  value,
  column,
}) {
  const options = useMemo(() => [
    {
      label: 'need more practice',
      value: 'need more practice',
    },
    {
      label: 'having other activities',
      value: 'having other activities',
    },
    {
      label: 'personal issue',
      value: 'personal issue',
    },
    {
      label: 'empty',
      value: null,
    },
  ]);

  const [selectedOption, setSelectedOption] = useState({
    value,
    label: value,
  });

  useEffect(() => {
    setSelectedOption({
      value,
      label: value,
    });
  }, [value]);

  return (
    <div className="w-36">
      <Select
        options={options}
        value={selectedOption}
        onChange={(val) => {
          const rowId = row.id;
          const currentWeek = Number(column.parent.Header.split(' ')[1]);
          updateMyData({
            rowId,
            currentWeek,
            value: val.value,
            field: 'remark',
          });
        }}
      />
    </div>
  );
}
