import React, { useEffect } from 'react';
import {
  Switch, Route, useLocation, useHistory
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import Navbar from './components/Navbar';
import BatchDashboard from './pages/BatchDashboard';
import Notification from './components/Notification';
import BatchList from './pages/BatchList';
import BatchPhase from './pages/BatchPhase';
import PageNotFound from './pages/PageNotFound';
import SignIn from './pages/SignIn';
import Topic from './pages/Topic';
import PrivateRoute from './components/PrivateRoute';
import Assignment from './pages/Assignment';
import Instructor from './pages/Instructor';
import Student from './pages/Student';
import Certificate from './pages/Certificate';
import firebase from './config/firebase';

function App() {
  const { isTokenInvalid } = useSelector((state) => state.userToken);
  const history = useHistory();
  const signOut = () => {
    firebase
      .auth()
      .signOut()
      .finally(() => {
        localStorage.clear();
      });
  };
  useEffect(() => {
    // redirect page to sign in if token is invalid
    if (isTokenInvalid) {
      history.push('/sign-in');
      signOut();
    }
  }, [isTokenInvalid]);
  const location = useLocation();
  return (
    <div className="min-h-screen relative">
      {location.pathname !== '/sign-in' && <Navbar />}
      <Notification />
      <Switch>
        <Route path="/sign-in">
          <SignIn />
        </Route>
        <PrivateRoute path="/certificate" exact>
          <Certificate />
        </PrivateRoute>
        <PrivateRoute path="/instructor" exact>
          <Instructor />
        </PrivateRoute>
        <PrivateRoute path="/topic" exact>
          <Topic />
        </PrivateRoute>
        <PrivateRoute path="/assignment" exact>
          <Assignment />
        </PrivateRoute>
        <PrivateRoute path="/student" exact>
          <Student />
        </PrivateRoute>
        <PrivateRoute path="/batch" exact>
          <BatchList />
        </PrivateRoute>
        <PrivateRoute path="/batch/:code/phase/:phase">
          <BatchDashboard />
        </PrivateRoute>
        <PrivateRoute path="/batch/:code/phase">
          <BatchPhase />
        </PrivateRoute>
        <PrivateRoute path="/" exact>
          <BatchList />
        </PrivateRoute>
        <Route path="/*">
          <PageNotFound />
        </Route>
      </Switch>
      {/* {location.pathname !== '/sign-in' && <Footer />} */}
    </div>
  );
}

export default App;
