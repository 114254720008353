import React, { useState, useEffect } from 'react';

export default function LectureDateCell({
  row,
  updateMyData,
  value: initialValue,
}) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div>
      <input
        type="date"
        className="bg-white py-2"
        onChange={(e) => {
          const newRow = {
            ...row.original,
            lectureDate: e.target.value,
          };
          updateMyData({
            rowIndex: row.id,
            newRow,
          });
        }}
        value={value}
      />
    </div>
  );
}
