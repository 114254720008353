import { useState, useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { ClipboardCheckIcon, PlusCircleIcon } from '@heroicons/react/solid';
import { useDispatch, useSelector } from 'react-redux';

/** components */
import Container from '../../components/Container';
import HeadingTab from '../../components/HeadingTab';
import AssignmentRubricNotes from './components/AssignmentRubricNotes';
import BatchTab from './components/BatchTab';
import BreadCrumb from '../../components/BreadCrumb';
import AddAssignmentModal from './components/AddAssignmentModal';
import Modal from '../../components/Modal';
import CompletePhaseModal from './components/CompletePhaseModal';
import CustomLoading from '../../components/CustomLoading';
import GraduatePhaseModal from './components/GraduatePhaseModal';

/** pages */
import BatchDashboardCummulative from '../BatchDashboardCummulative';
import BatchDashboardAbsence from '../BatchDashboardAbsence';
import BatchDashboardAssignment from '../BatchDashboardAssignment';
import BatchDashboardWeekly from '../BatchDashboardWeekly';
import BatchDashboardStudent from '../BatchDashboardStudent';
import BatchDashboardSchedule from '../BatchDashboardSchedule';

/** action & helper */
import {
  findBatchDetailByCode,
  findAssignmentOptions,
  findInstructorOptions,
} from '../../store/features/asyncThunk/batchDashboard';
import { findBatchAssignmentOptions } from '../../store/features/asyncThunk/batchAssignment';
import { findBatchStudents } from '../../store/features/asyncThunk/batchStudent';
import { setBatchStudentRequestDone } from '../../store/features/batchStudentSlice';
import { setRequestBatchAssignmentOptionsDone } from '../../store/features/batchAssignmentSlice';
import { setRequestDone } from '../../store/features/batchDashboardSlice';
import { findStudentStatus } from '../../store/features/asyncThunk/studentStatus';
import sendRequest from '../../helpers/sendRequest';
import cleanUp from '../../helpers/cleanUp';

export default function BatchDashboard() {
  const params = useParams();
  const dispatch = useDispatch();
  const [openAssignmentModal, setOpenAssignmentModal] = useState(false);
  const [openCompletePhaseModal, setOpenCompletePhaseModal] = useState(false);
  const [isBatchActive, setIsBatchActive] = useState(false);
  const { requestDone, currentBatchDetail } = useSelector(
    (state) => state.batchDashboard,
  );

  const { requestDone: requestBatchStudentDone } = useSelector(
    (state) => state.batchStudent,
  );

  const fetchBatchDetail = () => {
    dispatch(
      setRequestDone({
        requestDone: false,
      }),
    );
    sendRequest({
      dispatch,
      actionName: 'find batch detail',
      message: {
        waitMsg: 'Find batch detail, please wait ...',
      },
      mainRequest: {
        fn: findBatchDetailByCode,
        payload: {
          code: params.code,
        },
      },
      setRequestDone,
    });
  };

  const fetchAssignmentOptions = () => {
    const program = params.code.slice(0, 4);
    dispatch(
      findAssignmentOptions({
        program,
        phase: params.phase,
      }),
    );
  };

  const fetchInstructorOptions = () => {
    const program = params.code.slice(0, 4);
    dispatch(
      findInstructorOptions({
        program,
        phase: params.phase,
      }),
    );
  };

  const fetchStudentStatusOptions = () => {
    dispatch(findStudentStatus());
  };

  const fetchBatchStudents = () => {
    sendRequest({
      dispatch,
      actionName: 'find batch students',
      message: {
        waitMsg: 'Find batch students, please wait ...',
      },
      mainRequest: {
        fn: findBatchStudents,
        payload: {
          phase: params.phase,
          batchId: currentBatchDetail.id,
        },
      },
      setRequestDone: setBatchStudentRequestDone,
    });
  };

  const fetchBatchAssignmentOptions = () => {
    dispatch(
      setRequestBatchAssignmentOptionsDone({
        requestDone: false,
      }),
    );
    sendRequest({
      dispatch,
      actionName: 'find batch assignment options',
      message: {
        waitMsg: 'Finding batch assignment options, please wait ...',
      },
      mainRequest: {
        fn: findBatchAssignmentOptions,
        payload: {
          batchId: currentBatchDetail.id,
          phase: params.phase,
        },
      },
      setRequestDone: setRequestBatchAssignmentOptionsDone,
    });
  };

  useEffect(() => {
    fetchAssignmentOptions();
    fetchInstructorOptions();
    fetchStudentStatusOptions();
    fetchBatchDetail();
    return function clearState() {
      cleanUp(dispatch);
    };
  }, []);

  useEffect(() => {
    // if opened phase is the same with batch current phase, and batch still on going (active)
    if (
      +params.phase === currentBatchDetail.currentPhase
      && currentBatchDetail.isActive === true
    ) {
      setIsBatchActive(true);
    } else {
      setIsBatchActive(false);
    }
  }, [currentBatchDetail]);

  useEffect(() => {
    if (requestDone) {
      fetchBatchStudents();
      fetchBatchAssignmentOptions();
    }
  }, [requestDone]);

  return (
    <>
      {/* this component to preview rubric notes when instructor hover on assignment cell  */}
      <AssignmentRubricNotes />
      <Container>
        <Modal open={openAssignmentModal} setOpen={setOpenAssignmentModal}>
          <AddAssignmentModal
            setOpen={setOpenAssignmentModal}
            currentBatchDetail={currentBatchDetail}
          />
        </Modal>
        <Modal
          open={openCompletePhaseModal}
          setOpen={setOpenCompletePhaseModal}
        >
          {(requestDone
            && currentBatchDetail.code.includes('FTDS')
            && currentBatchDetail.currentPhase === 2)
          || currentBatchDetail.currentPhase === 3 ? (
            <GraduatePhaseModal setOpen={setOpenCompletePhaseModal} />
            ) : (
              <CompletePhaseModal setOpen={setOpenCompletePhaseModal} />
            )}
        </Modal>
        <BreadCrumb
          histories={[
            {
              path: '/batch',
              desc: 'Batch List',
            },
            {
              path: `/batch/${params.code}/phase`,
              desc: `${params.code} Phases`,
            },
            {
              path: `/batch/${params.code}/phase/${params.phase}/student`,
              desc: `Phase ${params.phase} Dashboard`,
            },
          ]}
        />
        <HeadingTab headingTitle="Batch Dashboard">
          {isBatchActive ? (
            <>
              <div className="mt-3 sm:mt-0 sm:ml-4">
                <button
                  onClick={() => setOpenAssignmentModal(true)}
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                >
                  <PlusCircleIcon className="h-6 w-6 text-white mr-2" />
                  Add Assignment
                </button>
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-4">
                <button
                  onClick={() => setOpenCompletePhaseModal(true)}
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <ClipboardCheckIcon className="h-6 w-6 text-white mr-2" />
                  Completing Phase
                </button>
              </div>
            </>
          ) : null}
        </HeadingTab>
        <BatchTab pathName={`/batch/${params.code}/phase/${params.phase}`} />
        {requestDone && requestBatchStudentDone ? (
          <Switch>
            <Route path="/batch/:code/phase/:phase/assignment" exact>
              <BatchDashboardAssignment isBatchActive={isBatchActive} />
            </Route>
            <Route
              path="/batch/:code/phase/:phase/student"
              exact
              isBatchActive={isBatchActive}
            >
              <BatchDashboardStudent isBatchActive={isBatchActive} />
            </Route>
            <Route
              path="/batch/:code/phase/:phase/schedule"
              exact
              isBatchActive={isBatchActive}
            >
              <BatchDashboardSchedule
                currentBatchDetail={currentBatchDetail}
                isBatchActive={isBatchActive}
              />
            </Route>
            <Route
              path="/batch/:code/phase/:phase/weekly-category"
              exact
              isBatchActive={isBatchActive}
            >
              <BatchDashboardWeekly isBatchActive={isBatchActive} />
            </Route>
            <Route
              path="/batch/:code/phase/:phase/cummulative"
              exact
              isBatchActive={isBatchActive}
            >
              <BatchDashboardCummulative isBatchActive={isBatchActive} />
            </Route>
            <Route
              path="/batch/:code/phase/:phase/absence"
              exact
              isBatchActive={isBatchActive}
            >
              <BatchDashboardAbsence isBatchActive={isBatchActive} />
            </Route>
          </Switch>
        ) : (
          <CustomLoading width="150" />
        )}
      </Container>
    </>
  );
}
