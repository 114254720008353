import React from 'react';
import { NavLink } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { LogoutIcon } from '@heroicons/react/solid';
import { useDispatch } from 'react-redux';
import HacktivNavbar from '../assets/img/hacktiv-navbar.png';
import firebase from '../config/firebase';
import { setNotification } from '../store/features/notificationSlice';

export default function Navbar() {
  const dispatch = useDispatch();

  const signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.clear();
        dispatch(
          setNotification({
            status: 'success',
            message: 'sign out success',
            show: true,
            autoClose: true,
          }),
        );
      })
      .catch((error) => {
        dispatch(
          setNotification({
            status: 'error',
            message: error.message,
            show: true,
            autoClose: true,
          }),
        );
      });
  };
  return (
    <Disclosure as="nav" className="bg-yellow-400 shadow">
      {({ open }) => (
        <>
          <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-12">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-shrink-0 flex items-center">
                  <NavLink to="/">
                    <img
                      className="block h-8 w-auto"
                      src={HacktivNavbar}
                      alt="Hacktiv8"
                    />
                  </NavLink>
                </div>
                <div className="hidden md:ml-6 md:flex md:space-x-8">
                  <NavLink
                    className="border-indigo-500  text-gray-500 inline-flex items-center px-1 pt-1 text-sm font-medium"
                    activeClassName="border-b-2 text-gray-900"
                    to="/batch"
                  >
                    Batch
                  </NavLink>
                  <NavLink
                    className="border-indigo-500  text-gray-500 inline-flex items-center px-1 pt-1 text-sm font-medium"
                    activeClassName="border-b-2 text-gray-900"
                    to="/student"
                  >
                    Student
                  </NavLink>
                  <NavLink
                    className="border-indigo-500  text-gray-500 inline-flex items-center px-1 pt-1 text-sm font-medium"
                    activeClassName="border-b-2 text-gray-900"
                    to="/assignment"
                  >
                    Assignment
                  </NavLink>
                  <NavLink
                    className="border-indigo-500  text-gray-500 inline-flex items-center px-1 pt-1 text-sm font-medium"
                    activeClassName="border-b-2 text-gray-900"
                    to="/topic"
                  >
                    Topic
                  </NavLink>
                  <NavLink
                    className="border-indigo-500  text-gray-500 inline-flex items-center px-1 pt-1 text-sm font-medium"
                    activeClassName="border-b-2 text-gray-900"
                    to="/instructor"
                  >
                    Instructor
                  </NavLink>
                  <NavLink
                    className="border-indigo-500  text-gray-500 inline-flex items-center px-1 pt-1 text-sm font-medium"
                    activeClassName="border-b-2 text-gray-900"
                    to="/certificate"
                  >
                    Certificate
                  </NavLink>
                </div>
              </div>
              <div className="flex items-center">
                <NavLink
                  onClick={signOut}
                  className="md:ml-4 md:flex-shrink-0 md:flex md:items-center"
                  to="/sign-in"
                >
                  <LogoutIcon
                    className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-500 hover:text-gray-700 cursor-pointer"
                    aria-hidden="true"
                  />
                </NavLink>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
}
