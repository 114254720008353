/* eslint-disable import/no-named-as-default-member */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import Select from 'react-select';
import DataTable from '../../components/DataTable';
import ActionCell from './components/ActionCell';
import EditableAssignmentCell from './components/EditableAssignmentCell';
import {
  editCell,
  setBatchAssignmentDetail,
} from '../../store/features/batchAssignmentSlice';
import Modal from '../../components/Modal';
import ConfirmationModal from '../../components/ConfirmationModal';
import ChooseOptionPlacehodler from '../../components/ChooseOptionPlaceholder';
import { removeBatchAssignmentOptions } from '../../store/features/asyncThunk/batchAssignment';
import { findAssignmentRubrics } from '../../store/features/asyncThunk/assignmentRubric';
import {
  findStudentAssignmentRubric,
  createManyStudentAssignmentRubrics,
} from '../../store/features/asyncThunk/studentAssignmentRubric';
import sendRequest from '../../helpers/sendRequest';

const selectBatchAssignmentHeaders = (isBatchActive) => createSelector(
  (state) => state.batchAssignment,
  (batchAssignment) => {
    const { batchAssignmentHeaders, batchAssignmentDetail } = batchAssignment;
    const studentColumnHeaders = [
      {
        Header: 'name',
        accessor: 'name',
      },
      {
        Header: 'buddy',
        accessor: 'buddy',
      },
    ];
    const selectedAssignmentId = batchAssignmentDetail.value.assignmentId;
    if (selectedAssignmentId === undefined) {
      return studentColumnHeaders;
    }
    const selectedHeaders = batchAssignmentHeaders[selectedAssignmentId];
    if (selectedHeaders === undefined) {
      return studentColumnHeaders;
    }
    const scoringColumnHeaders = selectedHeaders.map((assignmentRubric) => {
      const { criteria, id, maxPoints } = assignmentRubric;
      const accessor = `scores.${id}`;
      const headerName = `${criteria.split('_').join(' ')} (${maxPoints})`;
      return {
        Header: headerName,
        accessor,
        Cell: EditableAssignmentCell,
      };
    });
    const headers = [
      ...studentColumnHeaders,
      ...scoringColumnHeaders,
      {
        Header: 'total',
        accessor: 'totalScore',
      },
    ];
    if (!isBatchActive) {
      return headers;
    }
    return [
      ...headers,
      {
        Header: 'save',
        accessor: 'id',
        Cell: ActionCell,
        disableSortBy: true,
      },
    ];
  },
);

const selectBatchAssignmentRows = createSelector(
  (state) => state.batchAssignment,
  (state) => state.batchStudent.students,
  (batchAssignment, students) => {
    const {
      batchAssignmentRows,
      batchAssignmentHeaders,
      batchAssignmentDetail,
    } = batchAssignment;
    if (batchAssignmentDetail.value.assignmentId === undefined) {
      return [];
    }
    if (
      batchAssignmentHeaders[batchAssignmentDetail.value.assignmentId]
      === undefined
    ) {
      return [];
    }
    if (
      batchAssignmentRows[batchAssignmentDetail.value.batchAssignmentId]
      === undefined
    ) {
      return [];
    }
    const mappedColumn = batchAssignmentRows[
      batchAssignmentDetail.value.batchAssignmentId
    ].map((student) => {
      const studentIndex = students.findIndex((row) => row.id === student.id);
      const studentClone = JSON.parse(JSON.stringify(student));
      studentClone.name = students[studentIndex].name;
      studentClone.buddy = students[studentIndex].buddy?.name;
      studentClone.scores = batchAssignmentHeaders[
        batchAssignmentDetail.value.assignmentId
      ].reduce((acc, header) => {
        const isScoreExist = studentClone.scores.filter(
          (score) => score.assignmentRubricId === header.id,
        );
        if (isScoreExist.length > 0) {
          acc[header.id] = isScoreExist[0].points;
        } else {
          acc[header.id] = 0;
        }
        return acc;
      }, {});
      const assignmentRubricIds = Object.keys(studentClone.scores);
      studentClone.totalScore = assignmentRubricIds.reduce(
        (acc, assignmentRubricId) => acc + studentClone.scores[assignmentRubricId],
        0,
      );
      return studentClone;
    });
    return mappedColumn;
  },
);

const selectAssignmentOptions = createSelector(
  (state) => state.batchAssignment,
  (batchAssignment) => batchAssignment.batchAssignmentOptions.map((item) => ({
    value: { batchAssignmentId: item.id, assignmentId: item.assignment.id },
    label: item.assignment.name,
  })),
);

export default function BatchDashboardAssignment({ isBatchActive }) {
  const dispatch = useDispatch();
  const { batchAssignmentRows, batchAssignmentDetail, batchAssignmentHeaders } = useSelector((state) => state.batchAssignment);

  const formatedHeaders = useSelector(
    selectBatchAssignmentHeaders(isBatchActive),
  );

  const formatedRows = useSelector(selectBatchAssignmentRows);

  const batchAssignmentOptions = useSelector(selectAssignmentOptions);

  const [openConfirmationDelete, setOpenConfirmationDelete] = useState(false);

  const deleteBatchAssignment = () => {
    sendRequest({
      dispatch,
      actionName: 'Delete batch assignment',
      message: {
        waitMsg: 'Deleting batch assignment, please wait',
      },
      mainRequest: {
        fn: removeBatchAssignmentOptions,
        payload: {
          batchAssignmentId: +batchAssignmentDetail.value.batchAssignmentId,
        },
      },
      closeModal: () => setOpenConfirmationDelete(false),
    });
  };

  const updateCell = (payload) => {
    dispatch(editCell(payload));
  };

  const fetchBatchAssignmentHeader = () => {
    sendRequest({
      dispatch,
      actionName: 'Find batch assignment headers',
      message: {
        waitMsg: 'Finding batch assignment headers, please wait',
      },
      mainRequest: {
        fn: findAssignmentRubrics,
        payload: {
          assignmentId: batchAssignmentDetail.value.assignmentId,
        },
      },
    });
  };

  const fetchBatchAssignmentRows = () => {
    sendRequest({
      dispatch,
      actionName: 'Find student assignment rubrics',
      message: {
        waitMsg: 'Finding student assignment rubrics, please wait',
      },
      mainRequest: {
        fn: findStudentAssignmentRubric,
        payload: {
          batchAssignmentId: batchAssignmentDetail.value.batchAssignmentId,
        },
      },
    });
  };

  const saveStudentAssignmentRubric = (payload) => {
    const assignmentRubricIds = Object.keys(payload.scores);
    const studentAssignmentRubrics = assignmentRubricIds.map((id) => ({
      assignment_rubric_id: +id,
      points: +payload.scores[id],
    }));
    const requestBody = {
      student_id: +payload.id,
      batch_assignment_id: +batchAssignmentDetail.value.batchAssignmentId,
      student_assignment_rubrics: studentAssignmentRubrics,
    };
    sendRequest({
      dispatch,
      actionName: 'Update student scores',
      message: {
        waitMsg: 'Updating student scores, please wait',
      },
      mainRequest: {
        fn: createManyStudentAssignmentRubrics,
        payload: requestBody,
      },
    });
  };

  useEffect(() => {
    if (
      batchAssignmentDetail.value.assignmentId !== undefined
      && !batchAssignmentHeaders[batchAssignmentDetail.value.assignmentId]
    ) {
      fetchBatchAssignmentHeader();
    }
  }, [batchAssignmentDetail, batchAssignmentHeaders]);

  useEffect(() => {
    if (
      batchAssignmentDetail.value.batchAssignmentId !== undefined
      && !batchAssignmentRows[batchAssignmentDetail.value.batchAssignmentId]
    ) {
      fetchBatchAssignmentRows();
    }
  }, [batchAssignmentRows, batchAssignmentDetail]);

  return (
    <>
      <Modal open={openConfirmationDelete} setOpen={setOpenConfirmationDelete}>
        <ConfirmationModal
          setOpen={setOpenConfirmationDelete}
          message="This action is irreversible, removing a batch assignment will delete all scores that already inputted on this assignment, are you sure?"
          onConfirmed={deleteBatchAssignment}
        />
      </Modal>
      <div className="mb-4">
        {isBatchActive && (
          <button
            className="border p-2 text-sm border-red-700 text-red-700 rounded hover:bg-gray-100"
            type="button"
            onClick={() => setOpenConfirmationDelete(true)}
          >
            Remove Assignment
          </button>
        )}
      </div>
      <div className="mb-4">
        <Select
          options={batchAssignmentOptions}
          onChange={(val) => {
            dispatch(setBatchAssignmentDetail(val));
          }}
          value={batchAssignmentDetail}
        />
      </div>
      {batchAssignmentDetail.value.batchAssignmentId !== undefined ? (
        <DataTable
          rows={formatedRows}
          headers={formatedHeaders}
          updateCell={updateCell}
          needGlobalFilter
          rowFn={saveStudentAssignmentRubric}
        />
      ) : (
        <ChooseOptionPlacehodler title="Please choose one of the assignment above" />
      )}
    </>
  );
}
