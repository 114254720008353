import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

const perPageoption = [5, 15, 25, 50];
export default function Pagination({
  paginationDetail,
  changePage,
  changePerPage,
}) {
  const debouncedChangePage = useDebouncedCallback((value) => {
    changePage(value);
  }, 1000);

  const [page, setPage] = useState(paginationDetail.currentPage);
  const [perPage, setPerPage] = useState(paginationDetail.perPage);

  useEffect(() => {
    setPage(paginationDetail.currentPage);
    setPerPage(paginationDetail.perPage);
  }, [paginationDetail.currentPage, paginationDetail.perPage]);

  return (
    <div className="bg-white py-3 flex items-center justify-between border-t border-gray-200 ">
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <div className="text-sm text-gray-700">
            <span>
              rows per page:
              <select
                value={perPage}
                onChange={(e) => changePerPage(+e.target.value)}
                className="p-2 bg-white"
              >
                {perPageoption.map((val) => (
                  <option value={val} key={val}>
                    {val}
                  </option>
                ))}
              </select>
              {' '}
              .
            </span>
            {' '}
            <span>
              Total search result
              {paginationDetail.total}
              {' '}
              rows.
            </span>
          </div>
        </div>
        <nav
          className=" flex justify-end rounded-md  -space-x-px"
          aria-label="Pagination"
        >
          <button
            type="button"
            onClick={() => {
              changePage(paginationDetail.currentPage - 1);
            }}
            className=" inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            disabled={paginationDetail.currentPage <= 1}
          >
            <span className="sr-only">Previous</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <input
            value={page}
            onChange={(e) => {
              if (
                e.target.value <= paginationDetail.lastPage
                || e.target.value >= 1
              ) {
                setPage(e.target.value);
                debouncedChangePage(e.target.value);
              }
            }}
            type="number"
            className="border-gray-300 w-1/5  inline-flex items-center px-4 py-2 border text-sm font-medium"
          />
          <span className="border-gray-300 text-gray-700 bg-gray-300   inline-flex items-center px-4 py-2 border text-sm font-medium">
            of
            {' '}
            {paginationDetail.lastPage}
          </span>
          <button
            type="button"
            onClick={() => {
              changePage(paginationDetail.currentPage + 1);
            }}
            className=" inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            disabled={
              paginationDetail.currentPage === paginationDetail.lastPage
            }
          >
            <span className="sr-only">Next</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  );
}
