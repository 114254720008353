/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { useDispatch } from 'react-redux';
import { phases, programs } from '../../../constant/academic';
import Modal from '../../../components/Modal';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { createTopic } from '../../../store/features/asyncThunk/topic';
import sendRequest from '../../../helpers/sendRequest';
import { addTopicRows } from '../../../store/features/topicSlice';

export default function TopicForm({ setOpen, filterInput }) {
  const dispatch = useDispatch();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [topicInput, setTopicInput] = useState({
    topic: '',
    phase: 0,
    program: 'FSJS',
  });

  const changeTopicInput = (e) => {
    let { value } = e.target;
    if (e.target.name === 'phase') {
      value = +e.target.value;
    }
    setTopicInput({
      ...topicInput,
      [e.target.name]: value,
    });
  };

  const submitTopic = () => {
    setOpenConfirmation(false);
    sendRequest({
      dispatch,
      actionName: 'create new topic',
      message: {
        waitMsg: 'Creating new topic, please wait ...',
      },
      mainRequest: {
        fn: createTopic,
        payload: topicInput,
      },
      closeModal: () => setOpen(false),
    }).then((res) => {
      // adding newly created topic to redux if it's phase and program it's the same with filter input
      if (
        res.payload?.data?.phase === filterInput.phase
        && res.payload?.data?.program === filterInput.program
      ) {
        dispatch(
          addTopicRows({
            topic: {
              ...res.payload.data,
              createdAt: res.payload?.data?.created_at.slice(0, 10),
            },
          }),
        );
      }
    });
  };
  return (
    <>
      <Modal open={openConfirmation} setOpen={setOpenConfirmation}>
        <ConfirmationModal
          setOpen={setOpenConfirmation}
          message="You cannot update this topic later, make sure data that you fill already correct before proceeding"
          onConfirmed={submitTopic}
        />
      </Modal>
      <div className="flex flex-col justify-center my-2 mx-4 md:mx-0 text-left">
        <form
          className="mx-auto bg-white p-6 rounded-md"
          onSubmit={(e) => {
            e.preventDefault();
            setOpenConfirmation(true);
          }}
        >
          <div className="flex justify-between mb-6">
            <h2 className="w-1/2 text-lg text-black ">Topic Form</h2>
            <button type="button" onClick={() => setOpen(false)}>
              <XIcon className="h-6 w-6 bg-red-400 rounded text-gray-100" />
            </button>
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-full px-3 mb-4">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="Password"
              >
                Title
              </label>
              <input
                className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none"
                type="text"
                name="topic"
                value={topicInput.topic}
                onChange={changeTopicInput}
                required
              />
            </div>
            <div className="flex w-full">
              <div className="w-full md:w-1/2 px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="Password"
                >
                  Phase
                </label>
                <select
                  name="phase"
                  className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none"
                  value={topicInput.phase}
                  onChange={changeTopicInput}
                >
                  {phases.map((phase) => (
                    <option key={phase} value={phase}>
                      {phase}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-full md:w-1/2 px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="Password"
                >
                  Program
                </label>
                <select
                  name="program"
                  className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none"
                  value={topicInput.program}
                  onChange={changeTopicInput}
                >
                  {programs.map((val) => (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="w-full md:w-full px-3 mb-6">
              <button
                type="submit"
                className="appearance-none block w-full bg-indigo-600 text-gray-100 font-bold border border-gray-200 rounded-lg py-3 px-3 leading-tight hover:bg-blue-500 focus:outline-none  focus:border-gray-500"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
