import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import Select from 'react-select';

const topicSelector = createSelector(
  (state) => state.batchDashboard,
  (batchDashboard) => batchDashboard.topics.map((topic) => ({
    value: topic.id,
    label: topic.topic,
  })),
);
export default function TopicCell({ row, updateMyData, value: topic }) {
  const topics = useSelector(topicSelector);
  const [selectedTopic, setSelectedTopic] = useState({
    value: topic.id,
    label: topic.topic,
  });
  useEffect(() => {
    setSelectedTopic({
      value: topic.id,
      label: topic.topic,
    });
  }, [topic]);
  return (
    <Select
      options={topics}
      value={selectedTopic}
      onChange={(val) => {
        const newRow = {
          ...row.original,
          topic: {
            id: val.value,
            topic: val.label,
          },
        };
        updateMyData({
          rowIndex: row.id,
          newRow,
        });
      }}
    />
  );
}
