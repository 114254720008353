import { createAsyncThunk } from '@reduxjs/toolkit';
import academicAPI from '../../../config/api/academicAPI';
import { thunkHandler } from '../helper/thunkHandler';

export const createTopic = createAsyncThunk(
  'topic/createTopic',
  async (topic, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');

    return thunkHandler(
      academicAPI.post('/topics', topic, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);

export const findTopic = createAsyncThunk(
  'topic/findTopic',
  async (filter, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');

    return thunkHandler(
      academicAPI.get('/topics', {
        params: filter,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);
