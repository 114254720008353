/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';

export default function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((userInput) => {
    setGlobalFilter(userInput || undefined);
  }, 200);

  return (
    <span>
      Search:
      {' '}
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
        className="mb-2"
      />
    </span>
  );
}
