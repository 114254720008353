/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import camelcaseKeys from 'camelcase-keys';
import { findStudentJourneyByStudentId } from './asyncThunk/studentJourney';

const initialState = {
  studentJourneys: [],
  studentJourneyDetail: {},
  requestDone: false,
};

export const studentJourneySlice = createSlice({
  name: 'studentJourney',
  initialState,
  reducers: {
    setRequestDone: (state, action) => {
      state.requestDone = action.payload.requestDone;
    },
    resetStudentJourneyDetail: (state) => {
      state.studentJourneyDetail = {};
      state.requestDone = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      findStudentJourneyByStudentId.fulfilled,
      (state, action) => {
        if (action.payload.data) {
          const {
            student_journey: studentJourney,
            phases_summary: phasesSummary,
          } = action.payload.data;
          if (!studentJourney || !phasesSummary) {
            return state;
          }
          const studentJourneyDetail = camelcaseKeys(action.payload.data, {
            deep: true,
          });

          // format createdAt and updatedAt value
          studentJourneyDetail.studentJourney = studentJourneyDetail.studentJourney.map((journey) => ({
            ...journey,
            createdAt: journey.createdAt?.slice(0, 10),
            updatedAt: journey.updatedAt?.slice(0, 10),
          }));

          state.studentJourneyDetail = studentJourneyDetail;
        }
        return state;
      },
    );
  },
});
export const { setRequestDone, resetStudentJourneyDetail } = studentJourneySlice.actions;
export default studentJourneySlice.reducer;
