/* eslint-disable no-param-reassign */
import { PlusIcon } from '@heroicons/react/solid';
import { XIcon } from '@heroicons/react/outline';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AssignmentRubricInput from './AssignmentRubricInput';
import Modal from '../../../components/Modal';
import ConfirmationModal from '../../../components/ConfirmationModal';
import sendRequest from '../../../helpers/sendRequest';
import { createManyAssignmentRubrics } from '../../../store/features/asyncThunk/assignmentRubric';

export default function AssignmentRubricForm({ setOpen, assignmentDetail }) {
  const dispatch = useDispatch();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [assignmentRubrics, setAssignmentRubrics] = useState([
    {
      criteria: 'debugging',
      rubricNotes: [],
    },
  ]);

  const removeAssignmentRubricInput = (i) => {
    const newAssignmentRubrics = JSON.parse(JSON.stringify(assignmentRubrics));
    newAssignmentRubrics.splice(i, 1);
    setAssignmentRubrics(newAssignmentRubrics);
  };

  const submitAssignmentRubrics = () => {
    const payload = {
      assignment_id: assignmentDetail.id,
      assignment_rubrics: assignmentRubrics.map((item) => {
        const maxPoints = item.rubricNotes.reduce((acc, cur) => {
          acc += cur.points;
          return acc;
        }, 0);
        return {
          criteria: item.criteria,
          max_points: maxPoints,
          rubric_notes: item.rubricNotes,
        };
      }),
    };
    setOpenConfirmation(false);
    sendRequest({
      dispatch,
      actionName: 'create assignment rubrics',
      message: {
        waitMsg: 'Creating new assignment rubrics, please wait ...',
      },
      mainRequest: {
        fn: createManyAssignmentRubrics,
        payload,
      },
      closeModal: () => setOpen(false),
    });
  };

  return (
    <>
      <Modal open={openConfirmation} setOpen={setOpenConfirmation}>
        <ConfirmationModal
          setOpen={setOpenConfirmation}
          message="You cannot add/update rubrics later, make sure data that you fill already correct before proceeding"
          onConfirmed={submitAssignmentRubrics}
        />
      </Modal>
      <div className="flex flex-col justify-center my-2 mx-4 md:mx-0 text-left">
        <form
          className="mx-auto bg-white p-6 rounded-md"
          onSubmit={(e) => {
            e.preventDefault();
            setOpenConfirmation(true);
          }}
        >
          <div className="flex justify-between mb-6">
            <h2 className="w-1/2 text-lg text-black ">
              {assignmentDetail.name}
            </h2>
            <button type="button" onClick={() => setOpen(false)}>
              <XIcon className="h-6 w-6 bg-red-400 rounded text-gray-100" />
            </button>
          </div>
          <div className="flex text-sm flex-wrap">
            {assignmentRubrics.map((item, i) => (
              <AssignmentRubricInput
                {...item}
                index={i}
                key={`rubric-${i}`}
                setAssignmentRubrics={setAssignmentRubrics}
                removeAssignmentRubricInput={removeAssignmentRubricInput}
                assignmentRubrics={assignmentRubrics}
                assignmentDetail={assignmentDetail}
              />
            ))}
            <div className="w-full mb-4">
              <button
                onClick={() => {
                  const newRubrics = assignmentRubrics.map((item) => {
                    const newItem = { ...item };
                    return newItem;
                  });
                  const rubric = {
                    criteria: '',
                    points: 0,
                    rubricNotes: [],
                  };
                  setAssignmentRubrics(newRubrics.concat(rubric));
                }}
                type="button"
                className="flex justify-center w-full p-3 bg-white text-indigo-500 border border-indigo-500 rounded-md"
              >
                <PlusIcon className="h-5 w-5" aria-hidden="true" />
                <span>Add More Rubric</span>
              </button>
            </div>
            <div className="w-full">
              <button
                type="submit"
                className="appearance-none block w-full bg-indigo-600 text-gray-100 font-bold border border-gray-200 rounded-md py-3 px-2 leading-tight hover:bg-indigo-500 focus:outline-none  focus:border-gray-500"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
