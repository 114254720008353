/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { findScheduleLecture } from './asyncThunk/scheduleLecture';

const initialState = {
  requestDone: false,
  scheduleRows: [],
};

export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    changeRowSchedule: (state, action) => {
      const { rowIndex, newRow } = action.payload;
      state.scheduleRows[rowIndex] = newRow;
      return state;
    },
    setRequestDone: (state, action) => {
      state.requestDone = action.payload.requestDone;
    },
    cleanUp: (state) => {
      state = {
        requestDone: false,
        scheduleRows: [],
      };
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findScheduleLecture.fulfilled, (state, action) => {
      const formatedSchedules = action.payload?.data?.map((schedule) => {
        const {
          id,
          created_at: createdAt,
          topic,
          lecture_date: lectureDate,
          time_frame: timeFrame,
        } = schedule;
        let { lecturer_detail: lecturerDetail } = schedule;
        if (lecturerDetail === null) {
          lecturerDetail = {
            id: '',
            fullName: '',
          };
        }
        return {
          id,
          topic: { id: topic.id, topic: topic.topic },
          assignee: {
            id: lecturerDetail.id,
            fullName: lecturerDetail.full_name,
          },
          timeFrame,
          lectureDate: lectureDate.slice(0, 10),
          createdAt: createdAt.slice(0, 10),
        };
      });
      if (formatedSchedules) {
        state.scheduleRows = formatedSchedules;
      } else {
        state.scheduleRows = [];
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const { changeRowSchedule, setRequestDone, cleanUp } = scheduleSlice.actions;

export default scheduleSlice.reducer;
