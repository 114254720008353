import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import DataTable from '../../components/DataTable';
import ActionCell from './components/ActionCell';
import DropDownBuddyCell from './components/DropdownBuddyCell';
// eslint-disable-next-line no-unused-vars
import { changeStudentRow } from '../../store/features/batchStudentSlice';
import CheckBoxCell from './components/CheckBoxCell';
import ChangeStatusCell from './components/ChangeStatusCell';
import Modal from '../../components/Modal';
import UpdateStatusModal from './components/UpdateStatusModal';
import UpdateProfileModal from './components/UpdateProfileModal';
import ShowProfileCell from './components/ShowProfileCell';
import { setNotification } from '../../store/features/notificationSlice';
import { updateStudent } from '../../store/features/asyncThunk/student';
import sendRequest from '../../helpers/sendRequest';

export default function BatchDashboardStudent({ isBatchActive }) {
  const dispatch = useDispatch();
  const [studentDetail, setStudentDetail] = useState({});
  const showChangeStatusModal = (student) => {
    setStudentDetail(student);
    setOpenChangeStatus(true);
  };
  const showProfileModal = (student) => {
    setStudentDetail(student);
    setOpenProfile(true);
  };
  const studentSelector = createSelector(
    (state) => state.batchStudent.students,
    (students) => students.map((student) => ({
      ...student,
      changeStatus: showChangeStatusModal,
      changeProfile: showProfileModal,
    })),
  );

  const students = useSelector(studentSelector);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);

  const studentColumnHeader = useMemo(() => {
    const headers = [
      {
        Header: 'name',
        accessor: 'name',
      },
      {
        Header: 'payment type',
        accessor: 'paymentType',
      },
      {
        Header: 'email',
        accessor: 'email',
      },
      {
        Header: 'warning letter',
        accessor: 'warningLetter',
        Cell: CheckBoxCell,
      },
      {
        Header: 'buddy',
        accessor: 'buddy',
        Cell: DropDownBuddyCell,
      },
    ];
    if (!isBatchActive) {
      return headers;
    }
    return [
      ...headers,
      {
        Header: 'Update Profile',
        accessor: 'changeProfile',
        Cell: ShowProfileCell,
        disableSortBy: true,
      },
      {
        Header: 'Update Status',
        accessor: 'changeStatus',
        Cell: ChangeStatusCell,
        disableSortBy: true,
      },
      {
        Header: 'action',
        accessor: 'id',
        Cell: ActionCell,
        disableSortBy: true,
      },
    ];
  }, [isBatchActive]);

  const saveStudentRow = (payload) => {
    const { id, activeBuddy, warningLetter } = payload;
    if (activeBuddy.id === undefined) {
      dispatch(
        setNotification({
          message: 'Please set student buddy',
          status: 'error',
          show: true,
          autoClose: true,
        }),
      );
    } else {
      sendRequest({
        dispatch,
        actionName: 'Update student',
        message: {
          waitMsg: 'Updating student data, please wait',
        },
        mainRequest: {
          fn: updateStudent,
          payload: {
            warning_letter: warningLetter,
            student_id: id,
            active_buddy: activeBuddy.id,
          },
        },
      });
    }
  };
  const updateCell = (payload) => {
    dispatch(changeStudentRow(payload));
  };
  return (
    <>
      <Modal open={openChangeStatus} setOpen={setOpenChangeStatus}>
        <UpdateStatusModal
          student={studentDetail}
          setOpen={setOpenChangeStatus}
        />
      </Modal>
      <Modal open={openProfile} setOpen={setOpenProfile}>
        <UpdateProfileModal student={studentDetail} setOpen={setOpenProfile} />
      </Modal>
      <DataTable
        rows={students}
        needGlobalFilter
        headers={studentColumnHeader}
        additionalStyle="pb-64 no-scrollbar"
        updateCell={updateCell}
        rowFn={saveStudentRow}
      />
    </>
  );
}
