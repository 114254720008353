import React from 'react';
import { NavLink } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/solid';

export default function BreadCrumb({ histories }) {
  return (
    <div className="mb-4">
      <div>
        <nav className="hidden sm:flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-2">
            {histories.map((item, i) => (
              <NavLink to={item.path} key={item.desc}>
                <div className="flex items-center">
                  {i !== 0 && (
                    <ChevronRightIcon
                      className="flex-shrink-0 h-5 w-5 text-gray-400 mr-2"
                      aria-hidden="true"
                    />
                  )}
                  <span className="text-sm font-medium text-gray-500 hover:text-gray-700">
                    {item.desc}
                  </span>
                </div>
              </NavLink>
            ))}
          </ol>
        </nav>
      </div>
    </div>
  );
}
