/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  findBatchAssignmentOptions,
  removeBatchAssignmentOptions,
} from './asyncThunk/batchAssignment';
import { findAssignmentRubrics } from './asyncThunk/assignmentRubric';
import { findStudentAssignmentRubric } from './asyncThunk/studentAssignmentRubric';

const initialState = {
  batchAssignmentOptions: [],
  requestBatchAssignmentOptionsDone: false,
  requestBatchAssignmentRubricDone: false,
  batchAssignmentHeaders: {},
  batchAssignmentRows: {},
  batchAssignmentDetail: {
    value: {},
    label: '',
  },
  focusRubricNotes: {
    // this state is used when instructor focus on one of editable assignment cell and show assignment rubrics
    show: false,
    notes: [],
  },
};

export const batchAssignmentSlice = createSlice({
  name: 'batchAssignment',
  initialState,
  reducers: {
    editCell: (state, action) => {
      const { rowIndex, assignmentRubricId, value } = action.payload;

      /** find witch batchAssignmentID is currently edited */
      const { batchAssignmentId } = state.batchAssignmentDetail.value;
      if (batchAssignmentId === undefined) {
        return state;
      }
      if (state.batchAssignmentRows[batchAssignmentId] === undefined) {
        return state;
      }
      if (
        state.batchAssignmentRows[batchAssignmentId][rowIndex] === undefined
      ) {
        return state;
      }
      /** find witch student is currently edited */
      const student = state.batchAssignmentRows[batchAssignmentId][rowIndex];
      const studentScores = student.scores;

      /** find witch student scores is currently edited */
      const filteredScoreIndex = studentScores.findIndex(
        (score) => score.assignmentRubricId === assignmentRubricId,
      );

      /** if score not exist create new one */
      if (filteredScoreIndex === -1) {
        studentScores.push({
          assignmentRubricId,
          points: value,
          studentId: student.id,
        });
      } else {
        studentScores[filteredScoreIndex].points = value;
      }

      return state;
    },
    setFocusRubricNotes: (state, action) => {
      const { assignmentRubricId } = action.payload;

      /** find witch assignmentId is currently edited */
      const { assignmentId } = state.batchAssignmentDetail.value;
      if (assignmentId === undefined) {
        return state;
      }
      if (state.batchAssignmentHeaders[assignmentId] === undefined) {
        return state;
      }

      /** find witch assignment rubric is currently edited */
      const currentAssignmentRubric = state.batchAssignmentHeaders[
        assignmentId
      ].find((assignmentRubric) => assignmentRubric.id === assignmentRubricId);

      if (currentAssignmentRubric === undefined) {
        return state;
      }
      state.focusRubricNotes = {
        show: true,
        notes: currentAssignmentRubric.rubricNotes,
      };
      return state;
    },
    hideFocusRubricNotes: (state) => {
      state.focusRubricNotes = {
        show: false,
        notes: [],
      };
    },
    setRequestBatchAssignmentOptionsDone: (state, action) => {
      state.requestBatchAssignmentOptionsDone = action.payload.requestDone;
    },
    addBatchAssignmentOptions: (state, action) => {
      state.batchAssignmentOptions = [
        ...state.batchAssignmentOptions,
        ...action.payload.newOptions,
      ];
    },
    setBatchAssignmentDetail: (state, action) => {
      const { value, label } = action.payload;
      state.batchAssignmentDetail = {
        label,
        value,
      };
    },
    cleanUp: () => ({
      batchAssignmentOptions: [],
      requestBatchAssignmentOptionsDone: false,
      requestBatchAssignmentRubricDone: false,
      batchAssignmentHeaders: {},
      batchAssignmentRows: {},
      batchAssignmentDetail: {
        value: {},
        label: '',
      },
      focusRubricNotes: {
        // this state is used when instructor focus on one of editable assignment cell and show assignment rubrics
        show: false,
        notes: [],
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(findBatchAssignmentOptions.fulfilled, (state, action) => {
      if (!action.payload.data) {
        state.batchAssignmentOptions = [];
      } else {
        const batchAssignmentOptions = action.payload.data.map(
          (batchAssignment) => {
            const {
              id,
              created_at: createdAt,
              batch_id: batchId,
              assignment_id: assignmentId,
              phase,
              assignment,
            } = batchAssignment;
            return {
              id,
              createdAt,
              batchId,
              assignmentId,
              phase,
              assignment,
            };
          },
        );
        state.batchAssignmentOptions = batchAssignmentOptions;
      }
      return state;
    });
    builder.addCase(findAssignmentRubrics.fulfilled, (state, action) => {
      const { assignmentId } = action.meta.arg;
      if (action.payload.data === null) {
        state.batchAssignmentHeaders[assignmentId] = [];
        return state;
      }

      const formatedAssignmentRubrics = action.payload.data.map(
        (assignmentRubric) => {
          const {
            id,
            criteria,
            rubric_notes: rubricNotes,
            max_points: maxPoints,
          } = assignmentRubric;
          const formatedRubricNotes = rubricNotes.map((note) => ({
            id: note.id,
            criteria: note.criteria,
            points: note.points,
          }));
          return {
            id,
            criteria,
            maxPoints,
            rubricNotes: formatedRubricNotes,
          };
        },
      );
      state.batchAssignmentHeaders[assignmentId] = formatedAssignmentRubrics;
      return state;
    });
    builder.addCase(findStudentAssignmentRubric.fulfilled, (state, action) => {
      if (action.payload.data === null) {
        return state;
      }
      const { batchAssignmentId } = action.meta.arg;
      const formatedScores = action.payload.data.map((score) => {
        const { id, student_assignment_rubrics: studentAssignmentRubrics } = score;
        const formatedStudentAssignmentRubrics = studentAssignmentRubrics.map(
          (item) => {
            const {
              student_id: studentId,
              assignment_rubric_id: assignmentRubricId,
              points,
            } = item;
            return {
              id: item.id,
              studentId,
              assignmentRubricId,
              points,
            };
          },
        );
        return {
          id,
          scores: formatedStudentAssignmentRubrics,
        };
      });
      state.batchAssignmentRows[batchAssignmentId] = formatedScores;
      return state;
    });
    builder.addCase(removeBatchAssignmentOptions.fulfilled, (state, action) => {
      state.batchAssignmentOptions = state.batchAssignmentOptions.filter(
        (batchAssignment) => batchAssignment.id !== action.meta.arg.batchAssignmentId,
      );
      state.batchAssignmentDetail = {
        value: {},
        label: '',
      };
      return state;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  editCell,
  setFocusRubricNotes,
  hideFocusRubricNotes,
  setRequestBatchAssignmentOptionsDone,
  addBatchAssignmentOptions,
  removeDeletedBatchAssignmentOptions,
  setBatchAssignmentDetail,
  cleanUp,
} = batchAssignmentSlice.actions;

export default batchAssignmentSlice.reducer;
