import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LectureDateCell from './LectureDateCell';
import DataTable from '../../../components/DataTable';
import ActionCell from './ActionCell';
import AssigneeCell from './AssigneeCell';
import TimeFrameCell from './TimeFrameCell';
import { changeRowSchedule } from '../../../store/features/scheduleLectureSlice';
import { updateScheduleLecture } from '../../../store/features/asyncThunk/scheduleLecture';
import sendRequest from '../../../helpers/sendRequest';
import TopicCell from './TopicCell';

export default function TableSchedule({ isBatchActive }) {
  const dispatch = useDispatch();
  const schedules = useSelector((state) => state.scheduleLecture.scheduleRows);

  const save = (payload) => {
    // dispatch some action here
    const {
      lectureDate, assignee, timeFrame, topic, scheduleId
    } = payload;

    sendRequest({
      dispatch,
      actionName: 'update schedule',
      message: {
        waitMsg: 'Updating a schedule, please wait ...',
      },
      mainRequest: {
        fn: updateScheduleLecture,
        payload: {
          schedule_id: scheduleId,
          lecture_date: new Date(lectureDate).toISOString(),
          topic_id: topic.id,
          lecturer: assignee.id,
          time_frame: timeFrame,
        },
      },
    });
  };

  const updateCell = (payload) => {
    dispatch(changeRowSchedule(payload));
  };

  const scheduleColumnHeader = useMemo(() => {
    const headers = [
      {
        Header: 'Lecture Date',
        accessor: 'lectureDate',
        Cell: LectureDateCell,
      },
      {
        Header: 'Lecture Topic',
        accessor: 'topic',
        Cell: TopicCell,
      },
      {
        Header: 'Instructor Assignee',
        accessor: 'assignee',
        Cell: AssigneeCell,
      },
      {
        Header: 'Timeframe',
        accessor: 'timeFrame',
        Cell: TimeFrameCell,
      },
    ];
    if (!isBatchActive) {
      return headers;
    }
    return [
      ...headers,
      {
        Header: 'Action',
        accessor: 'id',
        Cell: ActionCell,
      },
    ];
  }, [isBatchActive]);
  return (
    <>
      <DataTable
        rows={schedules}
        headers={scheduleColumnHeader}
        needGlobalFilter
        updateCell={updateCell}
        rowFn={save}
        additionalStyle="pb-80 no-scrollbar"
      />
    </>
  );
}
