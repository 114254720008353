/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isTokenInvalid: false,
};

const userTokenSlice = createSlice({
  name: 'userToken',
  initialState,
  reducers: {
    setIsTokenInvalid: (state, action) => {
      state.isTokenInvalid = action.payload.isTokenInvalid;
    },
  },
});

export const { setIsTokenInvalid } = userTokenSlice.actions;
export default userTokenSlice.reducer;
