/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { createAssignment, findAssignment } from './asyncThunk/assignment';
import { createManyAssignmentRubrics } from './asyncThunk/assignmentRubric';

const initialState = {
  assignmentRows: [],
  paginationDetail: {
    currentPage: 1,
    lastPage: 1,
    perPage: 15,
    total: 0,
    from: 1,
    to: 1,
  },
  requestDone: false,
};

export const assignmentSlice = createSlice({
  name: 'assignment',
  initialState,
  reducers: {
    setAssignmentPage: (state, action) => {
      state.paginationDetail.currentPage = action.payload.currentPage;
    },
    setAssignmentPerPage: (state, action) => {
      state.paginationDetail.perPage = action.payload.perPage;
    },
    setRequestDone: (state, action) => {
      state.requestDone = action.payload.requestDone;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findAssignment.fulfilled, (state, action) => {
      state.assignmentRows = action.payload.data.rows.map((row) => {
        const {
          id,
          name,
          type,
          phase,
          weight,
          week,
          program,
          created_at: createdAt,
          assignment_rubrics: assignmentRubrics,
        } = row;
        let formatedAssignmentRubrics = [];
        if (assignmentRubrics) {
          formatedAssignmentRubrics = assignmentRubrics.map(
            (assignmentRubric) => {
              const {
                id,
                created_at: createdAt,
                assignment_id: assignmentId,
                criteria,
                max_points: maxPoints,
              } = assignmentRubric;

              let { rubric_notes: rubricNotes } = assignmentRubric;
              rubricNotes = rubricNotes.map((rubricNote) => {
                const {
                  id,
                  assignment_rubric_id: assignmentRubricId,
                  created_at: createdAt,
                  criteria,
                  points,
                } = rubricNote;
                return {
                  id,
                  assignmentRubricId,
                  createdAt,
                  criteria,
                  points,
                };
              });
              return {
                id,
                createdAt,
                assignmentId,
                criteria,
                maxPoints,
                rubricNotes,
              };
            },
          );
        }

        return {
          id,
          name,
          type,
          phase,
          weight,
          week,
          program,
          createdAt: createdAt.slice(0, 10),
          assignmentRubrics: formatedAssignmentRubrics,
        };
      });
      const {
        total,
        current_page: currentPage,
        per_page: perPage,
        last_page: lastPage,
        from,
        to,
      } = action.payload.data.pagination;
      state.paginationDetail = {
        currentPage,
        lastPage,
        perPage,
        total,
        from,
        to,
      };
    });
    builder.addCase(createAssignment.fulfilled, (state, action) => {
      const {
        id,
        name,
        type,
        phase,
        weight,
        week,
        program,
        created_at: createdAt,
      } = action.payload.data;

      state.assignmentRows.unshift({
        id,
        name,
        type,
        phase,
        weight,
        week,
        program,
        createdAt: createdAt.slice(0, 10),
        assignmentRubrics: [],
      });
    });
    builder.addCase(createManyAssignmentRubrics.fulfilled, (state, action) => {
      const assignmentId = action.payload.data[0].assignment_id;
      const rowIndex = state.assignmentRows.findIndex(
        (assignment) => assignment.id === assignmentId,
      );
      if (rowIndex !== -1) {
        const assignmentRubrics = action.payload.data.map(
          (assignmentRubric) => {
            const {
              id,
              created_at: createdAt,
              assignment_id: assignmentId,
              criteria,
              max_points: maxPoints,
              rubric_notes: rubricNotes,
            } = assignmentRubric;

            const formatedRubricNotes = rubricNotes.map((rubricNote) => {
              const {
                id,
                assignment_rubric_id: assignmentRubricId,
                created_at: createdAt,
                criteria,
                points,
              } = rubricNote;
              return {
                id,
                assignmentRubricId,
                createdAt,
                criteria,
                points,
              };
            });
            return {
              id,
              assignmentId,
              createdAt,
              criteria,
              maxPoints,
              rubricNotes: formatedRubricNotes,
            };
          },
        );
        state.assignmentRows[rowIndex].assignmentRubrics = assignmentRubrics;
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const { setAssignmentPage, setAssignmentPerPage, setRequestDone } = assignmentSlice.actions;

export default assignmentSlice.reducer;
