import { createAsyncThunk } from '@reduxjs/toolkit';
import academicAPI from '../../../config/api/academicAPI';
import { thunkHandler } from '../helper/thunkHandler';

export const findAssignment = createAsyncThunk(
  'assignment/findAssignment',
  async (filter, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');

    return thunkHandler(
      academicAPI.get('/assignments', {
        params: filter,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);

export const createAssignment = createAsyncThunk(
  'assignment/createAssignment',
  async (payload, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');

    return thunkHandler(
      academicAPI.post('/assignments', payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);
