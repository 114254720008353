import React from 'react';

export default function StudentFilter({
  filterInput,
  setFilterInput,
  fetchFn,
  paymentTypes,
  studentStatusList,
}) {
  const changeFilterInput = (e) => {
    const field = e.target.name;
    setFilterInput({
      ...filterInput,
      [field]: e.target.value,
    });
  };
  return (
    <>
      <form
        className="text-center"
        onSubmit={(e) => {
          e.preventDefault();
          fetchFn();
        }}
      >
        <div>
          <div className="mb-2 flex rounded-md shadow-sm border border-gray-300">
            <span className="w-1/3 inline-flex items-center px-3 rounded-l-md  bg-gray-50 text-gray-500 sm:text-sm">
              Search
            </span>
            <input
              type="text"
              name="search"
              onChange={changeFilterInput}
              value={filterInput.name}
              className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
              placeholder="search..."
            />
          </div>
        </div>
        <div>
          <div className="mb-2 flex rounded-md shadow-sm border border-gray-300">
            <span className="w-1/3 inline-flex items-center px-3 rounded-l-md  bg-gray-50 text-gray-500 sm:text-sm">
              Batch
            </span>
            <input
              type="text"
              name="batch"
              onChange={changeFilterInput}
              value={filterInput.batch}
              className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
              placeholder="batch name..."
            />
          </div>
        </div>
        <div>
          <div className="mb-2 flex rounded-md shadow-sm border border-gray-300">
            <span className="w-1/3 inline-flex items-center px-3 rounded-l-md  bg-gray-50 text-gray-500 sm:text-sm">
              Enroll in
            </span>
            <input
              type="text"
              name="enrollBatch"
              onChange={changeFilterInput}
              value={filterInput.enrollBatch}
              className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
              placeholder="enroll batch name..."
            />
          </div>
        </div>
        <div>
          <div className="mb-2 flex rounded-md shadow-sm border border-gray-300 pr-2">
            <span className="inline-flex w-1/3 items-center px-3 rounded-l-md  bg-gray-50 text-gray-500 sm:text-sm">
              Type
            </span>
            <select
              value={filterInput.paymentType}
              onChange={changeFilterInput}
              name="paymentType"
              className="bg-white outline-none flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
            >
              {paymentTypes.map((val) => (
                <option key={val.name} value={val.name}>
                  {val.value}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <div className="mb-2 flex rounded-md shadow-sm border border-gray-300 pr-2">
            <span className="w-1/3 inline-flex items-center px-3 rounded-l-md  bg-gray-50 text-gray-500 sm:text-sm">
              Status
            </span>
            <select
              value={filterInput.status}
              onChange={changeFilterInput}
              name="status"
              className="flex-1 outline-none min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 bg-white sm:text-sm border-gray-300"
            >
              {studentStatusList.map((status) => (
                <option key={status.name} value={status.name}>
                  {status.value}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button
          type="submit"
          className="w-full text-center  border border-transparent text-xs font-medium rounded  text-white bg-indigo-600 hover:bg-indigo-700 py-2"
        >
          SEARCH STUDENT
        </button>
      </form>
    </>
  );
}
