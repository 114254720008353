import axios from 'axios';

const admissionService = axios.create({
  baseURL: process.env.REACT_APP_ADMISSION_URL,
  headers: {
    'x-api-key': process.env.REACT_APP_ADMISSION_API_KEY,
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});
export default admissionService;
