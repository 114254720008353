import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import firebase from '../../config/firebase';
import provider from '../../config/googleProvider';
import userAPI from '../../config/api/userAPI';
import hacktivLogo from '../../assets/img/hacktiv-navbar.png';
import hacktivBG from '../../assets/img/hacktiv-bg.png';
import { setNotification } from '../../store/features/notificationSlice';
import { setIsTokenInvalid } from '../../store/features/userTokenSlice';

export default function SignIn() {
  const history = useHistory();
  const dispatch = useDispatch();
  const onSignIn = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .signInWithPopup(provider)
      .catch(() => {
        dispatch(
          setNotification({
            status: 'error',
            message: 'sign in failed',
            show: true,
            autoClose: true,
          }),
        );
      });
  };

  useEffect(() => {
    dispatch(
      setIsTokenInvalid({
        isTokenInvalid: false,
      }),
    );
    firebase.auth().onAuthStateChanged(() => {
      if (firebase.auth().currentUser) {
        dispatch(
          setNotification({
            status: 'info',
            message: 'please wait...',
            show: true,
            autoClose: false,
          }),
        );
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            const isDomainHacktiv = firebase
              .auth()
              .currentUser?.email?.split('@')[1]
              .includes('hacktiv8');

            if (!isDomainHacktiv) {
              throw new Error('Your domain must be hacktiv8');
            }
            const { refreshToken } = firebase.auth().currentUser;
            return userAPI({
              method: 'POST',
              url: '/auth/login',
              headers: { 'Content-Type': 'application/json' },
              data: {
                grant_type: 'mfa_google',
                token_id: idToken,
                refresh_token: refreshToken,
              },
            });
          })
          .then(({ data }) => {
            localStorage.setItem('access_token', data.data.access_token);
            localStorage.setItem('time_expires_in', data.data.time_expires_in);
            dispatch(
              setNotification({
                status: 'success',
                message: 'sign in successful',
                show: true,
                autoClose: true,
              }),
            );
            history.push('/batch');
          })
          .catch(() => {
            localStorage.clear();
            dispatch(
              setNotification({
                status: 'error',
                message: 'sign in failed',
                show: true,
                autoClose: true,
              }),
            );
          });
      }
    });
  }, []);

  return (
    <div className="min-h-screen bg-white flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img
              className="h-12 w-auto mx-auto"
              src={hacktivLogo}
              alt="Hacktiv8"
            />
            <h1 className="mt-6 text-2xl text-center font-extrabold text-gray-900">
              Academic Portal
            </h1>
            <h2 className="mt-6 text-lg text-center font-extrabold text-gray-700">
              Sign in with your google account
            </h2>
          </div>

          <div className="mt-8">
            <div>
              <div>
                <button
                  onClick={onSignIn}
                  type="button"
                  className="w-full block bg-white hover:bg-gray-100 focus:bg-gray-100 text-gray-900 font-semibold rounded-lg px-4 py-3 border border-gray-300"
                >
                  <div className="flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      viewBox="0 0 48 48"
                    >
                      <defs>
                        <path
                          id="a"
                          d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
                        />
                      </defs>
                      <clipPath id="b">
                        <use xlinkHref="#a" overflow="visible" />
                      </clipPath>
                      <path
                        clipPath="url(#b)"
                        fill="#FBBC05"
                        d="M0 37V11l17 13z"
                      />
                      <path
                        clipPath="url(#b)"
                        fill="#EA4335"
                        d="M0 11l17 13 7-6.1L48 14V0H0z"
                      />
                      <path
                        clipPath="url(#b)"
                        fill="#34A853"
                        d="M0 37l30-23 7.9 1L48 0v48H0z"
                      />
                      <path
                        clipPath="url(#b)"
                        fill="#4285F4"
                        d="M48 48L17 24l-4-3 35-10z"
                      />
                    </svg>
                    <span className="ml-4">Sign In</span>
                  </div>
                </button>

                <div className="mt-1 grid grid-cols-3 gap-3" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={hacktivBG}
          alt="hacktiv-bg"
        />
      </div>
    </div>
  );
}
