import React, { useState, useMemo } from 'react';
import Select from 'react-select';
import { PlusCircleIcon, TrashIcon, XIcon } from '@heroicons/react/solid';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from '../../../store/features/notificationSlice';
import sendRequest from '../../../helpers/sendRequest';
import { createBatchAssignments } from '../../../store/features/asyncThunk/batchAssignment';
import { addBatchAssignmentOptions } from '../../../store/features/batchAssignmentSlice';

export default function AddAssignmentModal({ setOpen, currentBatchDetail }) {
  const dispatch = useDispatch();
  const { assignments: assignmentOptions } = useSelector(
    (state) => state.batchDashboard,
  );
  const batchAssignmentOptions = useSelector(
    (state) => state.batchAssignment.batchAssignmentOptions,
  );

  const formatedAssignmentOptions = useMemo(() => {
    const formatedAssignments = [];
    assignmentOptions.forEach((assignment) => {
      const alreadyAdded = batchAssignmentOptions.find(
        (batchAssignment) => batchAssignment.assignmentId === assignment.id,
      );
      if (!alreadyAdded) {
        formatedAssignments.push({
          value: assignment.id,
          label: assignment.name,
        });
      }
    });
    return formatedAssignments;
  }, [assignmentOptions]);

  const [assignments, setAssignments] = useState([
    {
      value: '',
      label: '',
    },
  ]);

  const changeAssignment = (val, i) => {
    let isValid = true;
    // eslint-disable-next-line consistent-return
    assignments.forEach((assignment) => {
      if (assignment.value === val.value) {
        dispatch(
          setNotification({
            message: 'Cannot have multiple same assignment',
            status: 'error',
            autoClose: true,
            show: true,
          }),
        );
        isValid = false;
      }
    });
    if (isValid) {
      const newAssignments = JSON.parse(JSON.stringify(assignments));
      newAssignments[i] = val;
      setAssignments(newAssignments);
    }
  };

  const validateAssignment = () => {
    let isValid = true;
    // eslint-disable-next-line consistent-return
    assignments.forEach((assignment) => {
      if (assignment.value === '') {
        isValid = false;
        dispatch(
          setNotification({
            message: 'Assignment cannot be null or empty',
            status: 'error',
            autoClose: true,
            show: true,
          }),
        );
        return isValid;
      }
    });
    return isValid;
  };

  const addAssignmentToBatch = (e) => {
    e.preventDefault();
    const isValid = validateAssignment();

    if (isValid) {
      const assignmentIds = assignments.map((assignment) => assignment.value);
      const payload = {
        batch_id: currentBatchDetail.id,
        assignment_ids: assignmentIds,
        phase: currentBatchDetail.currentPhase,
      };
      sendRequest({
        dispatch,
        actionName: 'add assignment to batch',
        message: {
          waitMsg: 'Adding assignment to batch, please wait ...',
        },
        mainRequest: {
          fn: createBatchAssignments,
          payload,
        },
        closeModal: () => setOpen(false),
      }).then((res) => {
        if (res.payload.data) {
          const newOptions = [];
          res.payload.data.forEach((option) => {
            const optionAssignment = assignmentOptions.find(
              (item) => item.id === option.assignment_id,
            );
            newOptions.push({
              assignment: optionAssignment,
              assignmentId: option.assignment_id,
              batchId: option.batch_id,
              createdAt: option.created_at,
              id: option.id,
              phase: option.phase,
            });
          });
          dispatch(
            addBatchAssignmentOptions({
              newOptions,
            }),
          );
        }
      });
    }
  };

  return (
    <div className="flex flex-col justify-center my-2 mx-4 md:mx-0 text-left bg-white rounded-md overflow-y-auto w-full p-4 ">
      <div className="flex justify-between mb-6 px-3">
        <h2 className="w-1/2 text-lg text-black ">Add Assignment</h2>
        <button type="button" onClick={() => setOpen(false)}>
          <XIcon className="h-6 w-6 bg-red-400 rounded text-gray-100" />
        </button>
      </div>
      <form
        className="flex text-sm flex-wrap px-3 mb-6"
        onSubmit={addAssignmentToBatch}
      >
        <div className="w-full md:w-full">
          {assignments.map((item, i) => (
            <div className="mb-4 flex" key={i}>
              <div className="w-5/6">
                <Select
                  options={formatedAssignmentOptions}
                  value={item}
                  onChange={(val) => {
                    changeAssignment(val, i);
                  }}
                />
              </div>
              <div className="w-1/6 pl-5">
                <button
                  type="button"
                  className="w-full inline-flex justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-red-500 border-red-500 hover:bg-red-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mb-2"
                  onClick={() => {
                    if (i !== 0) {
                      const newAssignments = JSON.parse(
                        JSON.stringify(assignments),
                      );
                      newAssignments.splice(i, 1);
                      setAssignments(newAssignments);
                    }
                  }}
                >
                  <TrashIcon className="h-6 w-6" />
                </button>
              </div>
            </div>
          ))}
        </div>
        <button
          type="button"
          className="w-full inline-flex justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 mb-2"
          onClick={() => {
            const newAssignments = JSON.parse(JSON.stringify(assignments));
            newAssignments.push({
              value: '',
              label: '',
            });
            setAssignments(newAssignments);
          }}
        >
          <PlusCircleIcon className="h-6 w-6 text-white mr-2" />
          <span>Add more assignment</span>
        </button>
        <input
          type="submit"
          className="cursor-pointer w-full items-center px-4 py-2  rounded-md shadow-sm text-sm font-medium text-white  hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2  bg-indigo-500"
        />
      </form>
    </div>
  );
}
