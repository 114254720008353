/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { findStudentWithPagination } from './asyncThunk/student';

const initialState = {
  studentRows: [],
  requestDone: false,
  paginationDetail: {
    currentPage: 1,
    lastPage: 1,
    perPage: 15,
    total: 0,
    from: 1,
    to: 1,
  },
};

export const studentSlice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    setRequestDone: (state, action) => {
      state.requestDone = action.payload.requestDone;
    },
    setStudentPage: (state, action) => {
      state.paginationDetail.currentPage = action.payload.currentPage;
    },
    setStudentPerPage: (state, action) => {
      state.paginationDetail.perPage = action.payload.perPage;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findStudentWithPagination.fulfilled, (state, action) => {
      const {
        current_page: currentPage,
        from,
        last_page: lastPage,
        to,
        total,
        per_page: perPage,
      } = action.payload.data;
      state.paginationDetail = {
        currentPage,
        from,
        to,
        perPage,
        total,
        lastPage,
      };
      const formatedStudents = action.payload.data.results.map((student) => {
        const {
          current_batch: currentBatch,
          email,
          enrolled_batch: enrolledBatch,
          id,
          name,
          payment_type: paymentType,
          student_status: status,
        } = student;
        return {
          currentBatch,
          email,
          enrolledBatch,
          id,
          name,
          paymentType,
          status,
        };
      });
      state.studentRows = formatedStudents;
      return state;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setRequestDone, setStudentPage, setStudentPerPage } = studentSlice.actions;

export default studentSlice.reducer;
