import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import firebase from '../config/firebase';
import { setNotification } from '../store/features/notificationSlice';

export default function PrivateRoute({ children, ...rest }) {
  const dispatch = useDispatch();

  const signOut = () => {
    firebase
      .auth()
      .signOut()
      .finally(() => {
        localStorage.clear();
      });
  };

  const checkToken = (param) => {
    const accessToken = localStorage.getItem('access_token');
    const timeExpire = localStorage.getItem('time_expires_in');
    if (accessToken && timeExpire) {
      const expireDate = new Date(timeExpire);
      const today = new Date();
      const eTime = expireDate.getTime();
      const todayTime = today.getTime();
      if (eTime > todayTime) {
        return children;
      }
      dispatch(
        setNotification({
          status: 'success',
          message: 'your session is expired',
          show: true,
          autoClose: true,
        }),
      );
    }
    signOut();
    return (
      <Redirect
        to={{
          pathname: '/sign-in',
          state: { from: param.location },
        }}
      />
    );
  };
  return <Route {...rest} render={checkToken} />;
}
