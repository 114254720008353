import React from 'react';
import {
  useTable, useSortBy, useFilters, useGlobalFilter
} from 'react-table';
import { matchSorter } from 'match-sorter';
import GlobalFilter from './GlobalFilter';

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}
fuzzyTextFilterFn.autoRemove = (val) => !val;

export default function Table({
  columns,
  data,
  updateMyData,
  needGlobalFilter,
  rowFn,
  additionalStyle,
}) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue !== undefined
          ? String(rowValue)
            .toLowerCase()
            .startsWith(String(filterValue).toLowerCase())
          : true;
      }),
    }),
    [],
  );

  const useTableOptions = {
    columns,
    data,
    filterTypes,
    autoResetPage: false,
    autoResetExpanded: false,
    autoResetGroupBy: false,
    autoResetSelectedRows: false,
    autoResetSortBy: false,
    autoResetFilters: false,
    autoResetRowState: false,
    autoResetGlobalFilter: false,
  };
  if (updateMyData) {
    useTableOptions.updateMyData = updateMyData;
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(useTableOptions, useFilters, useGlobalFilter, useSortBy);

  const getClassName = () => `overflow-x-auto ${additionalStyle}`;

  return (
    <>
      <div className="flex flex-col mb-4 h-auto">
        <div className={getClassName()}>
          <div className="pb-2 align-middle inline-block min-w-full">
            {needGlobalFilter && (
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            )}

            <div className="shadow border border-gray-300 sm:rounded-lg">
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-gray-200 "
              >
                <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup, i) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      key={`head-${i}`}
                    >
                      {headerGroup.headers.map((column, j) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps(),
                          )}
                          key={`head-${i}-${j}`}
                          scope="col"
                          className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {column.render('Header')}
                          {/* Add a sort direction indicator */}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : ''}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-200"
                >
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className="bg-white"
                        key={`body-${i}`}
                      >
                        {row.cells.map((cell, j) => (
                          <td
                            {...cell.getCellProps()}
                            className="px-6 py-2 text-sm text-center text-gray-500 break-words"
                            key={cell.row.cells[j].column.Header + j}
                          >
                            {rowFn
                              ? cell.render('Cell', { rowFn })
                              : cell.render('Cell')}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
