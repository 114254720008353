import React, { useState } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { useDispatch } from 'react-redux';
import ConfirmationModal from '../../../components/ConfirmationModal';
import Modal from '../../../components/Modal';
import { phases, programs, challengeType } from '../../../constant/academic';
import { createAssignment } from '../../../store/features/asyncThunk/assignment';
import sendRequest from '../../../helpers/sendRequest';

export default function AssignmentForm({ setOpen }) {
  const dispatch = useDispatch();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [assignmentInput, setAssignmentInput] = useState({
    name: '',
    phase: 0,
    type: 'challenge',
    program: 'FSJS',
    weight: 10,
    week: 1,
  });

  const changeAssignmentInput = (e) => {
    const field = e.target.name;
    let { value } = e.target;
    if (field === 'phase' || field === 'week' || field === 'weight') {
      value = +value;
    }
    setAssignmentInput({
      ...assignmentInput,
      [field]: value,
    });
  };

  const submitAssignment = () => {
    setOpenConfirmation(false);
    sendRequest({
      dispatch,
      actionName: 'create new assignment',
      message: {
        waitMsg: 'Creating new assignment, please wait ...',
      },
      mainRequest: {
        fn: createAssignment,
        payload: assignmentInput,
      },
      closeModal: () => setOpen(false),
    });
  };

  return (
    <>
      <Modal open={openConfirmation} setOpen={setOpenConfirmation}>
        <ConfirmationModal
          setOpen={setOpenConfirmation}
          message="You cannot update this assignment later, make sure data that you fill already correct before proceeding"
          onConfirmed={submitAssignment}
        />
      </Modal>
      <div className="flex flex-col justify-center my-2 mx-4 md:mx-0 text-left">
        <form
          className="mx-auto bg-white p-6 rounded-md"
          onSubmit={(e) => {
            e.preventDefault();
            setOpenConfirmation(true);
          }}
        >
          <div className="flex justify-between mb-6">
            <h2 className="w-1/2 text-lg text-black ">Add New Assignment</h2>
            <button type="button" onClick={() => setOpen(false)}>
              <XIcon className="h-6 w-6 bg-red-400 rounded-md text-gray-100" />
            </button>
          </div>
          <div className="flex text-sm flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-full px-3 mb-6">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="name"
              >
                Name
              </label>
              <input
                className="w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-md py-3 px-3 focus:outline-none"
                type="text"
                name="name"
                onChange={changeAssignmentInput}
                value={assignmentInput.name}
                required
              />
            </div>
            <div className="flex w-full">
              <div className="w-1/3 px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="phase"
                >
                  Phase
                </label>
                <select
                  name="phase"
                  className="appearance-none w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-md py-3 px-3 focus:outline-none"
                  onChange={changeAssignmentInput}
                  value={assignmentInput.phase}
                >
                  {phases.map((phase) => (
                    <option key={phase}>{phase}</option>
                  ))}
                </select>
              </div>
              <div className="w-1/3  px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="type"
                >
                  Type
                </label>
                <select
                  name="type"
                  className="appearance-none w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-md py-3 px-3 focus:outline-none"
                  onChange={changeAssignmentInput}
                  value={assignmentInput.type}
                >
                  {challengeType.map((type) => (
                    <option key={type}>{type}</option>
                  ))}
                </select>
              </div>
              <div className="w-1/3  px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="program"
                >
                  Program
                </label>
                <select
                  name="program"
                  className="appearance-none w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-md py-3 px-3 focus:outline-none"
                  onChange={changeAssignmentInput}
                  value={assignmentInput.program}
                >
                  {programs.map((val) => (
                    <option key={val}>{val}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex w-full">
              <div className="w-1/2  px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="weight"
                >
                  Weight
                </label>
                <input
                  name="weight"
                  className="w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-md py-3 px-3 focus:outline-none"
                  type="number"
                  required
                  onChange={changeAssignmentInput}
                  value={assignmentInput.weight ? assignmentInput.weight : ''}
                />
              </div>
              <div className="w-1/2 px-3 mb-6">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="week"
                >
                  Week
                </label>
                <input
                  name="week"
                  className="w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-md py-3 px-3 focus:outline-none"
                  type="number"
                  onChange={changeAssignmentInput}
                  value={assignmentInput.week ? assignmentInput.week : ''}
                  required
                />
              </div>
            </div>
            <div className="w-full px-3">
              <button
                type="submit"
                className="w-full bg-indigo-600 text-gray-100 font-bold border border-gray-200 rounded-md py-3 px-3 leading-tight hover:bg-indigo-500 focus:outline-none focus:border-gray-500"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
