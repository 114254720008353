import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  setFocusRubricNotes,
  hideFocusRubricNotes,
} from '../../../store/features/batchAssignmentSlice';

export default function EditableAssignmentCell({
  value: initialValue,
  row,
  column,
  updateMyData, // This is a custom function that we supplied to our table instance
}) {
  const [value, setValue] = useState(initialValue);

  const selectMaxPoints = createSelector(
    (state) => state.batchAssignment,
    (batchAssignment) => {
      const { batchAssignmentDetail, batchAssignmentHeaders } = batchAssignment;
      const { assignmentId } = batchAssignmentDetail.value;
      const { batchAssignmentId } = batchAssignmentDetail.value;
      if (assignmentId === undefined || batchAssignmentId === undefined) {
        return 0;
      }
      if (batchAssignmentHeaders[assignmentId] === undefined) {
        return 0;
      }
      const assignmentRubricId = +column.id.split('.')[1];
      const assignmentRubric = batchAssignmentHeaders[assignmentId].find(
        (rubric) => rubric.id === assignmentRubricId,
      );
      if (assignmentRubric === undefined) {
        return 0;
      }
      const maxPoints = +assignmentRubric.maxPoints;
      return maxPoints;
    },
  );

  const maxPoints = useSelector(selectMaxPoints);
  const dispatch = useDispatch();

  const onChange = (e) => {
    if (+e.target.value > maxPoints) {
      setValue(maxPoints);
    } else {
      setValue(e.target.value);
    }
  };

  const onBlur = () => {
    dispatch(hideFocusRubricNotes());
    const assignmentRubricId = column.id.split('.')[1];
    updateMyData({
      rowIndex: row.index,
      assignmentRubricId: +assignmentRubricId,
      value: +value,
    });
  };

  useEffect(() => {
    setValue(Number(initialValue));
  }, [initialValue]);

  return (
    <input
      type="number"
      className="py-2 text-center"
      value={value}
      onChange={onChange}
      onFocus={() => {
        const assignmentRubricId = column.id.split('.')[1];
        dispatch(
          setFocusRubricNotes({
            assignmentRubricId: +assignmentRubricId,
          }),
        );
      }}
      onBlur={onBlur}
      max={maxPoints}
    />
  );
}
