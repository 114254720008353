export const thunkHandler = async (httpRequestFn, thunkAPI) => {
  try {
    const response = await httpRequestFn;
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      errors: error.response.data.errors,
      status: error.response.status,
    });
  }
};
