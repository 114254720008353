import { createAsyncThunk } from '@reduxjs/toolkit';
import academicAPI from '../../../config/api/academicAPI';
import { thunkHandler } from '../helper/thunkHandler';

export const updateStudent = createAsyncThunk(
  'student/updateStudent',
  async (payload, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');

    return thunkHandler(
      academicAPI.put('/students', payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);

export const findStudentWithPagination = createAsyncThunk(
  'student/findStudentWithPagination',
  async (payload, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');
    const params = {
      search: payload.search,
      current_batch: payload.batch,
      enrolled_batch: payload.enrollBatch,
      payment_type: payload.paymentType,
      student_status: payload.status,
      page: payload.page,
      limit: payload.limit,
    };

    return thunkHandler(
      academicAPI.get('/students/list', {
        params,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);
