import { createAsyncThunk } from '@reduxjs/toolkit';
import academicAPI from '../../../config/api/academicAPI';
import { thunkHandler } from '../helper/thunkHandler';

export const findBatchDetailByCode = createAsyncThunk(
  'batchDashboard/findBatchDetailByCode',
  async (payload, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');

    return thunkHandler(
      academicAPI.get(`/batches/${payload.code}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);

export const findAssignmentOptions = createAsyncThunk(
  'batchDashboard/findAssignmentOptions',
  async (payload, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');

    return thunkHandler(
      academicAPI.get('/assignments/options', {
        params: payload,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);

export const findInstructorOptions = createAsyncThunk(
  'batchDashboard/findInstructorOptions',
  async (payload, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');
    const params = {
      page: 1,
      limit: 200,
      ...payload,
    };

    return thunkHandler(
      academicAPI.get('/instructors', {
        params,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);

export const findTopicOptions = createAsyncThunk(
  'batchDashboard/findTopicOptions',
  async (payload, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');
    const params = {
      page: 1,
      limit: 100,
      ...payload,
    };

    return thunkHandler(
      academicAPI.get('/topics', {
        params,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);
