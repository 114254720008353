import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { XIcon } from '@heroicons/react/solid';
import { useDispatch, useSelector } from 'react-redux';
import sendRequest from '../../../helpers/sendRequest';
import CustomLoading from '../../../components/CustomLoading';
import {
  findStudentProfile,
  upsertStudentProfile,
} from '../../../store/features/asyncThunk/studentProfile';
import {
  changeFieldProfile,
  cleanUp,
  setRequestDone,
} from '../../../store/features/studentProfileSlice';

const UpdateProfileModal = ({ setOpen, student }) => {
  const dispatch = useDispatch();

  const { studentProfile: profile, requestDone } = useSelector(
    (state) => state.studentProfile,
  );

  const changeProfile = (e) => {
    const field = e.target.name;
    const { value } = e.target;
    dispatch(
      changeFieldProfile({
        field,
        value,
      }),
    );
  };
  const updateProfile = () => {
    sendRequest({
      dispatch,
      actionName: 'Update student profile',
      message: {
        waitMsg: 'Updating student profile',
      },
      mainRequest: {
        fn: upsertStudentProfile,
        payload: {
          student_id: student.id,
          github_username: profile.githubUsername,
          wakatime_api: profile.wakatimeApi,
          discord_id: profile.discordId,
          zoom_username: profile.zoomUsername,
          background: profile.background,
          join_reason: profile.joinReason,
          goals: profile.goals,
          supporting_factors: profile.supportingFactors,
          blocker_factors: profile.blockerFactors,
          durability: profile.durability,
          curiousity: profile.curiousity,
          logic_score: profile.logicScore,
          papikostick: profile.papikostick,
        },
      },
      closeModal: () => setOpen(false),
    });
  };
  const fetchStudentProfile = () => {
    sendRequest({
      dispatch,
      actionName: 'Find student profile',
      message: {
        waitMsg: 'Finding student profile, please wait',
      },
      mainRequest: {
        fn: findStudentProfile,
        payload: {
          studentId: student.id,
        },
      },
      setRequestDone,
    });
  };

  useEffect(() => {
    if (!requestDone) {
      fetchStudentProfile();
    }
  }, [requestDone]);

  useEffect(
    () => function clearState() {
      dispatch(cleanUp());
    },
    [],
  );
  return (
    <>
      <div className="flex flex-col justify-center my-2 mx-4 md:mx-0 text-left w-full">
        <form
          className="mx-auto bg-white p-6 rounded-md w-3/4"
          onSubmit={(e) => {
            e.preventDefault();
            updateProfile();
          }}
        >
          <div className="flex justify-between mb-6">
            <h2 className="w-1/2 text-lg text-black ">
              Change
              {' '}
              {student.name}
              {' '}
              Profile
            </h2>
            <button type="button" onClick={() => setOpen(false)}>
              <XIcon className="h-6 w-6 bg-red-400 rounded text-gray-100" />
            </button>
          </div>
          <div className="flex flex-wrap -mx-3">
            {requestDone ? (
              <>
                <div className="w-full md:w-full px-3 mb-4">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="background"
                  >
                    Background
                    <input
                      className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none"
                      type="text"
                      name="background"
                      value={profile.background}
                      onChange={changeProfile}
                    />
                  </label>
                </div>
                <div className="w-full md:w-full px-3 mb-4">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="discordId"
                  >
                    Discord Id
                    <input
                      className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none"
                      type="text"
                      name="discordId"
                      value={profile.discordId}
                      onChange={changeProfile}
                      required
                    />
                  </label>
                </div>
                <div className="w-full md:w-full px-3 mb-4">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="wakatimeApi"
                  >
                    API Wakatime
                    <input
                      className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none"
                      type="text"
                      name="wakatimeApi"
                      value={profile.wakatimeApi}
                      onChange={changeProfile}
                    />
                  </label>
                </div>
                <div className="w-full md:w-full px-3 mb-4">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="githubUsername"
                  >
                    Github
                    <input
                      className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none"
                      type="text"
                      name="githubUsername"
                      value={profile.githubUsername}
                      onChange={changeProfile}
                      required
                    />
                  </label>
                </div>
                <div className="w-full md:w-full px-3 mb-4">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="zoomUsername"
                  >
                    Zoom
                    <input
                      className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none"
                      type="text"
                      name="zoomUsername"
                      value={profile.zoomUsername}
                      onChange={changeProfile}
                    />
                  </label>
                </div>
                <div className="w-full md:w-full px-3 mb-4">
                  <label
                    className="block uppercase tracking-wide text-gray-700 font-bold mb-2"
                    htmlFor="papikostick"
                  >
                    Papikostick
                    <textarea
                      id="papikostick"
                      name="papikostick"
                      rows={6}
                      className="px-2 pt-2 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 border border-gray-400 rounded-md mb-2"
                      placeholder="Write papikostick here..."
                      value={profile.papikostick}
                      onChange={changeProfile}
                      required
                    />
                  </label>
                </div>
                <div className="w-full md:w-full px-3 mb-6">
                  <button
                    type="submit"
                    className="appearance-none block w-full bg-indigo-600 text-gray-100 font-bold border border-gray-200 rounded-lg py-3 px-3 leading-tight hover:bg-blue-500 focus:outline-none  focus:border-gray-500"
                  >
                    Submit
                  </button>
                </div>
              </>
            ) : (
              <div className="mx-auto">
                <CustomLoading width="150" />
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

UpdateProfileModal.propTypes = {
  student: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default UpdateProfileModal;
