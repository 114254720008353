import React from 'react';

export default function ActionCell({ rowFn, row }) {
  // props { row, value: showModal }
  return (
    <button
      className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-500 border-indigo-500 hover:bg-indigo-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      type="button"
      onClick={() => {
        // will save to db with this component
        // (row, showModal)
        rowFn({
          rowIndex: row.id,
        });
      }}
    >
      SAVE
    </button>
  );
}
