/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { findTopic } from './asyncThunk/topic';

const initialState = {
  topicRows: [],
  requestDone: false,
  paginationDetail: {
    currentPage: 1,
    lastPage: 1,
    perPage: 15,
    total: 0,
    from: 1,
    to: 1,
  },
};

export const topicSlice = createSlice({
  name: 'topic',
  initialState,
  reducers: {
    setRequestDone: (state, action) => {
      state.requestDone = action.payload.requestDone;
    },
    setPage: (state, action) => {
      state.paginationDetail.currentPage = action.payload.currentPage;
    },
    setPerPage: (state, action) => {
      state.paginationDetail.perPage = action.payload.perPage;
    },
    addTopicRows: (state, action) => {
      state.topicRows.pop();
      state.topicRows.unshift(action.payload.topic);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findTopic.fulfilled, (state, action) => {
      state.topicRows = action.payload.data.rows.map((row) => {
        const {
          id, phase, program, topic, created_at: createdAt
        } = row;
        return {
          id,
          phase,
          program,
          topic,
          createdAt: createdAt.slice(0, 10),
        };
      });
      const {
        total, current_page, per_page, last_page, from, to
      } = action.payload.data.pagination;
      state.paginationDetail = {
        currentPage: current_page,
        lastPage: last_page,
        perPage: per_page,
        total,
        from,
        to,
      };
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setRequestDone, setPage, setPerPage, addTopicRows
} = topicSlice.actions;

export default topicSlice.reducer;
