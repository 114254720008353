/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { findStudentStatus } from './asyncThunk/studentStatus';

const initialState = {
  studentStatusList: [{ name: '', value: '' }],
};

export const studentStatusSlice = createSlice({
  name: 'studentStatus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findStudentStatus.fulfilled, (state, action) => {
      if (action.payload.data) {
        const formatedStudentStatus = action.payload.data.map(
          (studentStatus) => {
            const {
              id,
              created_at: createdAt,
              updated_at: updatedAt,
              name,
              value,
            } = studentStatus;
            return {
              id,
              createdAt,
              updatedAt,
              name,
              value,
            };
          },
        );

        state.studentStatusList = [
          { name: '', value: '' },
          ...formatedStudentStatus,
        ];
      }
      return state;
    });
  },
});

export default studentStatusSlice.reducer;
