import React from 'react';
import { phases, programs } from '../../../constant/academic';

export default function TopicFilter({
  filterInput,
  setFilterInput,
  fetchTopic,
}) {
  const changeFilterInput = (e) => {
    const field = e.target.name;
    setFilterInput({
      ...filterInput,
      [field]: e.target.value,
    });
  };

  return (
    <>
      <form
        className="text-center"
        onSubmit={(e) => {
          e.preventDefault();
          fetchTopic();
        }}
      >
        <div>
          <div className="mb-2 flex rounded-md shadow-sm border border-gray-300">
            <span className="w-1/3 inline-flex items-center px-3 rounded-l-md  bg-gray-50 text-gray-500 sm:text-sm">
              Topic
            </span>
            <input
              onChange={changeFilterInput}
              type="text"
              name="topic"
              id="topic"
              className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
              placeholder="topic..."
            />
          </div>
        </div>
        <div>
          <div className="mb-2 flex rounded-md shadow-sm border border-gray-300 pr-2">
            <span className="inline-flex w-1/3 items-center px-3 rounded-l-md  bg-gray-50 text-gray-500 sm:text-sm">
              Program
            </span>
            <select
              onChange={changeFilterInput}
              name="program"
              id="program"
              className="bg-white outline-none flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
            >
              {programs.map((val) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <div className="mb-2 flex rounded-md shadow-sm border border-gray-300 pr-2">
            <span className="w-1/3 inline-flex items-center px-3 rounded-l-md  bg-gray-50 text-gray-500 sm:text-sm">
              Phase
            </span>
            <select
              onChange={changeFilterInput}
              name="phase"
              id="phase"
              className="flex-1 outline-none min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 bg-white sm:text-sm border-gray-300"
            >
              {phases.map((phase) => (
                <option key={phase} value={phase}>
                  {phase}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button
          type="submit"
          className="w-full text-center  border border-transparent text-xs font-medium rounded  text-white bg-indigo-600 hover:bg-indigo-700 py-2"
        >
          SEARCH TOPIC
        </button>
      </form>
    </>
  );
}
