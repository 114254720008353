import React from 'react';
import { NavLink } from 'react-router-dom';

const tabs = [
  { name: 'Student', href: 'student', current: false },
  { name: 'Schedule', href: 'schedule', current: false },
  { name: 'Absence', href: 'absence', current: true },
  {
    name: 'Assignment',
    href: 'assignment',
    current: false,
  },
  {
    name: 'Weekly Category',
    href: 'weekly-category',
    current: false,
  },
  {
    name: 'Accumulative Score',
    href: 'cummulative',
    current: false,
  },
];

export default function BatchTab({ pathName }) {
  return (
    <div className="mb-4">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <NavLink
                exact
                key={tab.name}
                to={`${pathName}/${tab.href}`}
                className="border-transparent text-gray-500  whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                activeClassName="border-yellow-500 text-yellow-600"
              >
                {tab.name}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
