import { configureStore } from '@reduxjs/toolkit';
import batchAssignmentReducer from './features/batchAssignmentSlice';
import topicReducer from './features/topicSlice';
import notificationReducer from './features/notificationSlice';
import assignmentReducer from './features/assignmentSlice';
import batchReducer from './features/batchSlice';
import scheduleLectureReducer from './features/scheduleLectureSlice';
import batchDashboardReducer from './features/batchDashboardSlice';
import weeklyCategoryReducer from './features/weeklyCategorySlice';
import batchStudentReducer from './features/batchStudentSlice';
import instructorReducer from './features/instructorSlice';
import cummulativeScoreReducer from './features/cummulativeScoreSlice';
import absenceReducer from './features/absenceSlice';
import studentReducer from './features/studentSlice';
import studentNoteReducer from './features/studentNoteSlice';
import studentProfileReducer from './features/studentProfileSlice';
import userTokenReducer from './features/userTokenSlice';
import paymentTypeReducer from './features/paymentTypeSlice';
import studentStatusReducer from './features/studentStatusSlice';
import studentJourneyReducer from './features/studentJourneySlice';

export const store = configureStore({
  reducer: {
    batchAssignment: batchAssignmentReducer,
    topic: topicReducer,
    notification: notificationReducer,
    assignment: assignmentReducer,
    batch: batchReducer,
    scheduleLecture: scheduleLectureReducer,
    batchDashboard: batchDashboardReducer,
    weeklyCategory: weeklyCategoryReducer,
    batchStudent: batchStudentReducer,
    instructor: instructorReducer,
    cummulativeScore: cummulativeScoreReducer,
    absence: absenceReducer,
    student: studentReducer,
    studentNote: studentNoteReducer,
    studentProfile: studentProfileReducer,
    userToken: userTokenReducer,
    paymentType: paymentTypeReducer,
    studentStatus: studentStatusReducer,
    studentJourney: studentJourneyReducer,
  },
});
