/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';

export default function Collapsible({
  open,
  setOpen,
  children,
  title,
  headerClass,
}) {
  const changeOpen = useCallback(() => {
    setOpen(!open);
  });
  const getClassNames = () => {
    let classNames = 'flex justify-between cursor-pointer p-4 ';
    if (headerClass) {
      classNames += headerClass;
    }
    return classNames;
  };
  return (
    <>
      <div>
        <div className={getClassNames()} onClick={changeOpen}>
          <h1 className="text-gray-700">{title}</h1>
          {open ? (
            <ChevronUpIcon className="h-6 w-6" />
          ) : (
            <ChevronDownIcon className="h-6 w-6" />
          )}
        </div>
        {open ? (
          <div className="mb-2">
            <div className="border border-b-2">{children}</div>
            {' '}
          </div>
        ) : null}
      </div>
    </>
  );
}
