import { createAsyncThunk } from '@reduxjs/toolkit';
import academicAPI from '../../../config/api/academicAPI';
import { thunkHandler } from '../helper/thunkHandler';

export const findAccumulativeScore = createAsyncThunk(
  'AccumulativeScore/findAccumulativeScore',
  async (payload, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');
    const params = {
      batch: payload.batchId,
      phase: payload.phase,
    };
    let url = '/scores/accumulative';
    if (!payload.isBatchActive) {
      url += '/inactive-phase';
    }

    return thunkHandler(
      academicAPI.get(url, {
        params,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);

export const findGraduationPreview = createAsyncThunk(
  'AccumulativeScore/findAccumulativeScore',
  async (payload, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');
    const params = {
      batch: payload.batchId,
      phase: payload.phase,
    };

    return thunkHandler(
      academicAPI.get('/scores/graduation-preview', {
        params,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);
