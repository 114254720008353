import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from '../../components/DataTable';
import ShowNotesCell from './components/ShowNotesCell';
import EditableCategoryCell from './components/EditableCategoryCell';
import EditableRemarkCell from './components/EditableRemarkCell';
import Modal from '../../components/Modal';

import {
  changeWeeklyCategories,
  changeWeeklyRemarks,
  setRequestDone,
} from '../../store/features/weeklyCategorySlice';
import ActionCell from './components/ActionCell';
import NotesModal from './components/NotesModal';

import sendRequest from '../../helpers/sendRequest';
import {
  findWeeklyCategories,
  createManyWeeklyCategories,
} from '../../store/features/asyncThunk/weeklyCategory';

export default function BatchDashboardWeekly({ isBatchActive }) {
  const dispatch = useDispatch();
  const requestDone = useSelector((state) => state.weeklyCategory.requestDone);
  const { requestDone: requestBatchDetailDone, currentBatchDetail } = useSelector((state) => state.batchDashboard);
  const params = useParams();
  const [weekList, setWeekList] = useState([]);
  const [open, setOpen] = useState(false);

  const [studentId, setStudentId] = useState(null);
  const showNotesModal = (id) => {
    setStudentId(id);
    setOpen(true);
  };
  const weeklyCategoriesSelector = ({ weeks }) => createSelector(
    (state) => state.weeklyCategory.weeklyCategoryRows,
    (state) => state.batchStudent.students,
    (weeklyCategories, students) => weeklyCategories.map((student) => {
      const studentIndex = students.findIndex(
        (row) => row.id === student.id,
      );
      const formatedStudent = {
        id: student.id,
        'show notes': showNotesModal,
      };
      if (studentIndex !== -1) {
        formatedStudent.name = students[studentIndex].name;
        formatedStudent.buddy = students[studentIndex].buddy?.name;
      }
      weeks.forEach((week) => {
        const studentWeek = student.weeklyCategories.filter(
          (item) => item.week === week,
        );
        if (studentWeek.length === 0) {
          formatedStudent[`week ${week} category`] = '';
          formatedStudent[`week ${week} remark`] = '';
        } else {
          formatedStudent[`week ${week} category`] = studentWeek[0].weeklyCategory;
          formatedStudent[`week ${week} remark`] = studentWeek[0].remark;
        }
      });
      return formatedStudent;
    }),
  );

  const weeklyCategoryHeaders = useMemo(() => {
    const headers = [
      {
        Header: 'name',
        accessor: 'name',
      },
      {
        Header: 'buddy',
        accessor: 'buddy',
      },
      {
        Header: 'show notes',
        accessor: 'show notes',
        Cell: ShowNotesCell,
        disableSortBy: true,
      },
    ];
    weekList.forEach((week) => {
      headers.push({
        Header: `week ${week}`,
        columns: [
          {
            Header: 'category',
            accessor: `week ${week} category`,
            Cell: EditableCategoryCell,
          },
          {
            Header: 'remark',
            accessor: `week ${week} remark`,
            Cell: EditableRemarkCell,
          },
        ],
      });
    });
    if (!isBatchActive) {
      return headers;
    }
    return [
      ...headers,
      {
        Header: 'action',
        accessor: 'id',
        Cell: ActionCell,
        disableSortBy: true,
      },
    ];
  }, [weekList, isBatchActive]);

  useEffect(() => {
    if (params.code.includes('FSJF')) {
      // total week on 1 batch on flex program
      setWeekList([1, 2, 3, 4, 5, 6, 7, 8]);
    } else {
      setWeekList([1, 2, 3, 4]);
    }
  }, [params]);

  useEffect(() => {
    if (!requestDone && requestBatchDetailDone) {
      sendRequest({
        dispatch,
        actionName: 'Find student weekly categories',
        message: {
          waitMsg: 'Finding student weekly categories, please wait',
        },
        mainRequest: {
          fn: findWeeklyCategories,
          payload: {
            batchId: currentBatchDetail.id,
            phase: params.phase,
          },
        },
        setRequestDone,
      });
    }
  }, [requestDone, requestBatchDetailDone, params.phase]);

  const updateCell = (payload) => {
    if (payload.field === 'category') {
      dispatch(changeWeeklyCategories(payload));
    } else {
      dispatch(changeWeeklyRemarks(payload));
    }
  };

  const updateWeeklyCategory = (payload) => {
    sendRequest({
      dispatch,
      actionName: 'Update student weekly categories',
      message: {
        waitMsg: 'Updating student weekly categories, please wait',
      },
      mainRequest: {
        fn: createManyWeeklyCategories,
        payload,
      },
    });
  };

  const formatedRows = useSelector(
    weeklyCategoriesSelector({
      weeks: weekList,
    }),
  );

  return (
    <>
      <Modal open={open} setOpen={setOpen}>
        <NotesModal setOpen={setOpen} studentId={studentId} />
      </Modal>
      <DataTable
        rows={formatedRows}
        headers={weeklyCategoryHeaders}
        needGlobalFilter
        additionalStyle="pb-64 no-scrollbar"
        updateCell={updateCell}
        rowFn={updateWeeklyCategory}
      />
    </>
  );
}
