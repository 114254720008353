import React from 'react';
import PropTypes from 'prop-types';

export default function HeadingTab({ headingTitle, children }) {
  return (
    <div className="mb-4">
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {headingTitle}
          </h2>
        </div>
        {children || null}
      </div>
    </div>
  );
}

HeadingTab.propTypes = {
  headingTitle: PropTypes.string,
};
