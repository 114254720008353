import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { absenceStatus } from '../../../constant/academic';

export default function EditableAbsenceStatus({
  row,
  value: initialValue,
  column,
  updateMyData,
}) {
  const [value, setValue] = useState({
    label: initialValue,
    value: initialValue,
  });
  const options = useMemo(() => absenceStatus.map((status) => ({
    label: status,
    value: status,
  })),);
  useEffect(() => {
    setValue({
      label: initialValue,
      value: initialValue,
    });
  }, [initialValue]);
  return (
    <div>
      <Select
        options={options}
        value={value}
        onChange={(val) => {
          const scheduleLectureId = Number(column.id.split('absences.')[1]);
          const rowIndex = row.id;
          updateMyData({
            scheduleLectureId,
            rowIndex,
            value: val.value,
          });
        }}
      />
    </div>
  );
}
