import React from 'react';

export default function ActionCell({ rowFn, row }) {
  return (
    <>
      {row.original?.assignmentRubrics?.length > 0 ? (
        <button
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-500 border-indigo-500 hover:bg-indigo-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          type="button"
          onClick={() => {
            rowFn({
              showAssignmentRubric: true,
              assignmentDetail: row.original,
            });
          }}
        >
          Show Rubrics
        </button>
      ) : (
        <button
          className="inline-flex items-center px-5 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-500 border-yellow-500 hover:bg-yellow-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
          type="button"
          onClick={() => {
            rowFn({
              addAssignmentRubric: true,
              assignmentDetail: row.original,
            });
          }}
        >
          Add Rubrics
        </button>
      )}
    </>
  );
}
