import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CreateScheduleModal from './components/CreateScheduleModal';
import Modal from '../../components/Modal';
import TableSchedule from './components/TableSchedule';
import { findScheduleLecture } from '../../store/features/asyncThunk/scheduleLecture';
import { findTopicOptions } from '../../store/features/asyncThunk/batchDashboard';
import { setRequestDone } from '../../store/features/scheduleLectureSlice';
import { setRequestTopicDone } from '../../store/features/batchDashboardSlice';
import sendRequest from '../../helpers/sendRequest';

export default function BatchDashboardSchedule({
  currentBatchDetail,
  isBatchActive,
}) {
  const dispatch = useDispatch();
  const params = useParams();
  const { requestDone } = useSelector((state) => state.scheduleLecture);
  const requestTopicDone = useSelector(
    (state) => state.batchDashboard.requestTopicDone,
  );
  const [openModal, setOpenModal] = useState(false);

  const fetchScheduleLectures = () => {
    dispatch(
      setRequestDone({
        requestDone: false,
      }),
    );
    sendRequest({
      dispatch,
      actionName: 'find schedules',
      message: {
        waitMsg: 'Find schedules, please wait ...',
      },
      mainRequest: {
        fn: findScheduleLecture,
        payload: {
          batchId: currentBatchDetail.id,
          phase: params.phase,
        },
      },
      setRequestDone,
    });
  };

  const fetchTopicOptions = () => {
    const program = params.code.slice(0, 4);
    dispatch(
      findTopicOptions({
        phase: params.phase,
        program,
      }),
    ).then(() => {
      dispatch(setRequestTopicDone({ requestDone: true }));
    });
  };

  useEffect(() => {
    if (currentBatchDetail.code !== undefined && requestDone === false) {
      fetchScheduleLectures();
    }
  }, [currentBatchDetail.code]);

  useEffect(() => {
    if (!requestTopicDone) {
      fetchTopicOptions();
    }
  }, [requestTopicDone]);

  return (
    <div>
      <Modal open={openModal} setOpen={setOpenModal}>
        <CreateScheduleModal
          setOpen={setOpenModal}
          currentBatchDetail={currentBatchDetail}
          fetchScheduleLectures={fetchScheduleLectures}
        />
      </Modal>
      <div className="flex mb-4">
        {isBatchActive && (
          <button
            onClick={() => setOpenModal(true)}
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
          >
            Add Schedule
          </button>
        )}
      </div>
      <TableSchedule isBatchActive={isBatchActive} />
    </div>
  );
}
