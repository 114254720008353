import { createAsyncThunk } from '@reduxjs/toolkit';
import academicAPI from '../../../config/api/academicAPI';
import { thunkHandler } from '../helper/thunkHandler';

export const findWeeklyCategories = createAsyncThunk(
  'weeklyCategory/findWeeklyCategories',
  async (payload, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');
    const params = {
      batch: payload.batchId,
      phase: payload.phase,
    };

    return thunkHandler(
      academicAPI.get('/students/weekly-categories', {
        params,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);

export const createManyWeeklyCategories = createAsyncThunk(
  'weeklyCategory/createManyWeeklyCategories',
  async (payload, thunkAPI) => {
    const { weeklyCategory } = thunkAPI.getState();
    const { weeklyCategoryRows } = weeklyCategory;
    const currentWeeklyCategory = weeklyCategoryRows[payload.rowIndex];
    if (!currentWeeklyCategory) {
      return thunkAPI.rejectWithValue({
        errors: ['Weekly category not found'],
        status: 404,
      });
    }
    const filteredWeeklyCategories = currentWeeklyCategory.weeklyCategories?.filter(
      (item) => item.weeklyCategory !== '',
    );
    const formattedWeeklyCategories = filteredWeeklyCategories.map((item) => ({
      weekly_category: item.weeklyCategory,
      week: item.week,
      remark: item.remark || null,
    }));
    const requestBody = {
      student_id: currentWeeklyCategory.id,
      categories: formattedWeeklyCategories,
    };
    const accessToken = localStorage.getItem('access_token');

    return thunkHandler(
      academicAPI.post('/student-weekly-categories/bulk', requestBody, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);
