import React, { useMemo, useState } from 'react';
import { XIcon } from '@heroicons/react/outline';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { setNotification } from '../../../store/features/notificationSlice';
import sendRequest from '../../../helpers/sendRequest';
import { createScheduleLecture } from '../../../store/features/asyncThunk/scheduleLecture';

const selectTopicOptions = createSelector(
  (state) => state.batchDashboard.topics,
  (topics) => topics.map((item) => ({
    value: item.id,
    label: item.topic,
  })),
);

const selectLecturerOptions = createSelector(
  (state) => state.batchDashboard.instructors,
  (instructors) => instructors.map((item) => ({
    value: item.id,
    label: item.fullName,
  })),
);

export default function CreateScheduleModal({
  setOpen,
  currentBatchDetail,
  fetchScheduleLectures,
}) {
  const today = useMemo(() => new Date().toISOString().split('T')[0]);
  const dispatch = useDispatch();
  const [selectedTopic, setSelectedTopic] = useState('');
  const [selectedLecturer, setSelectedLecturer] = useState('');
  const [selectedTimeFrame, setSelectedTimeFrame] = useState({
    value: 'PM',
    label: 'PM',
  });
  const [selectedLectureDate, setSelectedLectureDate] = useState('');
  const topicOptions = useSelector(selectTopicOptions);
  const lecturerOptions = useSelector(selectLecturerOptions);
  const timeFrame = [
    { value: 'AM', label: 'AM' },
    { value: 'PM', label: 'PM' },
  ];

  const createNewSchedule = (e) => {
    e.preventDefault();
    if (
      selectedTopic === ''
      || selectedLecturer === ''
      || selectedTimeFrame === ''
      || selectedLectureDate === ''
    ) {
      dispatch(
        setNotification({
          show: true,
          message: 'Field cannot be empty, please check your input',
          autoClose: true,
          status: 'error',
        }),
      );
    } else {
      const lectureDate = new Date(selectedLectureDate).toISOString();
      const payload = {
        batch_id: currentBatchDetail.id,
        topic_id: selectedTopic.value,
        time_frame: selectedTimeFrame.value,
        phase: currentBatchDetail.currentPhase,
        lecturer: selectedLecturer.value,
        lecture_date: lectureDate,
      };
      sendRequest({
        dispatch,
        actionName: 'create new schedule',
        message: {
          waitMsg: 'Creating new schedule, please wait ...',
        },
        mainRequest: {
          fn: createScheduleLecture,
          payload,
        },
        closeModal: () => setOpen(false),
        additionalFetch: fetchScheduleLectures,
      });
    }
  };

  return (
    <div className="flex flex-col justify-center my-2 mx-4 md:mx-0 text-left">
      <form
        className="mx-auto bg-white p-6 rounded-md"
        onSubmit={createNewSchedule}
      >
        <div className="flex justify-between mb-4">
          <h2 className="w-1/2 text-lg text-black ">Add Schedule</h2>
          <button type="button" onClick={() => setOpen(false)}>
            <XIcon className="h-6 w-6 bg-red-400 rounded text-gray-100" />
          </button>
        </div>
        <div className="flex flex-col justify-between mb-4">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="topic"
          >
            Topic
          </label>
          <Select
            options={topicOptions}
            value={selectedTopic}
            onChange={(value) => {
              setSelectedTopic(value);
            }}
          />
        </div>

        <div className="flex flex-col justify-between mb-4">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="lecturer"
          >
            Lecturer
          </label>
          <Select
            options={lecturerOptions}
            value={selectedLecturer}
            onChange={setSelectedLecturer}
          />
        </div>

        <div className="flex flex-wrap -mx-3">
          <div className="flex w-full">
            <div className="w-full md:w-1/2 px-3 mb-6">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="timeFrame"
              >
                Timeframe
              </label>
              <Select
                defaultValue={selectedTimeFrame}
                options={timeFrame}
                value={selectedTimeFrame}
                onChange={setSelectedTimeFrame}
              />
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="date"
              >
                Date
              </label>
              <input
                min={today}
                id="date"
                name="date"
                type="date"
                className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-300 rounded p-2 leading-tight focus:outline-none"
                onChange={(e) => {
                  setSelectedLectureDate(e.target.value);
                }}
                value={selectedLectureDate}
              />
            </div>
          </div>
          <div className="w-full md:w-full px-3 mb-6">
            <button
              type="submit"
              className="appearance-none block w-full bg-indigo-600 text-gray-100 font-bold border border-gray-200 rounded-lg py-3 px-3 leading-tight hover:bg-blue-500 focus:outline-none  focus:border-gray-500"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
