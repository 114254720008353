import { createAsyncThunk } from '@reduxjs/toolkit';
import academicAPI from '../../../config/api/academicAPI';
import { thunkHandler } from '../helper/thunkHandler';

export const findStudentProfile = createAsyncThunk(
  'studentProfile/findStudentProfile',
  async (payload, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');
    const params = {
      'student-id': payload.studentId,
    };

    return thunkHandler(
      academicAPI.get('/student-profiles', {
        params,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);

export const upsertStudentProfile = createAsyncThunk(
  'studentProfile/upsertStudentProfile',
  async (payload, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');
    const { student_id: studentId, ...data } = payload;

    return thunkHandler(
      academicAPI.put(`/student-profiles/${studentId}`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);
