import React, { useState, useEffect } from 'react';
import Select from 'react-select';

export default function TimeFrameCell({ row, updateMyData, value }) {
  const timeFrame = [
    { value: 'AM', label: 'AM' },
    { value: 'PM', label: 'PM' },
  ];
  const [selectedTimeFrame, setSelectedTimeFrame] = useState({
    value,
    label: value,
  });

  useEffect(() => {
    setSelectedTimeFrame({
      value,
      label: value,
    });
  }, [value]);

  return (
    <div>
      <Select
        options={timeFrame}
        value={selectedTimeFrame}
        onChange={(val) => {
          const newRow = {
            ...row.original,
          };
          newRow.timeFrame = val.value;
          updateMyData({
            rowIndex: row.id,
            newRow,
          });
        }}
      />
    </div>
  );
}
