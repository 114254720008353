/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  createStudentNote,
  deleteStudentNote,
  findStudentNote,
} from './asyncThunk/studentNotes';

const initialState = {
  currentStudentId: null,
  studentNoteRows: [],
  requestDone: false,
};

export const studentNoteSlice = createSlice({
  name: 'studentNote',
  initialState,
  reducers: {
    setRequestDone: (state, action) => {
      state.requestDone = action.payload.requestDone;
    },
    cleanUp: () => ({
      currentStudentId: null,
      studentNoteRows: [],
      requestDone: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(findStudentNote.fulfilled, (state, action) => {
      if (!action.payload.data) {
        return state;
      }
      action.payload.data.sort((a, b) => a.phase - b.phase);
      const formatedStudentNotes = action.payload.data.map((notes) => {
        const {
          id, phase, note, buddy, created_at: createdAt
        } = notes;
        const formatedNote = {
          id,
          phase,
          note,
          createdAt: createdAt.slice(0, 10),
        };
        if (!buddy) {
          formatedNote.buddy = '';
        } else {
          formatedNote.buddy = buddy.full_name;
        }
        return formatedNote;
      });

      state.studentNoteRows = formatedStudentNotes;

      return state;
    });
    builder.addCase(deleteStudentNote.fulfilled, (state, action) => {
      const { studentNoteId } = action.meta.arg;
      state.studentNoteRows = state.studentNoteRows.filter(
        (note) => note.id !== studentNoteId,
      );
      return state;
    });
    builder.addCase(createStudentNote.fulfilled, (state, action) => {
      const {
        id,
        phase,
        note,
        buddy,
        created_at: createdAt,
      } = action.payload.data;
      const formatedNote = {
        id,
        phase,
        note,
        createdAt: createdAt.slice(0, 10),
      };
      if (!buddy) {
        formatedNote.buddy = '';
      } else {
        formatedNote.buddy = buddy.full_name;
      }

      state.studentNoteRows.push(formatedNote);
      return state;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setRequestDone, cleanUp } = studentNoteSlice.actions;

export default studentNoteSlice.reducer;
