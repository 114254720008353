import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import Select from 'react-select';

const instructorSelector = createSelector(
  (state) => state.batchDashboard,
  (batchDashboard) => batchDashboard.instructors.map((instructor) => ({
    value: instructor.id,
    label: instructor.fullName,
  })),
);
export default function AssigneeCell({ row, updateMyData, value }) {
  const instructors = useSelector(instructorSelector);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState({
    value: row.original.assignee.id,
    label: value.fullName,
  });
  useEffect(() => {
    setSelectedTimeFrame({
      value: row.original.assignee.id,
      label: value.fullName,
    });
  }, [value]);
  return (
    <Select
      options={instructors}
      value={selectedTimeFrame}
      onChange={(val) => {
        const newRow = {
          ...row.original,
          assignee: {
            id: val.value,
            fullName: val.label,
          },
        };
        updateMyData({
          rowIndex: row.id,
          newRow,
        });
      }}
    />
  );
}
