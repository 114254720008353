import React, { useState, useEffect } from 'react';
import { ChatAltIcon } from '@heroicons/react/solid';
import { XIcon, TrashIcon } from '@heroicons/react/outline';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../components/Modal';
import ConfirmationModal from '../../../components/ConfirmationModal';
import {
  findStudentNote,
  deleteStudentNote,
  createStudentNote,
} from '../../../store/features/asyncThunk/studentNotes';
import CustomLoading from '../../../components/CustomLoading';
import sendRequest from '../../../helpers/sendRequest';
import {
  cleanUp,
  setRequestDone,
} from '../../../store/features/studentNoteSlice';

export default function NotesModal({ setOpen, studentId }) {
  const [deletedId, setDeletedId] = useState(null);
  const [newNote, setNewNote] = useState('');

  const changeNewNote = (e) => {
    setNewNote(e.target.value);
  };

  const { studentNoteRows, requestDone } = useSelector(
    (state) => state.studentNote,
  );

  const studentDetail = useSelector((state) => {
    const currentStudent = state.batchStudent.students.find(
      (student) => student.id === studentId,
    );
    if (!currentStudent) {
      return {};
    }
    return currentStudent;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setRequestDone({
        requestDone: false,
      }),
    );
    sendRequest({
      dispatch,
      actionName: 'Find student notes',
      message: {
        waitMsg: 'Finding student notes, please wait',
      },
      mainRequest: {
        fn: findStudentNote,
        payload: {
          studentId,
        },
      },
      setRequestDone,
    });
    return function clearState() {
      dispatch(cleanUp());
    };
  }, []);

  const deleteStudentNotes = () => {
    setOpenConfirmation(false);
    sendRequest({
      dispatch,
      actionName: 'Delete student note',
      message: {
        waitMsg: 'Deleting student notes, please wait',
      },
      mainRequest: {
        fn: deleteStudentNote,
        payload: {
          studentNoteId: deletedId,
        },
      },
    });
  };

  const submitStudentNote = (e) => {
    e.preventDefault();
    sendRequest({
      dispatch,
      actionName: 'Create student note',
      message: {
        waitMsg: 'Creating student notes, please wait',
      },
      mainRequest: {
        fn: createStudentNote,
        payload: {
          student_id: studentId,
          note: newNote,
        },
      },
    }).finally(() => {
      setNewNote('');
    });
  };

  const [openConfirmation, setOpenConfirmation] = useState(false);

  return (
    <>
      <Modal open={openConfirmation} setOpen={setOpenConfirmation}>
        <ConfirmationModal
          setOpen={setOpenConfirmation}
          onConfirmed={deleteStudentNotes}
          message="Are you sure want to delete this student notes?"
        />
      </Modal>
      <div
        className="flow-root text-left bg-white rounded-md relative overflow-y-auto"
        style={{
          maxHeight: '80vh',
        }}
      >
        <div className="flex justify-between pt-5 px-3">
          <h2 className="w-1/2 text-lg text-black ">
            {' '}
            {studentDetail.name}
            {' '}
            - Student Notes
            {' '}
          </h2>
          <button type="button" onClick={() => setOpen(false)}>
            <XIcon className="h-6 w-6 bg-red-400 rounded text-gray-100" />
          </button>
        </div>
        {requestDone ? (
          <>
            <ul className="py-5 px-3">
              {studentNoteRows.map((studentNote, studentNoteIdx) => (
                <li key={studentNote.id}>
                  <div
                    className={
                      studentNoteIdx === studentNoteRows.length - 1
                        ? 'relative'
                        : 'relative pb-8'
                    }
                  >
                    {studentNoteIdx !== studentNoteRows.length - 1 ? (
                      <span
                        className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div className="relative flex items-start space-x-3">
                      <>
                        <div className="bg-white">
                          <ChatAltIcon
                            className="h-8 w-8 rounded-full text-gray-500 items-center justify-center"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="min-w-0 flex-1">
                          <div>
                            <div className="text-sm flex">
                              <p className="font-medium text-gray-900 mr-3">
                                {studentNote.buddy}
                                {' '}
                                - P
                                {studentNote.phase}
                              </p>
                            </div>
                            <div className="mt-0.5 text-sm text-gray-500 flex justify-between">
                              <span>
                                Written on
                                {studentNote.createdAt}
                              </span>
                              <button
                                type="button"
                                className="mr-3"
                                onClick={() => {
                                  setDeletedId(studentNote.id);
                                  setOpenConfirmation(true);
                                }}
                              >
                                <TrashIcon className="h-5 w-5 text-red-500" />
                              </button>
                            </div>
                          </div>
                          <div className="mt-2 text-sm text-gray-700 break-words">
                            <p className="pr-3">{studentNote.note}</p>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <div className="w-full sticky bottom-0 bg-white">
              <div className="py-5 px-3">
                <form onSubmit={submitStudentNote}>
                  <textarea
                    id="notes"
                    name="notes"
                    onChange={changeNewNote}
                    value={newNote}
                    rows={3}
                    className="px-2 pt-2 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-400 rounded-md mb-2"
                    placeholder="Write new notes here..."
                    required
                  />
                  <input
                    type="submit"
                    className="cursor-pointer w-full items-center px-4 py-2  rounded-md shadow-sm text-sm font-medium text-white  hover:bg-indigo-800 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2  bg-indigo-500"
                  />
                </form>
              </div>
            </div>
          </>
        ) : (
          <CustomLoading width="150" />
        )}
      </div>
    </>
  );
}
