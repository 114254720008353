import React from 'react';
import Loader from 'react-loader-spinner';

export default function CustomLoading({ width }) {
  return (
    <div>
      <div className="mx-auto" style={{ width: `${width}px` }}>
        <Loader type="ThreeDots" color="#6466F2" height={100} width={width} />
      </div>
    </div>
  );
}
