import React from 'react';
import { TrashIcon } from '@heroicons/react/solid';

export default function RubricNotesInput({
  removeRubricNotes,
  assignmentRubricIndex,
  rubricNoteIndex,
  assignmentRubrics,
  setAssignmentRubrics,
  points,
  criteria,
}) {
  const changeRubricNote = (value, field) => {
    const newAssignmentRubrics = JSON.parse(JSON.stringify(assignmentRubrics));
    newAssignmentRubrics[assignmentRubricIndex].rubricNotes[rubricNoteIndex][
      field
    ] = value;
    setAssignmentRubrics(newAssignmentRubrics);
  };

  return (
    <div className="flex w-full mb-4 justify-end">
      <div className="w-4/12 px-2 ">
        <input
          onChange={(e) => {
            changeRubricNote(e.target.value, 'criteria');
          }}
          value={criteria}
          className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-md py-3 px-2 leading-tight focus:outline-none"
          type="text"
          placeholder="criteria"
          required
        />
      </div>
      <div className="w-2/12  px-2 ">
        <input
          onChange={(e) => {
            changeRubricNote(Number(e.target.value), 'points');
          }}
          className="appearance-none block w-full  text-gray-900 font-medium border border-gray-400 rounded-md py-3 px-2 leading-tight focus:outline-none"
          type="number"
          placeholder="points"
          value={points || ''}
          required
        />
      </div>
      <div className="w-2/12  px-2">
        <button
          onClick={() => removeRubricNotes(assignmentRubricIndex, rubricNoteIndex)}
          type="button"
          className="flex w-full p-3 bg-white text-red-500 border border-red-500 rounded-md text-sm"
        >
          <TrashIcon className="h-5 w-5" aria-hidden="true" />
          <span>Remove</span>
        </button>
      </div>
    </div>
  );
}
