/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { findAbsences } from './asyncThunk/absence';

const initialState = {
  absences: [],
  requestDone: false,
};

export const absenceSlice = createSlice({
  name: 'absence',
  initialState,
  reducers: {
    setStudentAbsence: (state, action) => {
      const { rowIndex, scheduleLectureId, value } = action.payload;
      const currentRow = JSON.parse(JSON.stringify(state.absences[rowIndex]));
      if (currentRow) {
        const studentAbsenceIndex = currentRow.studentAbsences.findIndex(
          (absence) => absence.scheduleLectureId === scheduleLectureId,
        );
        if (studentAbsenceIndex !== -1) {
          currentRow.studentAbsences[studentAbsenceIndex].status = value;
        } else {
          currentRow.studentAbsences.push({
            scheduleLectureId,
            checkInAt: new Date().toISOString(),
            status: value,
          });
        }
      }
      state.absences[rowIndex] = currentRow;
      return state;
    },
    setAbsenceRequestDone: (state, action) => {
      state.requestDone = action.payload.requestDone;
    },
    cleanUp: () => ({
      absences: [],
      requestDone: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(findAbsences.fulfilled, (state, action) => {
      if (!action.payload.data) {
        state.absences = [];
      } else {
        const formatedAbsences = action.payload.data.map((item) => {
          const { id, student_absences: studentAbsences } = item;
          if (studentAbsences === null) {
            return {
              id,
              studentAbsences: [],
            };
          }
          const currentStudentAbsence = studentAbsences.map((absence) => ({
            id: absence.id,
            scheduleLectureId: absence.schedule_lecture_id,
            checkInAt: absence.check_in_at,
            status: absence.status,
          }));
          return {
            id,
            studentAbsences: currentStudentAbsence,
          };
        });
        state.absences = formatedAbsences;
      }
      return state;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setStudentAbsence, setAbsenceRequestDone, cleanUp } = absenceSlice.actions;

export default absenceSlice.reducer;
