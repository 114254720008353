import React, { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from '../../components/Container';
import HeadingTab from '../../components/HeadingTab';
import CustomLoading from '../../components/CustomLoading';
import Pagination from '../../components/Pagination';
import DataTable from '../../components/DataTable';
import StudentFilter from './components/StudentFilter';
import ActionCell from './components/ActionCell';
import Modal from '../../components/Modal';
import StudentJourneyModal from './components/StudentJourneyModal';
import {
  setStudentPage,
  setStudentPerPage,
} from '../../store/features/studentSlice';
import { findPaymentType } from '../../store/features/asyncThunk/paymentType';
import { findStudentStatus } from '../../store/features/asyncThunk/studentStatus';
import { findStudentWithPagination } from '../../store/features/asyncThunk/student';
import sendRequest from '../../helpers/sendRequest';
import { findStudentJourneyByStudentId } from '../../store/features/asyncThunk/studentJourney';
import { setRequestDone as setRequestJourneyDone } from '../../store/features/studentJourneySlice';

export default function Student() {
  const dispatch = useDispatch();
  const [openJourney, setOpenJourney] = useState(false);
  const [studentDetail, setStudentDetail] = useState({});
  const { studentRows, requestDone, paginationDetail } = useSelector(
    (state) => state.student,
  );
  const paymentTypes = useSelector((state) => state.paymentType.paymentTypes);
  const studentStatusList = useSelector(
    (state) => state.studentStatus.studentStatusList,
  );
  const studentHeaderColumns = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Batch',
      accessor: 'currentBatch',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Payment Type',
      accessor: 'paymentType',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: ActionCell,
    },
  ]);
  const [filterInput, setFilterInput] = useState({
    search: '',
    batch: '',
    enrollBatch: '',
    paymentType: '',
    status: '',
  });

  const fetchStudent = (page) => {
    sendRequest({
      dispatch,
      actionName: 'Find student',
      message: {
        waitMsg: 'Finding student please wait',
      },
      mainRequest: {
        fn: findStudentWithPagination,
        payload: {
          ...filterInput,
          page: page || 1,
          limit: paginationDetail.perPage,
        },
      },
    });
  };

  useEffect(() => {
    dispatch(findPaymentType());
    dispatch(findStudentStatus());
  }, []);

  useEffect(() => {
    fetchStudent(paginationDetail.currentPage);
  }, [paginationDetail.currentPage, paginationDetail.perPage]);

  const findStudentJourney = (studentId) => {
    // will fetch student journey by id
    dispatch(findStudentJourneyByStudentId({ studentId })).finally(() => {
      dispatch(
        setRequestJourneyDone({
          requestDone: true,
        }),
      );
    });
  };

  const changePage = (page) => {
    dispatch(
      setStudentPage({
        currentPage: page,
      }),
    );
  };

  const changePerPage = (val) => {
    dispatch(
      setStudentPerPage({
        perPage: val,
      }),
    );
  };

  const showJourneyModal = (student) => {
    setStudentDetail(student);
    setOpenJourney(true);
  };
  return (
    <>
      <Container>
        <Modal open={openJourney} setOpen={setOpenJourney}>
          <StudentJourneyModal
            setOpen={setOpenJourney}
            studentDetail={studentDetail}
            fetchJourney={findStudentJourney}
          />
        </Modal>
        <HeadingTab headingTitle="Student Dashboard" />
        <div className="md:flex">
          <div className="w-full lg:w-1/5 pr-2 mb-4 md:mb-0">
            <StudentFilter
              filterInput={filterInput}
              setFilterInput={setFilterInput}
              fetchFn={fetchStudent}
              paymentTypes={paymentTypes}
              studentStatusList={studentStatusList}
            />
          </div>
          <div className="w-full lg:w-4/5 pl-2">
            {requestDone ? (
              <CustomLoading width="150" />
            ) : (
              <>
                <DataTable
                  rows={studentRows}
                  headers={studentHeaderColumns}
                  rowFn={showJourneyModal}
                />
                <Pagination
                  paginationDetail={paginationDetail}
                  changePage={changePage}
                  changePerPage={changePerPage}
                />
              </>
            )}
          </div>
        </div>
      </Container>
    </>
  );
}
