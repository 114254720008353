export const phases = [0, 1, 2, 3];
export const programs = ['FSJS', 'FSJF', 'FTDS', 'FTDM'];
export const challengeType = [
  'challenge',
  'live_code',
  'milestone',
  'portfolio',
  'pair_project',
  'rocket',
  'presentation',
];
export const phase0FSJSChallengeCriteria = [
  'debugging',
  'pair_programming',
  'problem_solving',
  'data_structure',
  'conditional',
  'loop',
  'modular_function',
  'basic_web',
];

export const phase0FSJSLiveCodeCriteria = [
  'problem_solving',
  'data_structure',
  'conditional',
  'loop',
  'modular_function',
  'basic_web',
];

export const absenceStatus = [
  'on_time',
  'late',
  'absent',
  'sick_leave',
  'leave',
];

export const paymentType = ['regular', 'ISA', 'scholarship'];
export const studentStatus = [
  {
    value: 'Graduate',
    name: 'GRADUATE',
  },
  {
    value: 'Student',
    name: 'STUDENT',
  },
];
