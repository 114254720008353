/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { findBatchStudents } from './asyncThunk/batchStudent';

const initialState = {
  students: [],
  requestDone: false,
};

export const batchStudentSlice = createSlice({
  name: 'batchStudent',
  initialState,
  reducers: {
    changeStudentRow: (state, action) => {
      const { rowIndex, newRow } = action.payload;
      state.students[rowIndex] = { ...state.students[rowIndex], ...newRow };
      return state;
    },
    setBatchStudentRequestDone: (state, action) => {
      state.requestDone = action.payload.requestDone;
    },
    cleanUp: () => ({
      students: [],
      requestDone: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(findBatchStudents.fulfilled, (state, action) => {
      if (!action.payload.data) {
        state.students = [];
      } else {
        const formatedStudents = action.payload.data.map((student) => {
          const {
            id,
            batch_id: batchId,
            buddy,
            life_points: lifePoints,
            warning_letter: warningLetter,
            user_id: userId,
            payment_type: paymentType,
            name,
            student_status: status,
            email,
          } = student;
          const formatBuddy = {};

          if (buddy !== null) {
            formatBuddy.id = buddy.id;
            formatBuddy.name = buddy.full_name;
          }
          return {
            id,
            batchId,
            buddy: formatBuddy,
            lifePoints,
            warningLetter,
            userId,
            paymentType,
            name,
            status,
            email,
          };
        });
        state.students = formatedStudents;
      }
      return state;
    });
  },
});

// Action creators are generated for each case reducer function
export const { changeStudentRow, setBatchStudentRequestDone, cleanUp } = batchStudentSlice.actions;

export default batchStudentSlice.reducer;
