import React from 'react';
import { XIcon } from '@heroicons/react/solid';

export default function AssignmentRubricDetail({ setOpen, assignmentDetail }) {
  return (
    <div className="flex flex-col justify-center my-2 mx-4 md:mx-0 text-left">
      <div className="mx-auto bg-white p-6 rounded-md w-full">
        <div className="flex justify-between mb-4">
          <h2 className="w-1/2 text-xl text-black ">{assignmentDetail.name}</h2>
          <button type="button" onClick={() => setOpen(false)}>
            <XIcon className="h-6 w-6 bg-red-400 rounded text-gray-100" />
          </button>
        </div>

        <div className="flex text-sm flex-wrap -mx-3 mb-6">
          <div className="flex flex-col w-full border-t boder-gray-50 mb-2">
            <div className="flex text-lg">
              <div className="w-1/2 p-3 ">Rubrics</div>
              <div className="w-1/2 p-3 text-center">Points</div>
            </div>
          </div>
          {assignmentDetail.assignmentRubrics.map((rubric, i) => (
            <div
              className="flex flex-col w-full border-t boder-gray-50 mb-2"
              key={rubric.id}
            >
              <div className="flex">
                <div className="w-1/2 p-3 ">
                  {i + 1}
                  {' '}
                  .
                  {rubric.criteria}
                </div>
                <div className="w-1/2 p-3 text-center">{rubric.maxPoints}</div>
              </div>
              {rubric.rubricNotes.map((note) => (
                <div className="flex text-sm" key={`note-${note.id}`}>
                  <div className="w-1/2 p-3 bg-gray-50">{note.criteria}</div>
                  <div className="w-1/2 p-3 bg-gray-50 text-center">
                    {note.points}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
