/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  findWeeklyCategories,
  createManyWeeklyCategories,
} from './asyncThunk/weeklyCategory';

const initialState = {
  weeklyCategoryRows: [],
  requestDone: false,
};

export const weeklyCategorySlice = createSlice({
  name: 'weeklyCategory',
  initialState,
  reducers: {
    changeWeeklyCategories: (state, action) => {
      const { rowId, currentWeek, value } = action.payload;
      const newRow = JSON.parse(JSON.stringify(state.weeklyCategoryRows[rowId]));
      const weeklyCategoryIndex = newRow.weeklyCategories.findIndex(
        (item) => item.week === currentWeek,
      );
      if (weeklyCategoryIndex === -1) {
        newRow.weeklyCategories.push({
          week: currentWeek,
          remark: '',
          weeklyCategory: value,
        });
      } else {
        newRow.weeklyCategories[weeklyCategoryIndex].weeklyCategory = value;
      }
      state.weeklyCategoryRows[rowId] = newRow;
      return state;
    },
    changeWeeklyRemarks: (state, action) => {
      const { rowId, currentWeek, value } = action.payload;
      const newRow = JSON.parse(JSON.stringify(state.weeklyCategoryRows[rowId]));
      const weeklyCategoryIndex = newRow.weeklyCategories.findIndex(
        (item) => item.week === currentWeek,
      );
      if (weeklyCategoryIndex === -1) {
        newRow.weeklyCategories.push({
          week: currentWeek,
          remark: value,
          weeklyCategory: '',
        });
      } else {
        newRow.weeklyCategories[weeklyCategoryIndex].remark = value;
      }
      state.weeklyCategoryRows[rowId] = newRow;
      return state;
    },
    setRequestDone: (state, action) => {
      state.requestDone = action.payload.requestDone;
    },
    cleanUp: () => ({
      weeklyCategoryRows: [],
      requestDone: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(findWeeklyCategories.fulfilled, (state, action) => {
      if (!action.payload.data) {
        return state;
      }
      const formatedRows = action.payload.data.map((weekly) => {
        const { id, student_weekly_categories: studentWeeklyCategories } = weekly;
        const formatedCategory = studentWeeklyCategories.map((item) => {
          const {
            student_id: studentId,
            weekly_category: weeklyCategory,
            remark,
            week,
          } = item;
          return {
            id: item.id,
            studentId,
            weeklyCategory,
            remark,
            week,
          };
        });
        return {
          id,
          weeklyCategories: formatedCategory,
        };
      });

      state.weeklyCategoryRows = formatedRows;
      return state;
    });
    builder.addCase(createManyWeeklyCategories.fulfilled, (state, action) => {
      const { rowIndex } = action.meta.arg;
      state.weeklyCategoryRows[rowIndex].weeklyCategories = state.weeklyCategoryRows[rowIndex].weeklyCategories.map((item) => {
        if (!item.weeklyCategory) {
          return {
            ...item,
            remark: null,
          };
        }
        return item;
      });
      return state;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  changeWeeklyCategories,
  changeWeeklyRemarks,
  setRequestDone,
  cleanUp,
} = weeklyCategorySlice.actions;

export default weeklyCategorySlice.reducer;
