import { createAsyncThunk } from '@reduxjs/toolkit';
import academicAPI from '../../../config/api/academicAPI';
import { thunkHandler } from '../helper/thunkHandler';

export const findScheduleLecture = createAsyncThunk(
  'scheduleLecture/findScheduleLectures',
  async (payload, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');
    const params = {
      batch: payload.batchId,
      phase: payload.phase,
    };

    return thunkHandler(
      academicAPI.get('/schedule-lectures', {
        params,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);

export const createScheduleLecture = createAsyncThunk(
  'scheduleLecture/createScheduleLecture',
  async (payload, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');

    return thunkHandler(
      academicAPI.post('/schedule-lectures', payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);

export const updateScheduleLecture = createAsyncThunk(
  'scheduleLecture/updateScheduleLecture',
  async (payload, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');
    const { schedule_id: scheduleId, ...data } = payload;

    return thunkHandler(
      academicAPI.put(`/schedule-lectures/${scheduleId}`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);
