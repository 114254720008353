import { createSlice } from '@reduxjs/toolkit';
import {
  findBatchDetailByCode,
  findAssignmentOptions,
  findInstructorOptions,
  findTopicOptions,
} from './asyncThunk/batchDashboard';

const initialState = {
  program: '',
  phase: '',
  requestDone: false,
  requestTopicDone: false,
  currentBatchDetail: {},
  instructors: [],
  assignments: [],
  topics: [],
  students: [],
};

export const batchDashboardSlice = createSlice({
  name: 'batchDashboard',
  initialState,
  reducers: {
    setRequestDone: (state, action) => {
      state.requestDone = action.payload.requestDone;
    },
    setRequestTopicDone: (state, action) => {
      state.requestTopicDone = action.payload.requestTopicDone;
    },
    cleanUp: (state) => {
      state.program = '';
      state.phase = '';
      state.requestDone = false;
      state.currentBatchDetail = {};
      state.instructors = [];
      state.assignments = [];
      state.batchAssignments = [];
      state.topics = [];

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findBatchDetailByCode.fulfilled, (state, action) => {
      const {
        id,
        current_phase: currentPhase,
        code,
        is_active: isActive,
        created_at: createdAt,
      } = action.payload.data;
      state.currentBatchDetail = {
        id,
        currentPhase,
        code,
        createdAt,
        isActive,
      };
    });
    builder.addCase(findAssignmentOptions.fulfilled, (state, action) => {
      if (state.assignments && action.payload.data.length) {
        const assignmentOptions = action.payload?.data?.map((assignment) => {
          const { id, name, type } = assignment;
          return {
            id,
            name: `${name} - ${type}`,
          };
        });

        if (Array.isArray(assignmentOptions)) {
          state.assignments = assignmentOptions;
        } else {
          state.assignments = [];
        }
      }
      return state;
    });
    builder.addCase(findInstructorOptions.fulfilled, (state, action) => {
      if (!action.payload.data) {
        state.instructors = [];
      } else {
        const formattedInstructors = action.payload.data.rows.map(
          (instructor) => {
            const {
              id,
              created_at: createdAt,
              full_name: fullName,
            } = instructor;
            return {
              id,
              createdAt,
              fullName,
            };
          },
        );
        state.instructors = formattedInstructors;
      }
      return state;
    });
    builder.addCase(findTopicOptions.fulfilled, (state, action) => {
      if (!action.payload.data) {
        state.topics = [];
      } else {
        const formattedTopics = action.payload.data.rows.map((topic) => {
          const { id, created_at: createdAt, topic: title } = topic;
          return {
            id,
            createdAt,
            topic: title,
          };
        });
        state.topics = formattedTopics;
      }
      return state;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setRequestDone, cleanUp, setRequestTopicDone } = batchDashboardSlice.actions;

export default batchDashboardSlice.reducer;
