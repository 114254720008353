import { createAsyncThunk } from '@reduxjs/toolkit';
import academicAPI from '../../../config/api/academicAPI';
import { thunkHandler } from '../helper/thunkHandler';

export const findBatchStudents = createAsyncThunk(
  'batchStudent/findBatchStudents',
  async (payload, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');
    const params = {
      batch: payload.batchId,
      phase: payload.phase,
    };

    return thunkHandler(
      academicAPI.get('/students', {
        params,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);

export const updateStudentAdmissionStatus = createAsyncThunk(
  'batchStudent/updateStudentAdmissionStatus',
  async (payload, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');

    return thunkHandler(
      academicAPI.put('/students/admission-status', payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);
