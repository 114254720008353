import React, { useState, useEffect } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { createSelector } from 'reselect';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Collapsible from '../../../components/Collapsible';
import Modal from '../../../components/Modal';
import PhaseSummaryTable from './PhaseSummaryTable';
import ConfirmationModal from '../../../components/ConfirmationModal';
import CustomLoading from '../../../components/CustomLoading';
import { findAccumulativeScore } from '../../../store/features/asyncThunk/cummulativeScore';
import {
  cleanUp,
  setRequestDone,
} from '../../../store/features/cummulativeScoreSlice';
import sendRequest from '../../../helpers/sendRequest';
import { closeBatchPhase } from '../../../store/features/asyncThunk/batch';
import { findBatchDetailByCode } from '../../../store/features/asyncThunk/batchDashboard';

const studentStatus = [
  { label: 'PASSED', value: 'passed' },
  { label: 'REPEAT', value: 'repeat' },
  { label: 'DROP OUT', value: 'dropOut' },
  { label: 'WITHDRAW', value: 'withdraw' },
  { label: 'LEAVE', value: 'leave' },
];
const cummulativeScoresSelector = createSelector(
  (state) => state.cummulativeScore.cummulativeRows,
  (state) => state.batchStudent.students,
  (cummulativeRows, students) => {
    const rows = cummulativeRows.map((studentCummulative) => {
      const studentIndex = students.findIndex(
        (row) => row.id === studentCummulative.id,
      );
      const formatedStudent = {
        id: studentCummulative.id,
        ...studentCummulative,
      };
      if (studentIndex !== -1) {
        formatedStudent.name = students[studentIndex].name;
        formatedStudent.buddy = students[studentIndex].buddy?.name;
        formatedStudent.lifePoints = students[studentIndex].lifePoints;
        formatedStudent.currentStatus = students[studentIndex].status;
      }
      return formatedStudent;
    });
    return {
      passed: rows.filter(
        (studentCummulative) =>
          // studentCummulative.currentStatus is from admission if admision status already failed/waived/do/wd/leave don't include from preview
          studentCummulative.cummulativeScores.status === 'passed'
          && studentCummulative.currentStatus !== 'FAILED'
          && studentCummulative.currentStatus !== 'WAIVED'
          && studentCummulative.currentStatus !== 'DROP_OUT'
          && studentCummulative.currentStatus !== 'WITHDRAW'
          && studentCummulative.currentStatus !== 'LEAVE',
      ),
      repeat: rows.filter(
        (studentCummulative) => studentCummulative.cummulativeScores.status === 'repeat'
          && studentCummulative.currentStatus !== 'FAILED'
          && studentCummulative.currentStatus !== 'WAIVED'
          && studentCummulative.currentStatus !== 'DROP_OUT'
          && studentCummulative.currentStatus !== 'WITHDRAW'
          && studentCummulative.currentStatus !== 'LEAVE',
      ),
      dropOut: rows.filter(
        (studentCummulative) => studentCummulative.cummulativeScores.status === 'drop_out'
          || studentCummulative.currentStatus === 'DROP_OUT',
      ),
      withdraw: rows.filter(
        (studentCummulative) => studentCummulative.currentStatus === 'WITHDRAW',
      ),
      leave: rows.filter(
        (studentCummulative) => studentCummulative.currentStatus === 'LEAVE',
      ),
    };
  },
);

export default function CompletePhaseModal({ setOpen }) {
  const dispatch = useDispatch();
  const params = useParams();
  const cumulativeScores = useSelector(cummulativeScoresSelector);
  const history = useHistory();
  const requestDone = useSelector((state) => state.cummulativeScore.requestDone);
  const [openCollapsible, setOpenCollapsible] = useState(null);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { requestDone: requestBatchDetailDone, currentBatchDetail } = useSelector((state) => state.batchDashboard);

  const completePhase = () => {
    sendRequest({
      dispatch,
      actionName: 'Completing Batch Phase',
      message: {
        waitMsg: 'Completing batch phase, please wait...',
      },
      mainRequest: {
        fn: closeBatchPhase,
        payload: {
          batchId: currentBatchDetail.id,
        },
      },
      closeModal: () => setOpen(false),
      additionalFetch: findBatchDetailByCode,
    }).finally(() => {
      history.push(`/batch/${params.code}/phase`);
    });
  };
  useEffect(() => {
    if (!requestDone && requestBatchDetailDone) {
      sendRequest({
        dispatch,
        actionName: 'Find cummulative scores',
        message: {
          waitMsg: 'Finding cummulative scores, please wait...',
        },
        mainRequest: {
          fn: findAccumulativeScore,
          payload: {
            batchId: currentBatchDetail.id,
            phase: currentBatchDetail.currentPhase,
            isBatchActive: true,
          },
        },
        setRequestDone,
      });
    }
  }, [requestDone, requestBatchDetailDone]);

  useEffect(
    () => function clearState() {
      dispatch(cleanUp());
    },
    [],
  );

  return (
    <div className="flex flex-col justify-center my-2 mx-4 md:mx-0 text-left bg-white rounded-md overflow-y-auto w-full p-4 ">
      <Modal open={openConfirmation} setOpen={setOpenConfirmation}>
        <ConfirmationModal
          setOpen={setOpenConfirmation}
          message="This action is irreversible, are you sure want to complete this phase?"
          onConfirmed={completePhase}
        />
      </Modal>
      <div className="flex justify-between mb-6 px-3">
        <h2 className="w-1/2 text-lg text-black ">Phase Summary</h2>
        <button type="button" onClick={() => setOpen(false)}>
          <XIcon className="h-6 w-6 bg-red-400 rounded text-gray-100" />
        </button>
      </div>
      {requestDone ? (
        <>
          <div className="px-3 mb-2">
            {studentStatus.map((status) => (
              <Collapsible
                key={status.value}
                open={openCollapsible === status.value}
                setOpen={() => {
                  if (openCollapsible === status.value) {
                    setOpenCollapsible(null);
                  } else {
                    setOpenCollapsible(status.value);
                  }
                }}
                title={`${status.label} - ${
                  cumulativeScores[status.value].length
                }`}
                headerClass="border rounded  shadow-sm"
              >
                <PhaseSummaryTable rows={cumulativeScores[status.value]} />
              </Collapsible>
            ))}
          </div>

          <div className="flex text-sm flex-wrap px-3 mb-6">
            <div className="w-full md:w-full" />
            <button
              onClick={() => setOpenConfirmation(true)}
              type="button"
              className="cursor-pointer w-full items-center px-4 py-2  rounded-md shadow-sm text-sm font-medium text-white  hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2  bg-indigo-500"
            >
              COMPLETE PHASE
            </button>
          </div>
        </>
      ) : (
        <CustomLoading width="150" />
      )}
    </div>
  );
}
