/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { findStudentProfile } from './asyncThunk/studentProfile';

const initialState = {
  studentProfile: {},
  requestDone: false,
};

export const studentProfileSlice = createSlice({
  name: 'studentProfile',
  initialState,
  reducers: {
    setRequestDone: (state, action) => {
      state.requestDone = action.payload.requestDone;
    },
    cleanUp: () => ({
      studentProfile: {},
      requestDone: false,
    }),
    changeFieldProfile: (state, action) => {
      state.studentProfile[action.payload.field] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findStudentProfile.fulfilled, (state, action) => {
      if (!action.payload.data) {
        return state;
      }
      const {
        id,
        created_at: createdAt,
        student_id: studentId,
        github_username: githubUsername,
        wakatime_api: wakatimeApi,
        discord_id: discordId,
        zoom_username: zoomUsername,
        background,
        join_reason: joinReason,
        goals,
        supporting_factors: supportingFactors,
        blocker_factors: blockerFactors,
        durability,
        curiousity,
        logic_score: logicScore,
        papikostick,
      } = action.payload.data;
      state.studentProfile = {
        id,
        createdAt,
        studentId,
        githubUsername,
        wakatimeApi,
        discordId,
        zoomUsername,
        background,
        joinReason,
        goals,
        supportingFactors,
        blockerFactors,
        durability,
        curiousity,
        logicScore,
        papikostick,
      };

      return state;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setRequestDone, cleanUp, changeFieldProfile } = studentProfileSlice.actions;

export default studentProfileSlice.reducer;
