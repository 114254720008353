/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { findInstructor } from './asyncThunk/instructor';

const initialState = {
  instructorRows: [],
  requestDone: false,
  paginationDetail: {
    currentPage: 1,
    lastPage: 1,
    perPage: 15,
    total: 0,
    from: 1,
    to: 1,
  },
};

export const instructorSlice = createSlice({
  name: 'instructor',
  initialState,
  reducers: {
    setInstructorPage: (state, action) => {
      state.paginationDetail.currentPage = action.payload.currentPage;
    },
    setInstructorPerPage: (state, action) => {
      state.paginationDetail.perPage = action.payload.perPage;
    },
    setRequestDone: (state, action) => {
      state.requestDone = action.payload.requestDone;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findInstructor.fulfilled, (state, action) => {
      state.instructorRows = action.payload.data.rows.map((row) => {
        const {
          id,
          phase,
          program,
          full_name: fullName,
          created_at: createdAt,
        } = row;
        return {
          id,
          phase,
          program,
          fullName,
          createdAt: createdAt.slice(0, 10),
        };
      });
      const {
        total,
        current_page: currentPage,
        per_page: perPage,
        last_page: lastPage,
        from,
        to,
      } = action.payload.data.pagination;
      state.paginationDetail = {
        currentPage,
        lastPage,
        perPage,
        total,
        from,
        to,
      };
    });
  },
});

// Action creators are generated for each case reducer function
export const { setInstructorPage, setInstructorPerPage, setRequestDone } = instructorSlice.actions;

export default instructorSlice.reducer;
