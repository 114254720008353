import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/outline';

export default function ConfirmationModal({ setOpen, message, onConfirmed }) {
  return (
    <div className="flex flex-col justify-center my-2 mx-4 md:mx-0 text-left bg-white rounded-md overflow-y-auto w-full p-4 ">
      <h1 className="inline-flex items-center text-xl">
        <InformationCircleIcon className="text-indigo-500 h-16 w-16 mr-2" />
        <span>{message}</span>
      </h1>
      <div className="flex">
        <button
          type="button"
          className="w-1/2 text-indigo-600"
          onClick={() => setOpen(false)}
        >
          Cancel
        </button>
        <button
          type="button"
          className="bg-indigo-500 rounded p-2 text-white w-1/2"
          onClick={() => {
            onConfirmed();
          }}
        >
          Proceed
        </button>
      </div>
    </div>
  );
}
