import React, { useState } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { useDispatch } from 'react-redux';
import { phases, programs } from '../../../constant/academic';
import { assignMyPhase } from '../../../store/features/asyncThunk/instructor';
import sendRequest from '../../../helpers/sendRequest';

export default function AssignPhaseModal({ setOpen, fetchInstructor }) {
  const dispatch = useDispatch();
  const [instructorInfo, setInstructorInfo] = useState({
    phase: 0,
    program: 'FSJS',
  });

  const changeInstructorInfo = (e) => {
    const field = e.target.name;
    let { value } = e.target;
    if (field === 'phase') {
      value = +value;
    }
    setInstructorInfo({
      ...instructorInfo,
      [field]: value,
    });
  };

  const updateInstructorInfo = (e) => {
    e.preventDefault();
    sendRequest({
      dispatch,
      actionName: 'phase assignment',
      message: {
        waitMsg: 'Updating your phase, please wait ...',
      },
      mainRequest: {
        fn: assignMyPhase,
        payload: instructorInfo,
      },
      closeModal: () => setOpen(false),
      additionalFetch: fetchInstructor,
    });
  };

  return (
    <>
      <div className="flex flex-col justify-center my-2 mx-4 md:mx-0 text-left">
        <form
          className="mx-auto bg-white p-6 rounded-md w-3/4"
          onSubmit={updateInstructorInfo}
        >
          <div className="flex justify-between mb-6">
            <h2 className="w-1/2 text-lg text-black ">
              Assign My Phase & Program
            </h2>
            <button type="button" onClick={() => setOpen(false)}>
              <XIcon className="h-6 w-6 bg-red-400 rounded-md text-gray-100" />
            </button>
          </div>
          <div className="flex text-sm flex-wrap -mx-3 mb-4">
            <div className="w-1/2 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="title"
              >
                Phase
              </label>
              <select
                name="phase"
                className="appearance-none w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-md py-3 px-3 focus:outline-none"
                onChange={changeInstructorInfo}
                value={instructorInfo.phase}
              >
                {phases.map((phase) => (
                  <option key={phase}>{phase}</option>
                ))}
              </select>
            </div>
            <div className="w-1/2 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="program"
              >
                Program
              </label>
              <select
                name="program"
                className="appearance-none w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-md py-3 px-3 focus:outline-none"
                onChange={changeInstructorInfo}
                value={instructorInfo.program}
              >
                {programs.map((val) => (
                  <option key={val}>{val}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="w-full">
            <button
              type="submit"
              className="w-full bg-indigo-600 text-gray-100 font-bold border border-gray-200 rounded-md py-3 px-3 leading-tight hover:bg-indigo-500 focus:outline-none focus:border-gray-500"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
