import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import Container from '../../components/Container';
import HeadingTab from '../../components/HeadingTab';
import PhaseCountCard from './components/PhaseCountCard';
import BreadCrumb from '../../components/BreadCrumb';
import sendRequest from '../../helpers/sendRequest';
import { findBatchPhases } from '../../store/features/asyncThunk/batch';
import {
  setBatchRequestDone,
  clearPhases,
} from '../../store/features/batchSlice';
import CustomLoading from '../../components/CustomLoading';

const selectBatchPhases = createSelector(
  (state) => state.batch.phases,
  (phases) => phases.map((item) => {
    let bgColor = 'bg-pink-600';
    if (item.phase === 1) {
      bgColor = 'bg-purple-600';
    } else if (item.phase === 2) {
      bgColor = 'bg-yellow-500';
    } else if (item.phase === 3) {
      bgColor = 'bg-green-500';
    }
    return {
      name: `Phase ${item.phase}`,
      initials: `P${item.phase}`,
      count: item.count,
      bgColor,
    };
  }),
);

export default function BatchPhase() {
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const { requestDone } = useSelector((state) => state.batch);
  const phases = useSelector(selectBatchPhases);

  useEffect(() => {
    dispatch(setBatchRequestDone(false));
    sendRequest({
      dispatch,
      actionName: 'find batch phases',
      message: {
        waitMsg: 'Find phases, please wait ...',
      },
      mainRequest: {
        fn: findBatchPhases,
        payload: {
          code: params.code,
        },
      },
      setRequestDone: setBatchRequestDone,
    });
    return () => {
      dispatch(clearPhases());
    };
  }, []);

  return (
    <>
      <Container>
        <BreadCrumb
          histories={[
            {
              path: '/batch',
              desc: 'Batch List',
            },
            {
              path: location.pathname,
              desc: `${params.code} Phases`,
            },
          ]}
        />
        <HeadingTab headingTitle="Phase List" />
        {!requestDone ? (
          <CustomLoading width="150" />
        ) : (
          <PhaseCountCard phases={phases} />
        )}
      </Container>
    </>
  );
}
