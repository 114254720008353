import { useSelector } from 'react-redux';
import Container from '../../components/Container';
import HeadingTab from '../../components/HeadingTab';
import PickProgram from './components/PickProgramCard';
import TableBatch from './components/TableBatch';

export default function BatchList() {
  const program = useSelector((state) => state.batch.program);
  // const batchRows = useSelector((state) => state.BatchList.batchRows)
  return (
    <>
      <Container>
        <HeadingTab
          headingTitle={program ? `${program} Batch List` : 'Choose Program'}
        />
        {program ? <TableBatch /> : <PickProgram />}
      </Container>
    </>
  );
}
