/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { findAccumulativeScore } from './asyncThunk/cummulativeScore';

const initialState = {
  cummulativeRows: [],
  requestDone: false,
};

export const cummulativeSlice = createSlice({
  name: 'cummulative',
  initialState,
  reducers: {
    setRequestDone: (state, action) => {
      state.requestDone = action.payload.requestDone;
    },
    cleanUp: () => ({
      cummulativeRows: [],
      requestDone: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(findAccumulativeScore.fulfilled, (state, action) => {
      if (!action.payload.data) {
        return state;
      }
      const formatedCummulativeRows = action.payload.data.map(
        (studentScore) => {
          const {
            id,
            created_at: createdAt,
            assignment_scores: assignmentScores,
            cummulative_scores: cummulativeScores,
            student_absences: studentAbsences,
          } = studentScore;

          const { cummulative_score: totalScore, status } = cummulativeScores;
          const { total_late: totalLate, total_absent: totalAbsent } = studentAbsences;
          return {
            id,
            createdAt,
            assignmentScores,
            cummulativeScores: {
              totalScore,
              status,
            },
            studentAbsences: {
              totalAbsent,
              totalLate,
            },
          };
        },
      );
      state.cummulativeRows = formatedCummulativeRows;

      return state;
    });
  },
});

export const { setRequestDone, cleanUp } = cummulativeSlice.actions;

export default cummulativeSlice.reducer;
