import React from 'react';
import { TrashIcon, PlusIcon } from '@heroicons/react/solid';
import RubricNotesInput from './RubricNotesInput';
import {
  phase0FSJSChallengeCriteria,
  phase0FSJSLiveCodeCriteria,
} from '../../../constant/academic';

export default function AssignmentRubricInput({
  removeAssignmentRubricInput,
  index,
  criteria,
  assignmentRubrics,
  setAssignmentRubrics,
  assignmentDetail: { phase, program, type },
}) {
  const removeRubricNotes = (assignmentRubricIndex, rubricIndex) => {
    const newAssignmentRubrics = JSON.parse(JSON.stringify(assignmentRubrics));
    newAssignmentRubrics[assignmentRubricIndex].rubricNotes.splice(
      rubricIndex,
      1,
    );
    setAssignmentRubrics(newAssignmentRubrics);
  };

  const addRubricNotes = () => {
    const newNotes = {
      criteria: '',
      points: 0,
    };
    const notes = [...assignmentRubrics[index].rubricNotes, newNotes];
    const newAssignmentRubrics = JSON.parse(JSON.stringify(assignmentRubrics));
    newAssignmentRubrics[index].rubricNotes = notes;
    setAssignmentRubrics(newAssignmentRubrics);
  };

  const changeCriteria = (e) => {
    const newAssignmentRubrics = JSON.parse(JSON.stringify(assignmentRubrics));
    newAssignmentRubrics[index].criteria = e.target.value;
    setAssignmentRubrics(newAssignmentRubrics);
  };

  const totalPoints = () => assignmentRubrics[index].rubricNotes.reduce((acc, current) => {
    const total = acc + current.points;
    return total;
  }, 0);

  const getCriteriaInput = () => {
    if (phase === 0 && program === 'FSJS') {
      return (
        <select
          className="appearance-none w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-md py-3 px-3 focus:outline-none"
          onChange={changeCriteria}
          value={criteria}
        >
          {type === 'challenge'
            ? phase0FSJSChallengeCriteria.map((val) => (
              <option key={val} value={val}>
                {val}
              </option>
            ))
            : phase0FSJSLiveCodeCriteria.map((val) => (
              <option key={val} value={val}>
                {val}
              </option>
            ))}
        </select>
      );
    }
    return (
      <input
        className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-md py-3 px-2 leading-tight focus:outline-none"
        type="text"
        placeholder="criteria"
        onChange={changeCriteria}
        value={criteria}
        required
      />
    );
  };

  return (
    <>
      <div className="flex w-full mb-4">
        <div className="w-4/12">{getCriteriaInput()}</div>
        <div className="w-4/12  px-2 ">
          <input
            className="appearance-none block w-full bg-gray-300 text-gray-900 font-medium border border-gray-400 rounded-md py-3 px-2 leading-tight focus:outline-none"
            type="number"
            placeholder="points"
            value={totalPoints()}
            disabled
          />
        </div>
        <div className="w-2/12  px-2">
          <button
            onClick={addRubricNotes}
            type="button"
            className="flex w-full p-3 bg-white text-yellow-500 border border-yellow-500 rounded-md text-sm"
          >
            <PlusIcon className="h-5 w-5" aria-hidden="true" />
            <span className="pl-2">Notes</span>
          </button>
        </div>
        <div className="w-2/12  px-2">
          <button
            onClick={() => (index ? removeAssignmentRubricInput(index) : null)}
            type="button"
            className="flex w-full py-3 pl-3 bg-white text-red-500 border border-red-500 rounded-md text-sm"
          >
            <TrashIcon className="h-5 w-5" aria-hidden="true" />
            <span>Remove</span>
          </button>
        </div>
      </div>
      {assignmentRubrics[index].rubricNotes.map((item, i) => (
        <RubricNotesInput
          removeRubricNotes={removeRubricNotes}
          key={`rubric-notes-${index}-${i}`}
          assignmentRubrics={assignmentRubrics}
          setAssignmentRubrics={setAssignmentRubrics}
          assignmentRubricIndex={index}
          rubricNoteIndex={i}
          points={item.points}
          criteria={item.criteria}
        />
      ))}
    </>
  );
}
