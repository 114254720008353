/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { findPaymentType } from './asyncThunk/paymentType';

const initialState = {
  paymentTypes: [{ name: '', value: '' }],
};

export const paymentTypeSlice = createSlice({
  name: 'paymentType',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findPaymentType.fulfilled, (state, action) => {
      if (action.payload.data) {
        const formatedPaymentType = action.payload.data.map((paymentType) => {
          const {
            id,
            created_at: createdAt,
            updated_at: updatedAt,
            name,
            value,
            description,
          } = paymentType;
          return {
            id,
            createdAt,
            updatedAt,
            name,
            value,
            description,
          };
        });
        state.paymentTypes = [{ name: '', value: '' }, ...formatedPaymentType];
      }
      return state;
    });
  },
});

export default paymentTypeSlice.reducer;
