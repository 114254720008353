/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ClassCountCard({ phases }) {
  const history = useHistory();
  const location = useLocation();

  return (
    <div>
      <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide" />
      <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
        {phases.map((phase) => (
          <li
            key={phase.name}
            className="col-span-1 flex shadow-sm rounded-md hover:bg-yellow-100 bg-white cursor-pointer"
            onClick={() => history.push(`${location.pathname}/${phase.initials[1]}/student`)}
          >
            <div
              className={classNames(
                phase.bgColor,
                'flex-shrink-0 flex items-center justify-center w-16 text-white font-medium rounded-l-md py-8 text-2xl',
              )}
            >
              {phase.initials}
            </div>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 rounded-r-md truncate">
              <div className="flex-1 px-4 py-2 truncate">
                <p className="text-gray-900 font-medium hover:text-gray-600 text-xl mb-2">
                  {phase.name}
                </p>
                <p className="text-gray-500 text-lg">
                  {phase.count}
                  {' '}
                  Students
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
