import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import DataTable from '../../components/DataTable';
import {
  cleanUp,
  setRequestDone,
} from '../../store/features/cummulativeScoreSlice';
import { findAccumulativeScore } from '../../store/features/asyncThunk/cummulativeScore';
import CustomLoading from '../../components/CustomLoading';
import sendRequest from '../../helpers/sendRequest';

const headerSelector = (isBatchActive) => createSelector(
  (state) => state.cummulativeScore.cummulativeRows,
  (cumulativeRows) => {
    const headers = [
      { Header: 'name', accessor: 'name' },
      { Header: 'buddy', accessor: 'buddy' },
    ];
    if (cumulativeRows.length === 0) {
      return headers;
    }
    const assignments = Object.keys(cumulativeRows[0].assignmentScores);
    const assignmentHeader = assignments.map((key) => ({
      Header: key,
      accessor: `assignmentScores.${key}`,
    }));
    const absencesHeader = [
      {
        Header: 'total late',
        accessor: 'studentAbsences.totalLate',
      },
      {
        Header: 'total absent',
        accessor: 'studentAbsences.totalAbsent',
      },
    ];
    const cumulativeHeader = [
      {
        Header: 'Grand Total',
        accessor: 'cumulativeScores.totalScore',
      },
      {
        Header: 'current status',
        /**
         * if student is already withdraw/leave/drop out/WAIVED
         * before batch ending show the status from admission detail
         *
         * currentStatus is status from admission
         */
        accessor: (originalRow) => {
          const statuses = ['DROP_OUT', 'LEAVE', 'WITHDRAW', 'WAIVED'];

          if (statuses.includes(originalRow.currentStatus) && isBatchActive) {
            return originalRow.currentStatus;
          }

          return originalRow.cummulativeScores.status.toUpperCase();
        },
      },
    ];
    return [
      ...headers,
      ...absencesHeader,
      ...assignmentHeader,
      ...cumulativeHeader,
    ];
  },
);

const cumulativeScoresSelector = createSelector(
  (state) => state.cummulativeScore.cummulativeRows,
  (state) => state.batchStudent.students,
  (cumulativeRows, students) => cumulativeRows.map((studentCumulative) => {
    const studentIndex = students.findIndex(
      (row) => row.id === studentCumulative.id,
    );
    const formattedStudent = {
      id: studentCumulative.id,
      ...studentCumulative,
    };
    if (studentIndex !== -1) {
      formattedStudent.name = students[studentIndex].name;
      formattedStudent.buddy = students[studentIndex].buddy?.name;
      formattedStudent.currentStatus = students[studentIndex].status;
    }
    return formattedStudent;
  }),
);

const BatchDashboardCumulative = ({ isBatchActive }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const headers = useSelector(headerSelector(isBatchActive));
  const cumulativeScores = useSelector(cumulativeScoresSelector);
  const requestDone = useSelector((state) => state.cummulativeScore.requestDone);
  const { requestDone: requestBatchDetailDone, currentBatchDetail } = useSelector(
    (state) => state.batchDashboard,
  );

  useEffect(() => {
    if (!requestDone && requestBatchDetailDone) {
      sendRequest({
        dispatch,
        actionName: 'Find cumulative scores',
        message: {
          waitMsg: 'Finding cumulative scores, please wait...',
        },
        mainRequest: {
          fn: findAccumulativeScore,
          payload: {
            batchId: currentBatchDetail.id,
            phase: params.phase,
            isBatchActive,
          },
        },
        setRequestDone,
      });
    }
  }, [requestDone, requestBatchDetailDone]);

  useEffect(
    () => function clearState() {
      dispatch(cleanUp());
    },
    [],
  );
  return (
    <>
      {requestDone ? (
        <DataTable rows={cumulativeScores} headers={headers} needGlobalFilter />
      ) : (
        <CustomLoading width="150" />
      )}
    </>
  );
};

BatchDashboardCumulative.propTypes = {
  isBatchActive: PropTypes.bool.isRequired,
};

export default BatchDashboardCumulative;
