import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DataTable from '../../../components/DataTable';
import ActionCell from './ActionCell';
import sendRequest from '../../../helpers/sendRequest';
import { findBatch } from '../../../store/features/asyncThunk/batch';
import {
  setBatchPage,
  setBatchPerPage,
  setBatchRequestDone,
  setProgram,
} from '../../../store/features/batchSlice';
import CustomLoading from '../../../components/CustomLoading';
import Pagination from '../../../components/Pagination';

export default function TableBatch() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    batchRows, program, paginationDetail, requestDone
  } = useSelector(
    (state) => state.batch,
  );
  useEffect(() => {
    sendRequest({
      dispatch,
      actionName: 'find batches',
      message: {
        waitMsg: 'Find batches, please wait ...',
      },
      mainRequest: {
        fn: findBatch,
        payload: {
          code: program,
          limit: paginationDetail.perPage,
          page: paginationDetail.currentPage,
        },
      },
      setRequestDone: setBatchRequestDone,
    });
  }, [paginationDetail.perPage, paginationDetail.currentPage]);

  const goToClassroom = (row) => {
    history.push(`/batch/${row.code}/phase`);
  };

  const changePage = (page) => {
    dispatch(
      setBatchPage({
        currentPage: page,
      }),
    );
  };

  const changePerPage = (perPage) => {
    dispatch(
      setBatchPerPage({
        perPage,
      }),
    );
  };

  const batchColumnHeader = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'code',
      },
      {
        Header: 'Current Phase',
        accessor: 'currentPhase',
      },
      {
        Header: 'Open',
        accessor: 'id',
        Cell: ActionCell,
      },
    ],
    [],
  );
  return (
    <>
      <button
        className="px-4 py-2 mb-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-500 border-indigo-500 hover:bg-indigo-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        type="button"
        onClick={() => dispatch(
          setProgram({
            program: '',
          }),
        )}
      >
        Select Other Program
      </button>
      {requestDone ? (
        <>
          <DataTable
            rows={batchRows}
            headers={batchColumnHeader}
            needGlobalFilter
            rowFn={goToClassroom}
          />
          <Pagination
            paginationDetail={paginationDetail}
            changePage={changePage}
            changePerPage={changePerPage}
          />
        </>
      ) : (
        <CustomLoading width="150" />
      )}
    </>
  );
}
