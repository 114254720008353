/* eslint-disable no-shadow */
import React from 'react';
// eslint-disable-next-line import/no-named-as-default-member
import Table from './components/Table';

function DataTable({
  rows,
  headers,
  updateCell,
  needGlobalFilter,
  rowFn,
  additionalStyle,
}) {
  return (
    <>
      <Table
        columns={headers}
        data={rows}
        updateMyData={updateCell}
        needGlobalFilter={needGlobalFilter}
        rowFn={rowFn}
        additionalStyle={additionalStyle}
      />
    </>
  );
}

export default DataTable;
