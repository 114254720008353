import { createAsyncThunk } from '@reduxjs/toolkit';
import academicAPI from '../../../config/api/academicAPI';
import { thunkHandler } from '../helper/thunkHandler';

export const findAbsences = createAsyncThunk(
  'absences/findAbsences',
  async (payload, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');
    const params = {
      batch: payload.batchId,
      phase: payload.phase,
    };

    return thunkHandler(
      academicAPI.get('/students/absences', {
        params,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);

export const upsertManyAbsences = createAsyncThunk(
  'absences/upsertAbsences',
  async (rowIndex, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');
    const { absences } = thunkAPI.getState().absence;
    const currentAbsence = absences[rowIndex];
    if (!currentAbsence) {
      return thunkAPI.rejectWithValue({
        errors: ['Absence not found'],
        status: 404,
      });
    }
    const formattedAbsences = currentAbsence.studentAbsences?.map((item) => ({
      schedule_lecture_id: item.scheduleLectureId,
      check_in_at: new Date().toISOString(),
      status: item.status,
    }));
    const requestBody = {
      student_id: currentAbsence.id,
      absences: formattedAbsences,
    };

    return thunkHandler(
      academicAPI.post('/student-absences/bulk', requestBody, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);
