import { createAsyncThunk } from '@reduxjs/toolkit';
import academicAPI from '../../../config/api/academicAPI';
import { thunkHandler } from '../helper/thunkHandler';

export const findBatch = createAsyncThunk(
  'batch/findBatchRows',
  async (filter, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');

    return thunkHandler(
      academicAPI.get('/batches', {
        params: filter,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);

export const findBatchPhases = createAsyncThunk(
  'batch/findBatchPhases',
  async (payload, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');

    return thunkHandler(
      academicAPI.get(`/batches/all-student-count/${payload.code}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);

export const closeBatchPhase = createAsyncThunk(
  'batch/closeBatchPhase',
  async (payload, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');

    return thunkHandler(
      academicAPI.put(`/batches/close-phase/${payload.batchId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);

export const graduateBatch = createAsyncThunk(
  'batch/graduateBatch',
  async (payload, thunkAPI) => {
    const accessToken = localStorage.getItem('access_token');

    return thunkHandler(
      academicAPI.put(`/batches/graduate/${payload.batchId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      thunkAPI,
    );
  },
);
